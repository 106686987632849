import { useQuery } from "react-query";
import { getSpecialities } from "../../../services/specialities/services";
import { Button, Grid, HStack, Skeleton, useToast } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import FolderBox from "../../../components/FolderBox/FolderBox";
import FixedRightSidebar, {
  LeftSide,
  RightPart,
} from "../../../layouts/FixedRightSidebar";
import { useSidebar } from "../../../context/SidebarContext";
import SidebarCloseIcon from "../../../assets/icons/SidebarCloseIcon";
import SidebarOpenIcon from "../../../assets/icons/SidebarOpenIcon";
import {
  FILTER_SERIES_SIDEBAR_SERIES,
  FILTER_SIDEBAR,
  HISTORY_SIDEBAR,
  seriesQuestionsColumns,
} from "../Remarques/constants";
import FilterSquareIcon from "../../../assets/icons/FilterSquareIcon";
import Sidebar from "../../../components/Sidebar";
import LayerIcon from "../../../assets/icons/LayerIcon";
import { useEffect, useState } from "react";
import { filterSeriesQuestions } from "../../../services/questions/services";
import MyToast from "../../../components/Toast/Toast";
import LoadingTable from "../../../components/LoadingTable/LoadingTable";
import ErrorBoundary from "../../../components/ErrorBoundary/ErrorBoundary";
import QuestionRow from "./components/QuestionRow";
import AccordionQuestion, {
  Question,
} from "../Remarques/components/AccordionQuestion";
import CustomTable from "../../../components/CustomTable/CustomTable";
import { FiFastForward, FiRewind } from "react-icons/fi";
import { Td, Tr } from "../../../components/Table/Table";
import { findSelectedOptionsCount } from "../../../services/Remarques/services";

interface UpdatedBy {
  fullname: string;
  _id: string;
}

export default function Series({ path }: { path: string }) {
  const [pagesCount, setPagesCount] = useState<number>(1);
  const [expandedRows, setExpandedRow] = useState<string[]>([]);
  const {
    handleChangeSidebar,
    handleIsSidebarOpen,
    handleChangePage,
    handleSelectedReportDetails,
    state: { page, filter, selectedReportDetails, isSidebarOpen },
  } = useSidebar();
  const [selectedQuestion, setSelectedQuestion] = useState<Question | null>(
    null
  );
  const [questionsCount, setQuestionsCount] = useState<any>([]);
  const history = useHistory();

  const LIMIT = 30;
  const toast = useToast();
  const { data, isLoading, isError } = useQuery(
    ["questions", page, ...Object.entries(filter)],
    () => filterSeriesQuestions({ page, limit: LIMIT, filter }),
    {
      refetchOnWindowFocus: false,
      onSuccess: (res) => setPagesCount(res?.totalCount.totalDocuments || 0),
      onError: (err: any) => {
        MyToast({
          toast,
          description: err.response.data.message,
          status: "error",
          title: "Erreur",
        });
      },
      staleTime: Infinity,
      enabled: Boolean(objectToQueryString(filter)),
    }
  );
  const { data: selectedOptionsCounts, isLoading: selectedOptionsIsLoading } =
    useQuery(
      ["selecetedOptionsCount", selectedQuestion?._id],
      () => findSelectedOptionsCount(selectedQuestion?._id || ""),
      {
        refetchOnWindowFocus: false,
        enabled: Boolean(selectedQuestion),
        staleTime: Infinity,
        onSuccess: (res) =>
          setQuestionsCount([
            ...questionsCount,
            { questionId: res?.[0]?._id, stats: res },
          ]),
      }
    );
  const { data: specialities, isLoading: isLoadingSpecialities } = useQuery(
    "specialities",
    getSpecialities,
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    }
  );

  function objectToQueryString(obj: any) {
    const queryString = Object.keys(obj)
      .filter((key) => obj[key] !== "")
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`
      )
      .join("&");

    return queryString;
  }

  useEffect(() => {
    setQuestionsCount([]);
    setExpandedRow([])
    setSelectedQuestion(null);
  }, [page, ...Object.keys(filter)]);

  const handleRowClick = (
    rowId: string,
    questionId: Question,
    reportDetails: { updatedBy?: UpdatedBy; lastUpdatedAt: string }
  ) => {
    handleChangeSidebar(HISTORY_SIDEBAR);
    // setExpandedRow((prevRow) => (prevRow === rowId ? null : rowId));
    setExpandedRow((prevRow) => {
      const isStringInArray = prevRow.includes(rowId);

      if (isStringInArray) {
        // Remove the string from the array
        const newArray = prevRow.filter((item) => item !== rowId);
        return newArray;
      } else {
        // Append the string to the array
        const newArray = [...prevRow, rowId];
        return newArray;
      }
    });
    setSelectedQuestion(questionId);
    handleSelectedReportDetails(reportDetails);
  };

  const renderPageButtons = () => {
    const buttons = [];
    const totalPages = Math.ceil(pagesCount / LIMIT)
      ? Math.ceil(pagesCount / LIMIT)
      : 1;
    const startPage = Math.max(0, page - 2);
    const endPage = Math.min(totalPages, startPage + 1);

    for (let i = startPage; i <= page; i++) {
      if (i + 1 > totalPages) {
        break;
      }
      buttons.push(
        <Button
          key={i}
          onClick={() => handleChangePage(i + 1)}
          bg={i + 1 === page ? "blue-light" : "gray.100"}
          color={i + 1 === page ? "white" : "black"}
          _hover={{}}
        >
          {i + 1}
        </Button>
      );
    }

    if (startPage > 1) {
      buttons.unshift(<span key="start-dots">...</span>);
      for (let i = page - 4; i > page - 6; i--) {
        if (i === -1) {
          break;
        }
        buttons.unshift(
          <Button
            key={`key-${i}`}
            onClick={() => handleChangePage(i + 1)}
            bg={i + 1 === page ? "blue-light" : "gray.100"}
            color={i + 1 === page ? "white" : "black"}
            _hover={{}}
          >
            {i + 1}
          </Button>
        );
      }
    }

    if (page < totalPages) {
      buttons.push(<span key="end-dots">...</span>);
      if (page + 2 < totalPages) {
        for (let i = totalPages - 2; i < totalPages; i++) {
          buttons.push(
            <Button
              key={`keys-${i}`}
              onClick={() => handleChangePage(i + 1)}
              bg={i + 1 === page ? "blue-light" : "gray.100"}
              color={i + 1 === page ? "white" : "black"}
              _hover={{}}
            >
              {i + 1}
            </Button>
          );
        }
      }
    }

    buttons.push(
      <Button
        key={totalPages + "d"}
        onClick={() => handleChangePage(totalPages)}
        bg={"gray.100"}
      >
        <FiFastForward />
      </Button>
    );
    buttons.unshift(
      <Button key={"first"} onClick={() => handleChangePage(1)} bg={"gray.100"}>
        <FiRewind />
      </Button>
    );
    return buttons;
  };

  return (
    <FixedRightSidebar
      breadcrumbs={filter}
      basePage="Séries"
      buttons={[
        {
          title: isSidebarOpen ? "Fermer" : "Ouvrir",
          icon: isSidebarOpen ? <SidebarCloseIcon /> : <SidebarOpenIcon />,
          onClick: () => handleIsSidebarOpen(!isSidebarOpen),
        },
        {
          title: "Filtrer",
          value: FILTER_SERIES_SIDEBAR_SERIES,
          icon: <FilterSquareIcon />,
          onClick: () => handleChangeSidebar(FILTER_SERIES_SIDEBAR_SERIES),
          isHidden: !isSidebarOpen,
        },
      ]}
    >
      <LeftSide>
        {objectToQueryString(filter) ? (
          <>
            <CustomTable columns={seriesQuestionsColumns}>
              {isLoading ? (
                <LoadingTable height="500px" />
              ) : isError ? (
                <ErrorBoundary />
              ) : (
                data?.data?.map((question: Question) => (
                  <>
                    <QuestionRow
                      key={question._id}
                      handleRowClick={handleRowClick}
                      expandedRow={
                        expandedRows.find((el) => el === question._id) ?? ""
                      }
                      question={question}
                    />
                    {expandedRows.find((el) => el === question._id) && (
                      <Tr height="auto" padding="0">
                        <Td colSpan={10} padding="0">
                          <AccordionQuestion
                            question={question}
                            selectedOptionsCounts={
                              questionsCount.find(
                                (el: any) => el?.questionId == question._id
                              )?.stats ?? []
                            }
                            selectedOptionsIsLoading={selectedOptionsIsLoading}
                            isSeries
                          />
                        </Td>
                      </Tr>
                    )}
                  </>
                ))
              )}
            </CustomTable>
            <HStack my="2">{renderPageButtons()}</HStack>
          </>
        ) : (
          <Grid
            gridTemplateColumns={"repeat(auto-fill, minmax(300px, 1fr))"}
            justifyItems="center"
            gap="20px"
            my="4"
          >
            {isLoadingSpecialities
              ? Array.apply(null, Array(12)).map((el: any, index: number) => (
                  <Skeleton rounded={"0.8rem"} w="full" h="36" key={index} />
                ))
              : specialities.map((speciality: any) => (
                  <FolderBox
                    goTo={() => history.push(`${path}/${speciality.name}`)}
                    item={speciality}
                    key={speciality._id}
                  />
                ))}
          </Grid>
        )}
      </LeftSide>
      <RightPart
        footerButtons={[
          {
            title: "Historique",
            icon: <LayerIcon />,
            value: HISTORY_SIDEBAR,
            onClick: () => handleChangeSidebar(HISTORY_SIDEBAR),
            isDisabled: !selectedReportDetails,
          },
        ]}
      >
        <Sidebar />
      </RightPart>
    </FixedRightSidebar>
  );
}
