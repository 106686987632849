import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { getOneSpeciality, getSpecialities } from "../../../services/specialities/services";
import { getOneSubject } from "../../../services/subjects/services";
import { getOneUniversity } from "../../../services/university/services";
import NotFound from "../../Authorized/NotFound/NotFound";
import SpecialityForm from "./Specialities/Specialities.form";
import SubjectForm from "./Subjects/SubjectForm";
import UniversityForm from "./Universtities/UniversityForm";

export default function ResourcesForm({ path }: { path: string }) {
  const params = useParams<{ name: string }>();
  const history = useHistory();

  const {
    data: speciality,
    isLoading: specialityLoading,
    isError: specialityError,
  } = useQuery(
    `speciality-${params.name}`,
    () => getOneSpeciality(params.name),
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled:
        Boolean(path === "/resources/specialites") && Boolean(params.name),
    }
  );

  const {
    data: university,
    isLoading: universityLoading,
    isError: universityError,
  } = useQuery(
    `university-${params.name}`,
    () => getOneUniversity(params.name),
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: Boolean(path === "/resources/facultes") && Boolean(params.name),
    }
  );

  const {
    data: specialities,
    isLoading: specialitiesLoading,
    isError: specialitiesError,
  } = useQuery(
    `select-universities`,
    getSpecialities,
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: Boolean(path === "/resources/sujets"),
    }
  );

  const {
    data: subject,
    isLoading: subjectLoading,
    isError: subjectError,
  } = useQuery(
    `subject-${params.name}`,
    () => getOneSubject(params.name),
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: Boolean(path === "/resources/sujets") && Boolean(params.name),
    }
  );


  const [isOpen, setIsOpen] = useState(true);
  const onClose = () => {
    setIsOpen(false);
    history.goBack();
  };

  const initialRef = React.useRef<any>();
  const finalRef = React.useRef<any>();

  const modalBody = () => {
    switch (path) {
      case "/resources/specialites":
        return <SpecialityForm speciality={speciality} onClose={onClose} />;
      case "/resources/facultes":
        return <UniversityForm university={university} onClose={onClose} />;
      case "/resources/sujets":
        return <SubjectForm subject={subject} onClose={onClose} specialities={specialities}/>;
      default:
        return null;
    }
  };

  return (
    <div>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        size={"lg"}
        autoFocus
      >
        <ModalOverlay />
        <ModalContent bg={useColorModeValue("white", "blue-dark")}>
          <ModalHeader>Modifier</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {specialityLoading || universityLoading || (subjectLoading || specialitiesLoading) ? (
              <Flex justify={"center"} h="24" align={"center"}>
                <Spinner size={"md"} color="gold-yellow" />
              </Flex>
            ) : specialityError || universityError || specialitiesError || subjectError ? (
              <NotFound />
            ) : (
              modalBody()
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
}
