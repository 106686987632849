import { Route, Switch, useRouteMatch } from "react-router-dom";
import Dashboard from "../../pages/Unauthorized/Dashboard/Dashboard";

export default function DashboardContainer() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <Dashboard />
      </Route>
      <Route>
        <h1>Page Not Found</h1>
      </Route>
    </Switch>
  );
}
