import {
  Box,
  Flex,
  HStack,
  IconButton,
  Button,
  useDisclosure,
  useColorModeValue,
  Stack,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon, SmallAddIcon, EditIcon } from "@chakra-ui/icons";
import { Link, Link as RouterLink } from "react-router-dom";

const Links = [
  {
    id: 1,
    label: "Profil",
    href: "/profil",
  },
  {
    id: 2,
    label: "Collaborateurs",
    href: "/profil/gestion-des-collaborateurs",
  },
];

export default function Navbar({path, location}:{location: string, path: string}) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const goTo = () => {
    switch (location) {
      case "collaborateurs":
        return `${path}/ajouter-collaborateur`
      case "profile":
        return "/profil/modifier"
      default:
        return "/"
    }
  }

  return (
    <>
      <Box
        bg={useColorModeValue("gray.100", "block-dark")}
        px={4}
        mb="3"
        borderRadius={"lg"}
      >
        <Flex h={{base: 14, "2xl":16}} alignItems={"center"} justifyContent={"space-between"}>
          <IconButton
            size={"md"}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label={"Open Menu"}
            display={{ md: "none" }}
            onClick={isOpen ? onClose : onOpen}
          />
          <HStack spacing={8} alignItems={"center"}>
            <HStack
              as={"nav"}
              spacing={4}
              display={{ base: "none", md: "flex" }}
            >
              {Links.map((link) => (
                <RouterLink key={link.id} to={link.href} className={path === link.href ? "active" : undefined}>
                  <Button variant={path === link.href ? "primary" : "ghost"} height={"2rem"} fontSize={{base: "0.7rem", "2xl":"1rem"}}>{link.label}</Button>
                </RouterLink>
              ))}
            </HStack>
          </HStack>
          {
                    location === "collaborateurs" && <Flex alignItems={"center"}>
            <Link to={goTo}>
              <Button
                bg="green.400"
                color={"white"}
                _hover={{
                  bg: "green.600"
                }}
                size={"sm"}
                height={"2rem"}
                mr={4}
                fontSize={{base: "0.7rem", "2xl":"1rem"}}
                leftIcon={location === "collaborateurs" ? <SmallAddIcon fontSize={"xl"}/> : <EditIcon />}
              >
                   Ajouter collaborateur
              </Button>
            </Link>
          </Flex>
                }
        </Flex>

        {isOpen ? (
          <Box pb={4} display={{ md: "none" }}>
            <Stack as={"nav"} spacing={4}>
              {Links.map((link) => (
                <RouterLink key={link.id} to={link.href}>
                  <Button variant={path === link.href ? "primary" : "solid"} height={"2rem"}>{link.label}</Button>
                </RouterLink>
              ))}
            </Stack>
          </Box>
        ) : null}
      </Box>
    </>
  );
}
