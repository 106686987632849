import axiosInstance from "../../utils/axiosInstance";

//load user
export const getNotSeenNotificationCount = async () => {
    let token = localStorage.getItem("token");
    return await axiosInstance({
      method: "get",
      url: `/notification/admin/count`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res: any) => res.data);
};

export const fetchNotifications = async (page: number) => {
  let token = localStorage.getItem("token");
  const response = await axiosInstance({
    url: `/notification/admin/all?skip=${page * 20}&limit=20`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return {
    results: response.data.results,
    nextPage: page + 1,
    totalPages: response.data.totalPages,
  };
};

export const setNotificationsAsSeen = async (ids: Array<string>) => {
  let token = localStorage.getItem("token");
  return await axiosInstance({
    method: "put",
    url: "/notification/many",
    data:{
      ids
    },
    headers:{
      Authorization: `Bearer ${token}`
    }
  }).then((res: any) => res.data);
};