import axiosInstance from "../../utils/axiosInstance";

export const createAnnounce = async ({ data, isActive }: any) => {
  let token = localStorage.getItem("token");
  const response = await axiosInstance({
    method: "post",
    url: `/announces/new-announce?isActive=${isActive}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  })
  return response.data
};

export const getAllAnnounces = async () => {
  let token = localStorage.getItem("token");
  return await axiosInstance({
    method: "get",
    url: `/announces/read-all`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data)
};

export const toggleActivateAnnounce = async (id: string) => {
  let token = localStorage.getItem("token");
  return await axiosInstance({
    method: "put",
    url: `/announces/activate/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data)
};

export const removeAnnounce = async (id: string) => {
  let token = localStorage.getItem("token");
  return await axiosInstance({
    method: "delete",
    url: `/announces/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data)
};
