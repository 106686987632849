import { EditIcon } from "@chakra-ui/icons";
import { HStack, IconButton, Tag, Text, Tooltip, useBreakpointValue, useToast } from "@chakra-ui/react";
import moment from "moment";
import { BiArchiveIn, BiArchiveOut } from "react-icons/bi";
import { IoTrashOutline } from "react-icons/io5";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import Dialog from "../../../../components/Dialog/Dialog";
import { Td, Tr } from "../../../../components/Table/Table";
import MyToast from "../../../../components/Toast/Toast";
import { archiveSpeciality, deleteSpeciality } from "../../../../services/specialities/services";

const SpecialitiesTable = ({ data, path, refetch }: any) => {
    const sizes = useBreakpointValue({ base: "sm", "2xl": "md" });
    const history = useHistory();
    const toast = useToast();
  
    const { mutateAsync: deleteMutation, isLoading: deleteIsLoading } =
      useMutation(deleteSpeciality, {
        onSuccess: async () => {
          MyToast({ toast, status: "success", title: "Spécialité supprimée." });
          refetch();
        },
        onError: (err: any) => {
          MyToast({
            toast,
            description: err.response.data.message,
            status: "warning",
            title: "Impossible",
          });
        },
      });
  
    const { mutateAsync: archiveMutation, isLoading } = useMutation(
      archiveSpeciality,
      {
        onSuccess: async () => {
          MyToast({ toast, status: "success", title: "Spécialité modifiée." });
          refetch();
        },
        onError: (err: any) => {
          MyToast({
            toast,
            description: err.response.data.message,
            status: "warning",
            title: "Impossible",
          });
        },
      }
    );
  
    return data.map((el: any) => (
      <Tr key={el._id}>
        <Td>
          <Text
            fontSize={{ base: "0.8rem", "2xl": "sm" }}
            isTruncated
            maxW={"99%"}
          >
            {el.name}
          </Text>
        </Td>
        <Td textAlign="center">
          <Text
            fontSize={{ base: "0.8rem", "2xl": "sm" }}
            isTruncated
            maxW={"99%"}
          >
            {el?.shortname || ""}
          </Text>
        </Td>
        <Td textAlign="center">
          <Text
            fontSize={{ base: "0.8rem", "2xl": "sm" }}
            isTruncated
            maxW={"99%"}
          >
            {el.jour}
          </Text>
        </Td>
        <Td textAlign="center">
          <Tag borderRadius={"100%"} colorScheme={"teal"} size={"md"}>
            {el.subjectCount || 0}
          </Tag>
        </Td>
        <Td textAlign="center">
          {el.archived ? (
            <Text fontSize={{ base: "0.8rem", "2xl": "sm" }} color="red.600">
              Archivée
            </Text>
          ) : (
            <Text fontSize={{ base: "0.8rem", "2xl": "sm" }} color="green.600">
              Non archivée
            </Text>
          )}
        </Td>
        <Td>
          <Text fontSize={{ base: "0.8rem", "2xl": "sm" }}>
            {moment(el.createdAt).format("DD/MM/YYYY")}
          </Text>
        </Td>
        <Td>
          <Text fontSize={{ base: "0.8rem", "2xl": "sm" }}>
            {moment(el.updatedAt).format("DD/MM/YYYY, H:mm")}
          </Text>
        </Td>
        <Td>
          <HStack spacing={2} justify={"flex-end"}>
            <Tooltip label="Modifier" placement="bottom">
              <IconButton
                aria-label="Modifier Collaborateur"
                size={sizes}
                icon={<EditIcon fontSize={"1.1rem"} color={"blue-light"} />}
                onClick={() => history.push(`${path}/modifier/${el.name}`)}
              />
            </Tooltip>
            <Dialog
              question="Attention"
              description={`Êtes-vous sur de vouloir ${
                el.archived ? "retirer" : "ajouter"
              } cette spécialité ${el.archived ? "de" : "à"} l'archive ?`}
              isLoading={isLoading}
              onClick={() => archiveMutation(el._id)}
            >
              <Tooltip
                label={el.archived ? "Restaurer" : "Archiver"}
                placement="bottom"
              >
                <IconButton
                  aria-label="Archiver une spécialité"
                  size={sizes}
                  icon={
                    el.archived ? (
                      <BiArchiveOut fontSize={"1.4rem"} color="teal" />
                    ) : (
                      <BiArchiveIn fontSize={"1.4rem"} color="orange" />
                    )
                  }
                />
              </Tooltip>
            </Dialog>
            <Dialog
              question="Attention"
              description={
                <>
                  <p>
                    Les données supprimées ne pourront pas être récupérées
                    ultérieurement.
                  </p>
                  <br />{" "}
                  <p>Êtes-vous sur de vouloir supprimer cette spécialité?</p>
                </>
              }
              isLoading={deleteIsLoading}
              onClick={() => deleteMutation(el._id)}
            >
              <Tooltip label={"Supprimer"} placement="bottom">
                <IconButton
                  aria-label="Supprimer une spécialité"
                  size={sizes}
                  icon={<IoTrashOutline fontSize={"1.4rem"} color="red" />}
                />
              </Tooltip>
            </Dialog>
          </HStack>
        </Td>
      </Tr>
    ));
  };
  
  export default SpecialitiesTable;
  