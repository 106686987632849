import { Route, Switch, useRouteMatch } from "react-router-dom";
import Notifications from "../../pages/Unauthorized/Notifications/Notifications";


export default function NotificationsContainer() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <Notifications />
      </Route>
      <Route>
        <h1>Page Not Found</h1>
      </Route>
    </Switch>
  );
}
