import { Box, Flex, Stack, useColorModeValue, Image } from "@chakra-ui/react";
import logo from "../assets/img/ecnplus-logo.svg";
import { IAuthLayout } from "../types";

export default function AuthLayout({ children }: IAuthLayout) {
  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      bg={useColorModeValue("light-blue-light", "gray.800")}
    >
      <Stack
        spacing={8}
        mx={"auto"}
        maxW={"lg"}
        py={12}
        px={{ base: 2, md: 6 }}
      >
        <Stack align={"center"}>
          <Image src={logo} textAlign={"center"} />
        </Stack>
        <Box
          minW={"350px"}
          rounded={"lg"}
          bg={useColorModeValue("white", "gray.700")}
          boxShadow={"lg"}
          p={8}
        >
          {children}
        </Box>
      </Stack>
    </Flex>
  );
}
