export const getStatusTag = (status: string) => {
  switch (status) {
    case "new":
      return {
        colorScheme: "linkedin",
        label: "Nouvelle",
      };
    case "confirmed":
      return {
        colorScheme: "whatsapp",
        label: "Confirmée",
      };
    case "cancelled":
      return {
        colorScheme: "red",
        label: "Annulée",
      };
    case "activated_voucher":
      return {
        colorScheme: "orange",
        label: "Code coupon activé",
      };
    case "completed":
      return {
        colorScheme: "purple",
        label: "Terminée",
      };

    default:
      return {
        colorScheme: "linkedin",
        label: "Nouvelle",
      };
  }
};

export const getVoucherStatus = (voucherStatus: string) => {
  switch (voucherStatus) {
    case "used":
      return "Utilisé";
    case "activated":
      return "Activé";
    // case "cancelled":
    //   return "annulé";
    default:
      return "Non activé";
  }
};
