import { ChevronRightIcon } from "@chakra-ui/icons";
import { Badge, Breadcrumb, BreadcrumbItem, Text } from "@chakra-ui/react";

export default function QuestionBreadCrumb({ question, insideExam }: any) {
  if (question?.type === "Examen" || question?.qcm?.type?.name === "Examen") {
    return (
      <Breadcrumb
        spacing="8px"
        separator={<ChevronRightIcon color="gray.500" />}
        my="2"
      >
        {!insideExam && (
          <BreadcrumbItem>
            <Badge
              colorScheme={"whatsapp"}
              fontWeight="medium"
              rounded={"xl"}
              textTransform="capitalize"
            >
              <Text fontSize={"sm"}>Examen Blanc</Text>
            </Badge>
          </BreadcrumbItem>
        )}
        {!insideExam && (
          <BreadcrumbItem>
            <Text fontSize={"sm"}>{question?.year || "n/a"}</Text>
          </BreadcrumbItem>
        )}

        {!insideExam && (
          <BreadcrumbItem>
            <Text fontSize={"sm"}>
              {question?.university || question?.university?.name || "n/a"}
            </Text>
          </BreadcrumbItem>
        )}

        {!insideExam && (
          <BreadcrumbItem>
            <Text fontSize={"sm"}>
              {question?.jour ? `Jour: ${question?.jour}` : "Jour: n/a"}
            </Text>
          </BreadcrumbItem>
        )}
        <BreadcrumbItem>
          <Text fontSize={"sm"}>{(!insideExam ? question?.speciality : question?.speciality?.name) || "n/a"}</Text>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <Text fontSize={"sm"}>{(!insideExam ? question?.subject : question?.subject?.name) || "n/a"}</Text>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <Text fontSize={"sm"}>
            {question?.order || "n/a"}
            {question?.order === 1 ? "ère " : "ème "}question
          </Text>
        </BreadcrumbItem>
      </Breadcrumb>
    );
  }

  return (
    <Breadcrumb
      spacing="8px"
      separator={<ChevronRightIcon color="gray.500" />}
      my="2"
    >
      <BreadcrumbItem>
        <Badge
          colorScheme={"yellow"}
          fontWeight="medium"
          rounded={"xl"}
          textTransform="capitalize"
        >
          <Text fontSize={"sm"}>Série</Text>
        </Badge>
      </BreadcrumbItem>

      <BreadcrumbItem>
        <Text fontSize={"sm"}>{(question?.speciality || question?.qcm?.speciality?.name) || "n/a"}</Text>
      </BreadcrumbItem>

      <BreadcrumbItem>
        <Text fontSize={"sm"}>{(question?.subject || question?.qcm?.subject?.name) || "n/a"}</Text>
      </BreadcrumbItem>

      <BreadcrumbItem>
        <Text fontSize={"sm"}>{question?.university || question?.qcm?.university?.name || "n/a"}</Text>
      </BreadcrumbItem>
      <BreadcrumbItem>
        <Text fontSize={"sm"}>{question?.year || "n/a"}</Text>
      </BreadcrumbItem>
      <BreadcrumbItem>
        <Text fontSize={"sm"}>
          {question?.order || "n/a"}
          {question?.order === 1 ? "ère " : "ème "}question
        </Text>
      </BreadcrumbItem>
    </Breadcrumb>
  );
}
