import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { useRef } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation, useQueries, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import Field from "../../../components/Field/Field";
import MyToast from "../../../components/Toast/Toast";
import { updateSeriesInfo } from "../../../services/series/services";
import { getSpecialities } from "../../../services/specialities/services";
import { getSubjects } from "../../../services/subjects/services";
import { getUniversities } from "../../../services/university/services";
import { IInputField } from "../../../types";

interface Props {
  onClose: () => void;
  isOpen: boolean;
  serie: any;
}

function SeriesInfoForm({ onClose, isOpen, serie }: Props) {
  const initialRef = useRef(null);
  const finalRef = useRef(null);

  const [
    { data: subjects, isLoading: subjectsLoading },
    { data: specialities, isLoading: specialitiesLoading },
    { data: universities, isLoading: universitiesLoading },
  ] = useQueries([
    {
      queryKey: "subjects",
      queryFn: getSubjects,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    },
    {
      queryKey: "specialities",
      queryFn: getSpecialities,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    },
    {
      queryKey: "universities",
      queryFn: getUniversities,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    },
  ]);

  return (
    <>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Information de base</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {subjectsLoading || specialitiesLoading || universitiesLoading ? (
              <Box h="32" display={"grid"} placeItems="center">
                <Spinner />
              </Box>
            ) : (
              <ModalForm
                serie={serie}
                onClose={onClose}
                universities={universities}
                subjects={subjects}
                specialities={specialities}
              />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
export default SeriesInfoForm;

const ModalForm = ({
  serie,
  specialities,
  subjects,
  universities,
  onClose,
}: any) => {
  const { serieId } = useParams<{ serieId: string }>();
  const methods = useForm({
    defaultValues: {
      speciality: serie?.speciality ? serie?.speciality._id : "",
      subject: serie?.subject ? serie?.subject._id : "",
      university: serie?.university ? serie?.university._id : "",
      year: serie?.year ? serie?.year : "",
    },
  });

  const toast = useToast();
  const informationFields: IInputField[] = [
    {
      type: "select",
      name: "speciality",
      placeholder: "Spécialité",
      height: "15rem",
      options: specialities.map((el: any) => ({
        id: el._id,
        label: el.name,
        value: el._id,
      })),
      rules: {
        required: "Veuillez sélectionner la spécialité",
      },
    },
    {
      type: "select",
      name: "subject",
      placeholder: "Sujet",
      height: "15rem",
      options: subjects
        .filter((x: any) => x.speciality._id == methods.watch("speciality"))
        .map((el: any) => ({
          id: el._id,
          label: el.name,
          value: el._id,
        })),
      rules: {
        required: "Veuillez sélectionner le sujet",
      },
    },
    {
      type: "select",
      name: "university",
      placeholder: "Faculté",
      options: universities.map((el: any) => ({
        id: el._id,
        label: el.name,
        value: el._id,
      })),
      rules: {
        required: "Veuillez sélectionner la facultée",
      },
    },
    {
      type: "text",
      name: "year",
      placeholder: "Année",
      rules: {
        required: "Veuillez sélectionner l'année",
        pattern: /^(0|[1-9]\d*)(\.\d+)?$/
      },
    },
  ];
  const queryClient = useQueryClient();

  const onsubmit = async (data: any) => {
    await saveQuestion({ id: serieId, data });
    await queryClient.invalidateQueries(`single-serie-${serieId}`);
    onClose();
  };

  const { mutateAsync: saveQuestion, isLoading: loadingQuestion } = useMutation(
    updateSeriesInfo,
    {
      onSuccess: () => {
        MyToast({ toast, status: "success", title: "Série modifiée." });
      },
      onError: () => {
        toast({
          title: "Something went wrong.",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "bottom-right",
        });
      },
    }
  );

  const getSelectValue = (fieldName: string) => {
    switch (fieldName) {
      case "subject":
        return methods.getValues("subject");
      case "speciality":
        return methods.getValues("speciality");
      case "university":
        return methods.getValues("university");
      case "year":
        return methods.getValues("year");
      default:
        return "";
    }
  };

  return (
    <FormProvider {...methods}>
      <Stack spacing={3} as="form" onSubmit={methods.handleSubmit(onsubmit)}>
        {informationFields.map((field: any) => (
          <Field
            key={field.name}
            {...field}
            value={getSelectValue(field.name)}
            bg="darkGrey"
          />
        ))}
        <ModalFooter pr="0" mt="3">
          <Button
            mr={3}
            variant="ghost"
            disabled={loadingQuestion}
            onClick={onClose}
          >
            Annuler
          </Button>
          <Button
            variant="primary"
            type="submit"
            disabled={!serie}
            isLoading={loadingQuestion}
            loadingText={"En cours"}
          >
            Sauvegarder
          </Button>
        </ModalFooter>
      </Stack>
    </FormProvider>
  );
};
