export const columns = [
  "État",
  "Épreuve",
  "Spécialité",
  "Sujet",
  "Année",
  "Fac",
  "Type",
  "Erreur",
  "N°",
  "Signal",
];

export const seriesQuestionsColumns = [
  "Épreuve",
  "Spécialité",
  "Sujet",
  "Année",
  "Fac",
  "N°",
  "Cas Clinique",
  ""
];

export const FILTER_SIDEBAR = 'filter'
export const HISTORY_SIDEBAR = 'history'
export const FILTER_SERIES_SIDEBAR_SERIES = 'filter_series_sidebar_page'
export const FILTER_ORDERS_SIDEBAR = 'filter_orders_sidebar'
export const REMARQUES_SIDEBAR = 'remarques_sidebar'