import { Route, Switch, useRouteMatch } from "react-router-dom";
import Series from "../../pages/Unauthorized/Series/Series";
import SeriesAdd from "../../pages/Unauthorized/Series/SeriesAdd";
import SeriesBySubject from "../../pages/Unauthorized/Series/SeriesBySubject";
import SingleSerie from "../../pages/Unauthorized/Series/SingleSerie";
import SpecialitiesSubjects from "../../pages/Unauthorized/Series/SpecialitiesSubjects";

export default function SeriesContainer() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <Series path={path}/>
      </Route>
      <Route exact path={`${path}/:specialityName`}>
        <SpecialitiesSubjects />
      </Route>
      <Route exact path={`${path}/:specialityName/:subjectName`}>
        <SeriesBySubject />
      </Route>
      <Route exact path={`${path}/:specialityName/:subjectName/ajouter`}>
        <SeriesAdd />
      </Route>
      <Route exact path={`${path}/:specialityName/:subjectName/:serieId`}>
        <SingleSerie />
      </Route>
      <Route>
        <h1>Page Not Found</h1>
      </Route>
    </Switch>
  );
}
