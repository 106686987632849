import { useState } from "react";
import { AddIcon } from "@chakra-ui/icons";
import {
  Button,
  Heading,
  HStack,
  Icon,
  LinkBox,
  Stack,
  Tag,
  Text,
  useColorModeValue,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { useQuery } from "react-query";
import { useHistory, useRouteMatch } from "react-router-dom";
import TestIcon from "../../../assets/icons/TestIcon";
import PageHeading from "../../../components/PageHeading/PageHeading";
import SidebarLayout from "../../../layouts/SidebarLayout";
import { getUniversities } from "../../../services/university/services";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { getExams } from "../../../services/Exams/services";
import MyToast from "../../../components/Toast/Toast";
import Forbidden from "../../../components/Forbidden/Forbidden";

export default function Exams() {
  const [notAuthorized, setNotAuthorized] = useState<boolean | string>(false);
  const { url, path } = useRouteMatch();
  const textColor = useColorModeValue("blue-light", "blue-light");
  const bgBox = useColorModeValue("lightGrey", "card-dark");
  const bgStack = useColorModeValue("gray.800", "white");
  const history = useHistory();
  const toast = useToast();

  const { data: universities, isLoading: loadingUniversities } = useQuery(
    "universities",
    getUniversities,
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: (err: any) => {
        MyToast({
          toast,
          description: err.response.data.message,
          status: "warning",
          title: "Impossible",
        });
      },
    }
  );
  const { data: count, isLoading: loadingexamsCount } = useQuery(
    "examsCount",
    getExams,
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: (err: any) => {
        MyToast({
          toast,
          description: err.response.data.message,
          status: "warning",
          title: "Impossible",
        });
        setNotAuthorized(true);
      },
    }
  );

  return (
    <SidebarLayout>
      <HStack justify={"space-between"} wrap="wrap">
        <PageHeading title="Examen blanc" />
        <Button
          leftIcon={<AddIcon />}
          onClick={() => history.push(`${url}/ajouter`)}
          variant="primary"
          size="sm"
          height="2.5rem"
        >
          Créer un examen
        </Button>
      </HStack>
      {notAuthorized && <Forbidden msg={notAuthorized} />}
      {!notAuthorized && [...Array(new Date().getFullYear() - 2014)].map((_, i: number) => (
        <Stack my="4" key={i} spacing={6}>
          <Heading as="h1" size="md" color={textColor}>
            {(new Date().getFullYear() - i).toString()}
          </Heading>
          <HStack>
            {loadingUniversities || loadingexamsCount ? (
              <p>Loading...</p>
            ) : !notAuthorized && (
              <Swiper
                slidesPerView={1.5}
                spaceBetween={15}
                loop={false}
                autoplay={false}
                pagination={{
                  clickable: false,
                  type: "custom",
                }}
                breakpoints={{
                  480: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                  },
                  1200: {
                    slidesPerView: 4,
                    spaceBetween: 30,
                  },
                }}
                // navigation={true}
                // modules={[Navigation]}
                grabCursor={true}
                className="mySwiper2"
              >
                {universities.map((university: any) => (
                  <SwiperSlide key={university._id}>
                    <LinkBox
                      onClick={() =>
                        history.push(
                          `${path}/${(
                            new Date().getFullYear() - i
                          ).toString()}/${university.name}`
                        )
                      }
                      as="article"
                      w="full"
                      maxW={"400px"}
                      bg={bgBox}
                      p="5"
                      borderWidth="1px"
                      borderColor={"transparent"}
                      rounded=".8rem"
                      transition={"all 0.15s ease-in-out"}
                      _hover={{
                        borderColor: "darkSky",
                        boxShadow: "xs",
                        cursor: "pointer",
                      }}
                      overflow="hidden"
                    >
                      <Stack
                        color={bgStack}
                        h="full"
                        justify={"space-between"}
                        align="center"
                      >
                        <Icon as={TestIcon} />
                        <Text>{university.name}</Text>
                        <Text color="gray.500" fontWeight={"bold"}>
                          {count
                            .filter((el: any) => el._id === university.name)
                            .map((el: any) =>
                              el.exams.filter(
                                (x: any) =>
                                  x.year ===
                                  (new Date().getFullYear() - i).toString()
                              )
                            )[0]?.length || 0}{" "}
                          / 2
                        </Text>
                      </Stack>
                    </LinkBox>
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </HStack>
        </Stack>
      ))}
    </SidebarLayout>
  );
}
