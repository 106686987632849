import axiosInstance from "../../utils/axiosInstance";

export const getExams = async () => {
  let token = localStorage.getItem("token");
  return await axiosInstance({
    method: "get",
    url: `/qcm/all/exams`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data);
};


export const getExamSingleExam = async (universityName: string, year: string, day:string, page: number) => {
  let token = localStorage.getItem("token");
  return await axiosInstance({
    method: "get",
    url: `/qcm/single/exams?university=${universityName}&year=${year}&day=${day}&skip=${page*20}&limit=20`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data);
};


export const getExamsCount = async ({university, year}:{university: string, year: string}) => {
  let token = localStorage.getItem("token");
  return await axiosInstance({
    method: "get",
    url: `/qcm/exams/count?university=${university}&year=${year}`,
    headers:{
      Authorization: `Bearer ${token}`
    }
  }).then((res: any) => res.data);
};