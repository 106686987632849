import { Route, Switch, useRouteMatch } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import NotFound from "../../pages/Authorized/NotFound/NotFound";
import CollaborateurFormWrapper from "../../pages/Unauthorized/Profile/Collaborateurs/CollaborateurForm";
import CollaborateursList from "../../pages/Unauthorized/Profile/Collaborateurs/CollaborateursList";

export default function CollaborateurContainer() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <Navbar path={path} location="collaborateurs" />
        <CollaborateursList path={path} />
      </Route>
      <Route exact path={`${path}/collaborateur/:fullname`}>
        <CollaborateurFormWrapper />
      </Route>
      <Route exact path={`${path}/ajouter-collaborateur`}>
        <CollaborateurFormWrapper />
      </Route>
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}
