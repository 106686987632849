import { Route, Redirect } from "react-router-dom";
import { useAuthProvider } from "./auth-provider/AuthProvider";

interface Props {
  component: any;
  permission: string;
  path: string;
  exact?: boolean;
}

const ProtectedRouteByRole = ({
  component: Component,
  permission,
  ...rest
}: Props) => {
  const { data: auth } = useAuthProvider();
  const userRole = auth?.role;
  const userPermissions = auth?.permissions?.map((el: any) => el.name);
  const user = auth;

  const isAuthenticated = user !== null;

  const isAdmin = userRole === "admin";
  const hasRequiredPermissions = userPermissions?.find((el) =>
    el.includes(permission)
  );

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated && (isAdmin || hasRequiredPermissions) ? (
          <Component {...props} />
        ) : (
          <Redirect to="/unauthorized" />
        )
      }
    />
  );
};

export default ProtectedRouteByRole;
