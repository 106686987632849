import { Avatar, Badge, Button, HStack, Text } from "@chakra-ui/react";
import moment from "moment";
import { useState } from "react";
import { useQuery } from "react-query";
import CustomTable from "../../../components/CustomTable/CustomTable";
import LoadingTable from "../../../components/LoadingTable/LoadingTable";
import PageHeading from "../../../components/PageHeading/PageHeading";
import { Td, Tr } from "../../../components/Table/Table";
import { getUsers } from "../../../services/users/services";
import google from "../../../assets/img/google.png";
import fb from "../../../assets/img/fb.png";
import email from "../../../assets/img/email.png";
import PaginationTable from "../../../components/CustomTable/PaginationTable";
import { Link } from "react-router-dom";
import VerifyUser from "./VerifyUser";

const columns = [
  "",
  "Nom",
  "Email",
  "Date d'inscription",
  "Abonnement",
  "Dernière connexion",
  "",
];

export default function UsersList({ path }: { path: string }) {
  const [page, setPage] = useState(0);
  const [users, setUsers] = useState<any>({
    results: [],
  });

  const { isLoading: isLoadingReports, isFetching: isFetchingNextPage } =
    useQuery(["users", page], () => getUsers(page), {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      onSuccess: (response: any) => {
        if (response.nextPage !== users?.nextPage) {
          setUsers((prevState: any) => ({
            ...response,
            results: [...prevState.results, ...response.results],
          }));
        } else {
          setUsers(response);
        }
      },
    });

  return (
    <div>
      <HStack align="center">
        <PageHeading title="Utilisateurs" />
        <Badge>{users?.count}</Badge>
      </HStack>
      <CustomTable columns={columns}>
        {isLoadingReports ? (
          <LoadingTable />
        ) : (
          users?.results?.map((user: any) => (
            <Tr key={user._id}>
              <Td width="40px">
                <VerifyUser user={user} page={page} />
              </Td>
              <Td>
                <HStack align="center" spacing={3}>
                  <Avatar src={user?.avatar} size="sm" position={"relative"}>
                    <Avatar
                      boxSize="1.8em"
                      position={"absolute"}
                      bg="white"
                      p="0.5"
                      bottom="0px"
                      right="-5px"
                      size="xs"
                      src={
                        user?.provider === "google"
                          ? google
                          : user?.provider === "facebook"
                          ? fb
                          : email
                      }
                    />
                  </Avatar>
                  <Text fontSize={"md"}>{user?.fullname}</Text>
                </HStack>
              </Td>
              <Td>
                <Text fontSize={"md"}>{user?.email}</Text>
              </Td>
              <Td>
                <Text fontSize={"md"}>
                  {moment(user?.createdAt).format("DD/MM/YYYY H:mm")}
                </Text>
              </Td>
              <Td>
                <Text fontSize={"md"}>Gratuit</Text>
              </Td>
              <Td>
                <Text fontSize={"md"}>
                  {user.last_login
                    ? moment(user?.last_login).format("DD/MM/YYYY HH:mm")
                    : "---"}
                </Text>
              </Td>
              <Td width="5%">
                <Link to={`${path}/${user._id}`}>
                  <Button size={"sm"}>Voir détails</Button>
                </Link>
              </Td>
            </Tr>
          ))
        )}
      </CustomTable>
      <PaginationTable
        isFetchingNextPage={isFetchingNextPage}
        isLoading={isLoadingReports}
        nextPage={users?.nextPage}
        totalPages={users?.totalPages}
        setPage={(x: number) => setPage(x)}
      />
    </div>
  );
}
