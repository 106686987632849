import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import { IconButton, Tooltip } from "@chakra-ui/react";
import { FaFilePdf } from "react-icons/fa6";

// Define styles for the PDF content
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "white",
    paddingTop: "10mm",
    paddingLeft: "10mm",
    paddingRight: "10mm",
    paddingBottom: 65,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  table: {
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#bfbfbf",
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableColLeft: {
    fontSize: "12px",
    width: "30%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#bfbfbf",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 8,
  },
  tableCol: {
    fontSize: "12px",
    width: "70%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#bfbfbf",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 8,
  },
});

const PDFFile = ({ data }: any) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableColLeft}>
                <Text>Référence</Text>
              </View>
              <View style={styles.tableCol}>
                <Text>{data.reference}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableColLeft}>
                <Text>Prènom</Text>
              </View>
              <View style={styles.tableCol}>
                <Text>{data.firstName}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableColLeft}>
                <Text>Nom</Text>
              </View>
              <View style={styles.tableCol}>
                <Text>{data.lastName}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableColLeft}>
                <Text>E-mail</Text>
              </View>
              <View style={styles.tableCol}>
                <Text>{data.email}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableColLeft}>
                <Text>Tél</Text>
              </View>
              <View style={styles.tableCol}>
                <Text>{data.phone}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableColLeft}>
                <Text>Boutique</Text>
              </View>
              <View style={styles.tableCol}>
                <Text>{data.pickUpStore}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableColLeft}>
                <Text>Coupon</Text>
              </View>
              <View style={styles.tableCol}>
                <Text>{data.voucher}</Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const OrderDetailsPdf = ({ data }: any) => {
  const toExport = {
    firstName: data.firstName,
    lastName: data.lastName,
    email: data?.email || "",
    phone: data?.phone || "",
    reference: data?.reference,
    orderType: data?.orderType,
    pickUpStore: data?.pickUpStore || "",
    voucher: data?.voucher?.replace(/(.{4})(?!$)/g, "$1-"),
  };
  return (
    <div>
      <PDFDownloadLink
        document={<PDFFile data={toExport} />}
        fileName={`${toExport.firstName} ${toExport.lastName}`}
      >
        {({ loading }) => (
          <Tooltip label="Télécharger pdf" hasArrow>
            <IconButton
              size={"md"}
              aria-label="download pdf"
              isLoading={loading}
              icon={<FaFilePdf />}
              onClick={(e) => e.stopPropagation()}
            />
          </Tooltip>
        )}
      </PDFDownloadLink>
    </div>
  );
};

export default OrderDetailsPdf;
