import { Route, Switch, useRouteMatch } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import SidebarLayout from "../../layouts/SidebarLayout";
import Profile from "../../pages/Unauthorized/Profile/Profile";
import CollaborateurContainer from "./Collaborateurs.container";

export default function ProfileContainer() {
  const { path } = useRouteMatch();

  return (
    <SidebarLayout>
      <Switch>
        <Route exact path={`${path}`}>
          <Navbar path={path} location="profile"/>
          <Profile />
        </Route>
        <Route path={`${path}/gestion-des-collaborateurs`}>
          <CollaborateurContainer />
        </Route>
        <Route>
          <h1>Page Not Found</h1>
        </Route>
      </Switch>
    </SidebarLayout>
  );
}
