export const formatUniversityName = (name: string): string => {
  switch (name) {
    case "F.M.Sousse":
      return "fms";
    case "F.M.Tunis":
      return "fmt";
    case "F.M.Sfax":
      return "fmsf";
    case "F.M.Monastir":
      return "fmm";
    default:
      return "fmt";
  }
};

export const formatReportsErrorMsg = (name: string): string => {
  switch (name) {
    case "Les réponses proposées":
      return "rép";
    case "Explication":
      return "exp";
    case "La question est dupliquée":
      return "dup";
    case "L'énoncé":
      return "éno";
    default:
      return "éno";
  }
};

export const formatQcmType = (name: string): string => {
  switch (name) {
    case "Série":
      return "sér";
    case "Examen":
      return "exa";
    default:
      return "exa";
  }
};
