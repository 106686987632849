import { TextField, PasswordField, SelectField, DatepickerField } from "../Form";
import CheckBoxField from "../Form/CheckboxField";
import NumberField from "../Form/NumberField";
// import {
//     DateField,
//     InputField,
//     NationalityField,
//     PhoneField,
//     FieldArray,
//     RadioField,
//     SelectField,
//     TimeField,
//     DropzoneField,
//     CheckBoxField,
//     DateTimeField,
//     AsyncSearchField,
//   } from "./Form";
//   import { FieldPropType } from "./Form/FieldPropType";
  
  const Field = ({ type, ...rest }: any) => {
    return (
      <>
        {type === "text" ? (
          <TextField {...rest} />
        ) :
        type === "number" ? (
          <NumberField {...rest} />
        ) :
        type === "password" ? (
          <PasswordField {...rest} />
        ) :
        type === "select" ? (
          <SelectField {...rest} />
        ) :
        type === "checkbox" ? (
          <CheckBoxField {...rest} />
        ) :
        type === "date" ? (
          <DatepickerField {...rest} />
        ) :
        // type === "nationality" ? (
        //   <NationalityField {...rest} />
        // ) : type === "dropzone" ? (
        //   <DropzoneField {...rest} />
        // ) : type === "phone" ? (
        //   <PhoneField {...rest} />
        // ) : type === "radio" ? (
        //   <RadioField {...rest} />
        // ) : type === "fieldArray" ? (
        //   <FieldArray {...rest} />
        // ) : type === "select" ? (
        //   <SelectField {...rest} />
        // ) : type === "time" ? (
        //   <TimeField {...rest} />
        // ) : type === "date_time" ? (
        //   <DateTimeField {...rest} />
        // ) : type === "search" ? (
        //   <AsyncSearchField {...rest} />
        // ) : type === "checkbox" ? (
        //   <CheckBoxField {...rest} />
        // ) : 
        null}
      </>
    );
  };
  
  export default Field;
  