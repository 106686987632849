import { useSidebar } from "../../context/SidebarContext";
import SidebarFilterOrders from "../../pages/Unauthorized/PackOrders/components/SidebarFilterOrders";
import SidebarFilter from "../../pages/Unauthorized/Remarques/components/SidebarFilter";
import SidebarReportHistory from "../../pages/Unauthorized/Remarques/components/SidebarReportHistory";
import {
  FILTER_ORDERS_SIDEBAR,
  FILTER_SERIES_SIDEBAR_SERIES,
  FILTER_SIDEBAR,
  HISTORY_SIDEBAR,
  REMARQUES_SIDEBAR,
} from "../../pages/Unauthorized/Remarques/constants";
import RemarquesSidebar from "../../pages/Unauthorized/Series/components/RemarquesSidebar";
import SidebarFilterQuestions from "../../pages/Unauthorized/Series/components/SidebarFilterQuestions";

export default function Sidebar() {
  const {
    state: { sidebar },
  } = useSidebar();

  switch (sidebar) {
    case HISTORY_SIDEBAR:
      return <SidebarReportHistory />;
    case FILTER_SIDEBAR:
      return <SidebarFilter />;
    case FILTER_SERIES_SIDEBAR_SERIES:
      return <SidebarFilterQuestions />;
    case REMARQUES_SIDEBAR:
      return <RemarquesSidebar />;
    case FILTER_ORDERS_SIDEBAR:
      return <SidebarFilterOrders />;
    default:
      return <h1>History</h1>;
  }
  return <div>index</div>;
}
