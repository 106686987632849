import { Td, Tr } from "../../../../components/Table/Table";
import { IconButton, Text, Tooltip } from "@chakra-ui/react";
import { formatUniversityName } from "../../../../utils/utils";
import { Question } from "../../Remarques/components/AccordionQuestion";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { MouseEvent, MouseEventHandler } from "react";

interface QuestionDetails {
  updatedBy?: { fullname: string; _id: string };
  lastUpdatedAt: string;
}

interface Props {
  expandedRow: string | null;
  handleRowClick: (
    _id: string,
    questionId: Question,
    questionDetails: QuestionDetails
  ) => void;
  question: Question;
}

export default function QuestionRow({
  expandedRow,
  handleRowClick,
  question,
}: Props) {
  return (
    <Tr
      height="50px"
      cursor="pointer"
      _hover={{ bg: "lightGrey" }}
      bg={expandedRow === question._id ? "#EDF2F7" : "gray.50"}
      onClick={() =>
        handleRowClick(question._id, question, {
          updatedBy: question.updatedBy,
          lastUpdatedAt: question.lastUpdatedAt || "",
        })
      }
    >
      <Td>J.{question.qcm.speciality.jour}</Td>
      <Td>
        <Tooltip label={question.qcm.speciality.name} hasArrow openDelay={2000}>
          <Text isTruncated maxW={"200px"}>
            {question.qcm.speciality.name}
          </Text>
        </Tooltip>
      </Td>
      <Td>
        <Tooltip label={question.qcm.subject.name} hasArrow openDelay={2000}>
          <Text isTruncated maxW={"200px"}>
            {question.qcm.subject.name}
          </Text>
        </Tooltip>
      </Td>
      <Td>{question.qcm.year}</Td>
      <Td>
        <Tooltip label={question.qcm.university.name} hasArrow openDelay={2000}>
          <p>{formatUniversityName(question.qcm.university.name)}</p>
        </Tooltip>
      </Td>
      <Td>{question.order}</Td>
      <Td w="80px">
        {question.children?.length ? <span>✅</span> : <span>❌</span>}
      </Td>
      <Td w="80px">
        {question.children?.length !== 0 && (
          <IconButton
            onClick={(event: MouseEvent<HTMLButtonElement>) => {
              event.stopPropagation();
              window.open(`/questions/modifier/${question._id}`, "_blank", "noopener noreferrer");
            }}
            aria-label="go to cas clinique question"
            icon={<ExternalLinkIcon />}
          />
        )}
      </Td>
    </Tr>
  );
}
