import { useMutation, useQueryClient } from "react-query";
import { IconButton, Tooltip, useToast } from "@chakra-ui/react";
import { MdVerifiedUser, MdOutlineVerifiedUser } from "react-icons/md";
import MyToast from "../../../components/Toast/Toast";
import { verifyUserAccount } from "../../../services/users/services";

export default function VerifyUser({ user, page }: any) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync, isLoading } = useMutation(verifyUserAccount, {
    onSuccess: (res) => {
      queryClient.invalidateQueries(["users", page]);
      MyToast({
        toast,
        status: "success",
        title: "Compte vérifé",
        description: `${res.email} a été vérifé`,
        duration: 5000
      });
    },
    onError: (err: any) => {
      MyToast({
        toast,
        description: err.response.data.message,
        status: "error",
        title: "Impossible",
      });
    },
  });

  return user.email_verified ? (
    <Tooltip label="Compte vérifé" hasArrow>
      <IconButton
        icon={<MdVerifiedUser color={"green"} />}
        isDisabled
        aria-label="User account verified"
      />
    </Tooltip>
  ) : (
    <Tooltip label="Vérifier ce compte" hasArrow>
      <IconButton
        icon={<MdOutlineVerifiedUser color="#4B62F0" />}
        aria-label="Verify User"
        onClick={() => mutateAsync(user._id)}
        isLoading={isLoading}
        bg="new-light-blue"
      />
    </Tooltip>
  );
}
