import { useRef, ReactElement } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { FormProvider, useForm } from "react-hook-form";
import { CgAddR } from "react-icons/cg";
import { useMutation, useQueryClient } from "react-query";
import FilterSquareIcon from "../../../assets/icons/FilterSquareIcon";
import SidebarCloseIcon from "../../../assets/icons/SidebarCloseIcon";
import SidebarOpenIcon from "../../../assets/icons/SidebarOpenIcon";
import Field from "../../../components/Field/Field";
import Sidebar from "../../../components/Sidebar";
import { Filter, useSidebar } from "../../../context/SidebarContext";
import FixedRightSidebar, {
  LeftSide,
  RightPart,
} from "../../../layouts/FixedRightSidebar";
import { IInputField } from "../../../types";
import { FILTER_ORDERS_SIDEBAR } from "../Remarques/constants";
import TableComponent from "./TableComponent";
import { createPackOrder } from "../../../services/PackOrders/services";
import MyToast from "../../../components/Toast/Toast";
import queryString from "query-string";
import { useLocation } from "react-router-dom";

export default function PackOrders() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    handleChangeSidebar,
    handleIsSidebarOpen,
    state: { isSidebarOpen, filter },
  } = useSidebar();

  return (
    <FixedRightSidebar
      breadcrumbs={"filter"}
      basePage="Commandes"
      buttons={[
        {
          title: "Créer commande",
          icon: <CgAddR size={"1.5rem"} />,
          onClick: () => onOpen(),
        },
        {
          title: isSidebarOpen ? "Fermer" : "Ouvrir",
          icon: isSidebarOpen ? <SidebarCloseIcon /> : <SidebarOpenIcon />,
          onClick: () => handleIsSidebarOpen(!isSidebarOpen),
        },
        {
          title: "Filtrer",
          value: FILTER_ORDERS_SIDEBAR,
          icon: <FilterSquareIcon />,
          onClick: () => handleChangeSidebar(FILTER_ORDERS_SIDEBAR),
          isHidden: !isSidebarOpen,
        },
      ]}
    >
      <LeftSide>
        <TableComponent />
        <CreatePackOrder isOpen={isOpen} onClose={onClose} filter={filter}/>
      </LeftSide>
      <RightPart footerButtons={[]}>
        <Sidebar />
      </RightPart>
    </FixedRightSidebar>
  );
}
interface Props {
  isOpen: boolean;
  onClose: () => void;
  filter: Filter
}
const CreatePackOrder = ({ isOpen, onClose, filter }: Props) => {
  const {
    isOpen: isDialogOpen,
    onOpen: onDialogOpen,
    onClose: onDialogClose,
  } = useDisclosure();
  const packOrderMethods = useForm<any>();
  const toast = useToast();
  const queryClient = useQueryClient();
  const location = useLocation();

  const packOrderFields: IInputField[] = [
    {
      type: "text",
      name: "firstName",
      placeholder: "Prénom",
      rules: {
        required: "Veuillez entrer le prénom",
      },
    },
    {
      type: "text",
      name: "lastName",
      placeholder: "Nom",
      rules: {
        required: "Veuillez entrer le nom.",
      },
    },
    {
      type: "number",
      name: "phone",
      placeholder: "Numéro de téléphone",
      showControllers: false,
      rules: {
        required: "Veuillez entrer le numéro de téléphone",
      },
    },
    {
      type: "text",
      name: "email",
      placeholder: "Adresse mail",
      rules: {
        required: "Veuillez entrer l'adresse email",
      },
    },
    {
      type: "select",
      name: "pickUpStore",
      placeholder: "Boutique",
      options: [
        {
          id: 1,
          label: "Tunis",
          value: "Tunis",
        },
        {
          id: 2,
          label: "Sousse",
          value: "Sousse",
        },
      ],
      rules: {
        required: "Veuillez sélectionner le boutique",
      },
    },
  ];

  const { mutateAsync, isLoading } = useMutation(createPackOrder, {
    onSuccess: async () => {
      MyToast({
        toast,
        status: "success",
        title: "Commande crée.",
      });
      onClose();
      packOrderMethods.setValue("firstName", "");
      packOrderMethods.setValue("lastName", "");
      packOrderMethods.setValue("phone", "");
      packOrderMethods.setValue("email", "");
      packOrderMethods.setValue("pickUpStore", "");
      queryClient.invalidateQueries(["pack-orders"]);
    },
    onError: (err: any) => {
      MyToast({
        toast,
        description: err.response.data.message,
        status: "warning",
        title: "Impossible",
      });
    },
  });

  const handleSubmit = (data: any) => {
    mutateAsync(data)
  };

  const checkIfAnyPropertyHasValue = (obj: any) => {
    for (let key in obj) {
      if (obj[key] !== undefined && obj[key] !== "") {
        return true;
      }
    }
    return false;
  };

  const handleClose = () => {
    const formValues = packOrderMethods.getValues();
    const isNotEmpty = checkIfAnyPropertyHasValue(formValues);
    if (isNotEmpty) {
      onDialogOpen();
    } else {
      onClose();
    }
  };

  const confirmCloseDialog = () => {
    packOrderMethods.setValue("firstName", "");
    packOrderMethods.setValue("lastName", "");
    packOrderMethods.setValue("phone", "");
    packOrderMethods.setValue("email", "");
    packOrderMethods.setValue("pickUpStore", "");
    onDialogClose();
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <FormProvider {...packOrderMethods}>
          <ModalHeader>Créer une commande</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack
              spacing={4}
              as="form"
              onSubmit={packOrderMethods.handleSubmit(handleSubmit)}
            >
              {packOrderFields.map((field: IInputField) => (
                <Box key={field.name}>
                  <FormLabel>{field.placeholder}</FormLabel>
                  <Field
                    onChange={(date: any) =>
                      packOrderMethods.setValue(field.name, date)
                    }
                    value={packOrderMethods.getValues(field.name)}
                    {...field}
                    bg="darkGrey"
                  />
                </Box>
              ))}
              <ModalFooter px="0">
                <Button variant="ghost" mr={3} onClick={handleClose} isDisabled={isLoading}>
                  Close
                </Button>
                <Button colorScheme="blue" type="submit" isLoading={isLoading}>
                  Créer commande
                </Button>
              </ModalFooter>
            </Stack>
          </ModalBody>
        </FormProvider>
      </ModalContent>
      <ConfirmDialog
        isOpen={isDialogOpen}
        onClose={onDialogClose}
        onConfirm={confirmCloseDialog}
      />
    </Modal>
  );
};

interface ConfirmDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const ConfirmDialog = ({ isOpen, onClose, onConfirm }: ConfirmDialogProps) => {
  const cancelRef = useRef<any>();

  return (
    <AlertDialog
      leastDestructiveRef={cancelRef}
      motionPreset="slideInBottom"
      onClose={onClose}
      isOpen={isOpen}
      isCentered
    >
      <AlertDialogOverlay />

      <AlertDialogContent>
        <AlertDialogHeader>Confirmation requise</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          Êtes-vous sûr de vouloir supprimer ce que vous avez saisi ?
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button onClick={onClose} ref={cancelRef}>
            Non
          </Button>
          <Button colorScheme="red" ml={3} onClick={onConfirm}>
            Oui
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
