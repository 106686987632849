import {
  Avatar,
  Grid,
  GridItem,
  Heading,
  HStack,
  List,
  ListItem,
  Spinner,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import google from "../../assets/img/google.png";
import fb from "../../assets/img/fb.png";
import email from "../../assets/img/email.png";
import { Link } from "react-router-dom";
import Forbidden from "../Forbidden/Forbidden";

interface Item {
  _id: {
    _id: string;
    fullname: string;
    avatar: string;
    provider: string;
  };
  answeredQuestions: number;
  attempts: number;
}

interface Props {
  mostActiveLoading: boolean;
  mostActive: Array<Item>;
}

export default function ActifUsers({ mostActiveLoading, mostActive }: Props) {
  return (
    <>
      <Heading
        as="h2"
        p="4"
        color={"blue-light"}
        fontSize="lg"
        position={"sticky"}
        top="0px"
        w="full"
        bg="lightGrey"
        zIndex={1}
      >
        Les utilisateurs les plus actifs
      </Heading>
      <Grid
        px="4"
        gridTemplateColumns={"minmax(120px, 1fr) 0.25fr 0.25fr"}
        gap={4}
        alignItems="center"
        mb="2"
      >
        <GridItem>
          <Text fontWeight={"semibold"} fontSize="sm">
            Nom
          </Text>
        </GridItem>
        <GridItem>
          <Text fontWeight={"semibold"} fontSize="sm">
            Tentatives
          </Text>
        </GridItem>
        <GridItem>
          <Text fontWeight={"semibold"} fontSize="sm">
            Répondu
          </Text>
        </GridItem>
      </Grid>
      <List spacing={0}>
        {mostActiveLoading ? (
          <Stack height="400px" align={"center"} justify="center">
            <Spinner color="gold-yellow" />
          </Stack>
        ) : (
          mostActive?.map((item: Item, index: number) => (
            <ListItem
              key={item?._id._id}
              bg={index % 2 === 0 ? "darkGrey" : "lightGrey"}
              px="4"
              py="2"
            >
              <Grid
                gridTemplateColumns={"minmax(120px, 1fr) 0.25fr 0.25fr"}
                gap={4}
                alignItems="center"
              >
                <GridItem isTruncated>
                  <HStack align="center" spacing={3}>
                    <Avatar
                      src={item?._id.avatar}
                      size="sm"
                      position={"relative"}
                    >
                      <Avatar
                        boxSize="1.8em"
                        position={"absolute"}
                        bottom="0px"
                        right="-5px"
                        bg="white"
                        p="0.5"
                        size="xs"
                        src={
                          item?._id?.provider === "google"
                            ? google
                            : item?._id?.provider === "facebook"
                            ? fb
                            : email
                        }
                      />
                    </Avatar>
                    <Tooltip
                      label={item?._id?.fullname}
                      hasArrow
                      openDelay={1000}
                    >
                      <Link to={`/utilisateurs/${item?._id?._id}`}>
                        <Text fontSize={"md"} isTruncated>
                          {item?._id?.fullname}
                        </Text>
                      </Link>
                    </Tooltip>
                  </HStack>
                </GridItem>
                <GridItem>
                  <Text>{item.attempts}</Text>
                </GridItem>
                <GridItem>
                  <Text>{item.answeredQuestions}</Text>
                </GridItem>
              </Grid>
            </ListItem>
          ))
        )}
      </List>
    </>
  );
}
