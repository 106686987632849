import { useState, useCallback, MouseEvent } from "react";
import { Tag, TagLabel, TagLeftIcon } from "@chakra-ui/react";
import { MdCheck, MdCancel } from "react-icons/md";
import { HiOutlineClock } from "react-icons/hi2";
import { IoSparklesSharp } from "react-icons/io5";
interface Props {
  voucher: string;
  voucherStatus: string;
}

function showFirstAndLastFour(str: string) {
  str = str.padStart(4, "*").padEnd(8, "*");

  let firstFour = str.slice(0, 4);
  let lastFour = str.slice(-4);

  return firstFour + "*****" + lastFour;
}

export default function VoucherTag({ voucher, voucherStatus }: Props) {
  const [show, setShow] = useState<boolean>(false);
  const getIcon = useCallback((voucherStatus: string) => {
    switch (voucherStatus) {
      // case "pending":
      //   return {
      //     icon: HiOutlineClock,
      //     colorScheme: "yellow",
      //   };
      case "used":
        return {
          icon: MdCheck,
          colorScheme: "whatsapp",
        };
      case "activated":
        return {
          icon: IoSparklesSharp,
          colorScheme: "messenger",
        };
      default:
        return {
          icon: MdCancel,
          colorScheme: "gray",
        };
    }
  }, []);

  const handleShowVoucher = useCallback(
    (e: MouseEvent<HTMLSpanElement>) => {
      e.stopPropagation();
      setShow(!show);
    },
    [show]
  );

  return (
    <Tag
      variant="outline"
      colorScheme={getIcon(voucherStatus)?.colorScheme}
      opacity={voucherStatus === "cancelled" ? "0.3" : 1}
      onClick={handleShowVoucher}
    >
      <TagLeftIcon as={getIcon(voucherStatus)?.icon} />
      <TagLabel>{show ? voucher : showFirstAndLastFour(voucher)}</TagLabel>
    </Tag>
  );
}
