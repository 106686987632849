import { Button, Heading, HStack, Square, Stack } from "@chakra-ui/react";
import { FormProvider, useForm } from "react-hook-form";
import { useQueries } from "react-query";
import FilterSquareIcon from "../../../../assets/icons/FilterSquareIcon";
import Field from "../../../../components/Field/Field";
import { useSidebar } from "../../../../context/SidebarContext";
import type { Filter } from "../../../../context/SidebarContext";
import { getSubjects } from "../../../../services/subjects/services";
import { getUniversities } from "../../../../services/university/services";
import { IInputField } from "../../../../types";
import SidebarHeader from "../../Remarques/components/SidebarHeader";
import { getSpecialities } from "../../../../services/specialities/services";

export default function SidebarFilterQuestions() {
  const {
    handleChangeQuestionFilter,
    handleResetFilter,
    state: { filter },
  } = useSidebar();
  const methods = useForm({
    defaultValues: {
      subject: filter.subject,
      year: filter.year,
      university: filter.university,
      speciality: filter.speciality,
      keyword: filter.keyword,
    },
  });

  const [subjects, universities, specialities] = useQueries([
    {
      queryKey: "subjects",
      queryFn: getSubjects,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    },
    {
      queryKey: "universities",
      queryFn: getUniversities,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    },
    {
      queryKey: "specialities",
      queryFn: getSpecialities,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    },
  ]);

  const getSelectValue = (fieldName: string) => {
    switch (fieldName) {
      case "subject":
        return methods.getValues("subject");
      case "university":
        return methods.getValues("university");
      case "year":
        return methods.getValues("year");
      case "speciality":
        return methods.getValues("speciality");
      case "keyword":
        return methods.getValues("keyword");
      default:
        return "";
    }
  };

  const filterFields: IInputField[] = [
    {
      type: "text",
      name: "keyword",
      placeholder: "Recherche",
      isUpperCase: true,
      borderColor: "newLightGrey",
      borderWidth: "2px",
      borderRadius: "xl",
      marginBottom: "8px",
    },
    {
      type: "select",
      name: "speciality",
      placeholder: "Spécialité",
      height: "20rem",
      isUpperCase: true,
      options: [
        {
          id: 300,
          label: "Tous",
          value: "",
        },
        ...(specialities?.data?.map((el: any) => ({
          id: el._id,
          label: el.name,
          value: el.name,
        })) ?? []),
      ],
    },
    {
      type: "select",
      name: "subject",
      placeholder: "Sujet",
      height: "20rem",
      isUpperCase: true,
      options: [
        {
          id: 300,
          label: "Tous",
          value: "",
        },
        ...(subjects?.data?.filter((el: any) => el.speciality.name === methods.watch("speciality"))?.map((el: any) => ({
          id: el._id,
          label: el.name,
          value: el.name,
        })) ?? []),
      ],
    },
    {
      type: "select",
      name: "year",
      placeholder: "Année",
      isUpperCase: true,
      options: [
        {
          id: 100,
          label: "Toutes les années",
          value: "",
        },
        ...[...Array(new Date().getFullYear() - 2014)].map((_, i) => ({
          id: i,
          label: (new Date().getFullYear() - i).toString(),
          value: (new Date().getFullYear() - i).toString(),
        })),
      ],
      height: "20rem",
    },
    {
      type: "select",
      name: "university",
      placeholder: "Faculté",
      isUpperCase: true,
      options: [
        {
          id: 4,
          label: "Toutes les facultés",
          value: "",
        },
        ...(universities?.data?.map((el: any) => ({
          id: el._id,
          label: el.name,
          value: el.name,
        })) ?? []),
      ],
    },
  ];

  const onSubmit = (data: any) => {
    handleChangeQuestionFilter(data);
  };

  const handleReset = () => {
    methods.setValue("subject", "");
    methods.setValue("year", "");
    methods.setValue("university", "");
    methods.setValue("speciality", "");
    methods.setValue("keyword", "");
    handleResetFilter();
  };
  return (
    <Stack align="stretch" spacing={6}>
      <SidebarHeader title="Filter" icon={<FilterSquareIcon />} />
      <FormProvider {...methods}>
        <Stack as="form" onSubmit={methods.handleSubmit(onSubmit)}>
          {filterFields.map((field: any) => (
            <Field
              key={field.name}
              {...field}
              value={getSelectValue(field.name)}
              bg="white"
              borderColor="newLightGrey"
              borderWidth="2px"
              borderRadius="xl"
            />
          ))}
          <HStack justify={"flex-end"} align="center">
            <Button
              variant={"outline"}
              flexGrow="1"
              rounded={"xl"}
              borderColor="newLightGrey"
              borderWidth="2px"
              fontWeight="medium"
              py="6"
              _hover={{ bg: "newLightGrey" }}
              onClick={handleReset}
            >
              Réinitialiser
            </Button>
            <Button
              variant={"outline"}
              type="submit"
              flexGrow="1"
              rounded={"xl"}
              borderColor="newLightGrey"
              borderWidth="2px"
              fontWeight="medium"
              py="6"
              _hover={{ bg: "newLightGrey" }}
            >
              Filtrer
            </Button>
          </HStack>
        </Stack>
      </FormProvider>
    </Stack>
  );
}
