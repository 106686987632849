import { Filter } from "../../context/SidebarContext";
import axiosInstance from "../../utils/axiosInstance";

export const getReports = async (page: number) => {
  let token = localStorage.getItem("token");
  const response = await axiosInstance({
    method: "get",
    url: `/report?skip=${page * 10}&limit=10`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return {
    results: response.data.results,
    nextPage: page + 1,
    totalPages: response.data.totalPages,
  };
};

export const resolveReports = async ({
  ids,
  resolved,
}: {
  ids: Array<string>;
  resolved: boolean;
}) => {
  let token = localStorage.getItem("token");
  return await axiosInstance({
    method: "put",
    url: `/report/many`,
    data: {
      ids,
      resolved,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data);
};

export const objectToQueryString = (obj: any) => {
  const queryString = Object.keys(obj)
    .filter(key => obj[key] !== '')
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    .join('&');

  return queryString;
}

export const getGroupedReports = async ({page, limit, filter}: {page: number, limit: number, filter: Filter}) => {
  let token = localStorage.getItem("token");
  const filterQueryString = objectToQueryString(filter)
  const response = await axiosInstance({
    method: "get",
    url: `/report/grouped-reports?skip=${page}&limit=${limit}&${filterQueryString}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data[0];
};

export const findSelectedOptionsCount = async (questionId: string|null) => {
  let token = localStorage.getItem("token");
  const response = await axiosInstance({
    method: "get",
    url: `/attempts-answers/question/${questionId}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};
