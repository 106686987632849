import { Badge } from "@chakra-ui/react";
import React from "react";

export default function ArchivedTag(props: any) {
  return (
    <Badge
      colorScheme="red"
      position={"absolute"}
      top="0"
      right={"2"}
      rounded="2xl"
      fontWeight={"normal"}
      textTransform="capitalize"
      {...props}
    >
      Archivée
    </Badge>
  );
}
