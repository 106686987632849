import { useColorModeValue } from "@chakra-ui/react";

export default function EmailIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g id="sms" opacity="0.5">
        <rect
          id="Rectangle_15"
          data-name="Rectangle 15"
          width="24"
          height="24"
          rx="5"
          fill="#ededed"
          opacity="0"
        />
        <g id="Group_17" data-name="Group 17" transform="translate(1.2 1.2)">
          <path
            id="Path_19"
            data-name="Path 19"
            d="M15.3,19.125h-9c-3.285,0-5.175-1.89-5.175-5.175V7.65c0-3.285,1.89-5.175,5.175-5.175h9c3.285,0,5.175,1.89,5.175,5.175v6.3C20.475,17.235,18.585,19.125,15.3,19.125Zm-9-15.3c-2.574,0-3.825,1.251-3.825,3.825v6.3c0,2.574,1.251,3.825,3.825,3.825h9c2.574,0,3.825-1.251,3.825-3.825V7.65c0-2.574-1.251-3.825-3.825-3.825Z"
            fill={useColorModeValue("#292d32","white")}
          />
          <path
            id="Path_20"
            data-name="Path 20"
            d="M10.8,11.583a3.346,3.346,0,0,1-2.106-.711L5.876,8.622a.673.673,0,0,1,.837-1.053L9.53,9.819a2.148,2.148,0,0,0,2.529,0l2.817-2.25a.673.673,0,1,1,.837,1.053L12.9,10.872a3.3,3.3,0,0,1-2.1.711Z"
            fill={useColorModeValue("#292d32","white")}
          />
        </g>
      </g>
    </svg>
  );
}
