import {
  Box,
  Button,
  FormLabel,
  ModalFooter,
  Spinner,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import Field from "../../../../components/Field/Field";
import MyToast from "../../../../components/Toast/Toast";
import {
  createSpeciality,
  updateSpeciality,
} from "../../../../services/specialities/services";
import { IInputField } from "../../../../types";

const specialityFields: IInputField[] = [
  {
    type: "text",
    name: "name",
    placeholder: "Nom du spécialité",
    rules: {
      required: "Veuillez entrer le nom du spécialité",
    },
  },
  {
    type: "text",
    name: "shortname",
    placeholder: "Abréviation du spécialité",
    rules: {
      required: "Veuillez entrer l'abréviation du spécialité",
    },
  },
  {
    type: "select",
    name: "jour",
    placeholder: "Jour de l'épreuve",
    options: [
      {
        id: 1,
        label: "Epreuve J1",
        value: "1",
      },
      {
        id: 2,
        label: "Epreuve J2",
        value: "2",
      },
    ],
    rules: {
      required: "Veuillez sélectionner le jour de l'épreuve",
    },
  },
];

const SpecialityForm = ({ speciality, onClose }: any) => {
  const params = useParams<{ name: string }>();
  const history = useHistory();
  const toast = useToast();

  const specialityMethods = useForm<any>({
    defaultValues: {
      name: params.name || "",
      shortname: params.name ? speciality?.shortname : '',
      jour: params.name ? speciality.jour : "",
    },
  });

  const { mutateAsync, isLoading } = useMutation(
    params.name ? updateSpeciality : createSpeciality,
    {
      onSuccess: async () => {
        MyToast({
          toast,
          status: "success",
          title: params.name ? "Spécialité modifiée." : "Spécialité ajoutée.",
        });
        history.goBack();
      },
      onError: (err: any) => {
        MyToast({
          toast,
          description: err.response.data.message,
          status: "warning",
          title: "Impossible",
        });
      },
    }
  );

  const onSubmit = (data: any) => {
    if (params.name) {
      mutateAsync({ ...data, id: speciality._id });
    } else {
      mutateAsync(data);
    }
  };

  return (
    <FormProvider {...specialityMethods}>
      <Stack
        spacing={4}
        as="form"
        onSubmit={specialityMethods.handleSubmit(onSubmit)}
      >
        {specialityFields.map((field: IInputField) => (
          <Box key={field.name}>
            <FormLabel>{field.placeholder}</FormLabel>
            <Field
              value={
                field.name === "jour" ? specialityMethods.getValues("jour") : ""
              }
              {...field}
              bg="darkGrey"
            />
          </Box>
        ))}
        <ModalFooter px="0">
          <Button onClick={onClose} variant={"ghost"} mr={3}>
            Annuler
          </Button>
          <Button variant={"primary"} type="submit" height="2.5rem">
            {isLoading ? (
              <Spinner size={"md"} color="gold-yellow" />
            ) : (
              "Sauvgarder"
            )}
          </Button>
        </ModalFooter>
      </Stack>
    </FormProvider>
  );
};

export default SpecialityForm;
