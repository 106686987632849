import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Spinner,
  Tooltip,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { useRef } from "react";
import { IDialog } from "../../types";

export default function Dialog({
  children,
  question,
  description,
  onClick,
  isLoading,
  withTooltip,
  tooltipText,
  full
}: IDialog) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef<any>();
  const sizes = useBreakpointValue({ base: "sm", "2xl": "md" });

  const onConfirm = async (e: any) => {
    e.stopPropagation();
    await onClick();
    onClose();
  };

  return (
    <>
      {withTooltip ? (
        <Tooltip label={tooltipText}>
          <Button
            onClick={onOpen}
            size={full ? "unset" : sizes}
            p="0"
            m="0"
            as="span"
            h="fit-content"
            w={full ? "full" : "fit-content"}
            bg="transparent"
            _hover={{}}
            _active={{}}
            cursor="pointer"
          >
            {children}
          </Button>
        </Tooltip>
      ) : (
        <Button
          onClick={onOpen}
          size={full ? "unset" : sizes}
          p="0"
          m="0"
          as="span"
          h="fit-content"
          w={full ? "full" : "fit-content"}
          bg="transparent"
          _hover={{}}
          _active={{}}
          cursor="pointer"
        >
          {children}
        </Button>
      )}

      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent bg={useColorModeValue("white", "blue-dark")}>
          <AlertDialogHeader>{question}</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>{description}</AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose} disabled={isLoading}>
              Non
            </Button>
            <Button
              colorScheme="red"
              ml={3}
              disabled={isLoading}
              onClick={onConfirm}
            >
              {isLoading ? <Spinner size={"md"} color="white" /> : "Oui"}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}
