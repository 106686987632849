import { Route, Switch, useRouteMatch } from "react-router-dom";
import Remarques from "../../pages/Unauthorized/Remarques";

export default function NewRemarquesContainer() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <Remarques />
      </Route>
      <Route>
        <h1>Page Not Found</h1>
      </Route>
    </Switch>
  );
}
