import {
  Button,
  Grid,
  HStack,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { ChangeEvent, useState } from "react";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import DownloadDocument from "../../../assets/icons/DownloadDocument";
import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb";
import ExportPDF from "../../../components/ExportPdf";
import FolderBox from "../../../components/FolderBox/FolderBox";
import SidebarLayout from "../../../layouts/SidebarLayout";
import { filterQuestionsBySpecAndYear } from "../../../services/questions/services";
import { getSubjectsBySpecialityName } from "../../../services/subjects/services";
import capitalizeText from "../../../utils/capitalizeText";

export default function SpecialitiesSubjects() {
  const { specialityName } = useParams<{ specialityName: string }>();
  const { data: subjects, isLoading: isLoadingSubjects } = useQuery(
    `subjects-${specialityName}`,
    () => getSubjectsBySpecialityName(specialityName),
    { refetchOnWindowFocus: false }
  );
  const history = useHistory();

  const breadcrumb = [
    {
      title: "Série",
      link: "/series",
      isCurrent: false,
    },
    {
      title: capitalizeText(specialityName),
      link: `/series/${specialityName}`,
      isCurrent: true,
    },
  ];
  return (
    <SidebarLayout>
      <HStack justifyContent={"space-between"}>
        <BreadCrumb
          items={breadcrumb}
          isArchived={!isLoadingSubjects && subjects[0]?.speciality?.archived}
        />
        <BasicUsage
          specialityId={subjects?.[0]?.speciality?._id}
          specialityName={specialityName}
        />
      </HStack>
      <Grid
        gridTemplateColumns={"repeat(auto-fill, minmax(300px, 1fr))"}
        justifyItems="center"
        gap="20px"
        my="4"
      >
        {isLoadingSubjects
          ? Array.apply(null, Array(12)).map((el: any, index: number) => (
              <Skeleton rounded={"0.8rem"} w="full" h="36" key={index} />
            ))
          : subjects.map((subject: any) => (
              <FolderBox
                goTo={() =>
                  history.push(`${history.location.pathname}/${subject.name}`)
                }
                item={subject}
                key={subject._id}
                itemType="subject"
              />
            ))}
      </Grid>
    </SidebarLayout>
  );
}

function BasicUsage({
  specialityId,
  specialityName,
}: {
  specialityId: string;
  specialityName: string;
}) {
  const [year, setYear] = useState<number>(0);
  const [pdfIsReady, setPdfIsReady] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const { data, isLoading, isError } = useQuery(
    ["questions", specialityId, year],
    () =>
      filterQuestionsBySpecAndYear({
        specialityId,
        year: String(year),
      }),
    {
      onSuccess: () => setPdfIsReady(true),
      onError: ({
        response: {
          data: { message },
        },
      }) => {
        toast({
          title: "Erreur",
          description: message,
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "bottom-right",
        });
      },
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      enabled: pdfIsReady,
    }
  );

  return (
    <>
      <IconButton
        aria-label="Download PDF"
        icon={<DownloadDocument />}
        onClick={onOpen}
        bg="new-light-blue"
        color="white"
        _hover={{ bg: "darkGrey" }}
        _active={{ bg: "blue-light", color: "white" }}
      />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Export</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              type={"number"}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setYear(+e.target.value)
              }
              placeholder="Année"
            />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            {pdfIsReady && data && !isError ? (
              <ExportPDF
                data={data}
                specialityName={specialityName}
                year={year}
              />
            ) : (
              <Button
                variant="ghost"
                onClick={() => setPdfIsReady(true)}
                isLoading={isLoading}
                loadingText="Chargement..."
              >
                Créer PDF
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
