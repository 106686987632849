import { Button, Input, InputGroup, InputLeftElement, InputRightElement, Text, useColorModeValue } from '@chakra-ui/react';
import { useState } from 'react'
import { Controller, useFormContext, useFormState } from 'react-hook-form';
import { IInputField } from '../../types';
import _ from "lodash"
import ViewOffIcon from '../../assets/icons/ViewOffIcon';
import ViewIcon from '../../assets/icons/ViewIcon';

export default function PasswordField({
    name,
    label,
    rules,
    placeholder,
    icon,
    bg,
    hidden = false,
    disabled = false,
  }: IInputField) {
    const [showPassword, setShowPassword] = useState(false);
    const { control, watch } = useFormContext();
  const { errors } = useFormState({ control });
  const bgColorMode = useColorModeValue(!hidden && _.get(errors, name) ? "red.50" : bg, !hidden && _.get(errors, name) ? "dark-blue-dark" : "dark-blue-dark")
    return (
        <div>
            <Controller
                name={name}
                control={control}
                defaultValue={_.get(watch(), name)}
                render={({ field: { ref, ...rest } }) => (
                    <InputGroup>
                        <InputLeftElement
                            pointerEvents='none'
                            children={icon}
                            h={"full"}
                        />
                        <Input
                            bg={bgColorMode}
                            hidden={hidden}
                            type={showPassword ? "text" : "password"}
                            variant={"primary"}
                            disabled={disabled}
                            placeholder={placeholder}
                            // inputref={ref}
                            {...rest}
                            // @ts-ignore
                            label={label}
                            // border={"none"}
                            size={"lg"}
                            borderRadius={"8px"}
                            fontWeight={"normal"}
                            _placeholder={{
                              fontWeight:"light",
                              color: "#bbb",
                              fontSize: {base: "0.9rem", "2xl":"1rem"}
                            }}
                            borderWidth={!hidden && _.get(errors, name) ? "1px":"0px"}
                            borderColor={!hidden && _.get(errors, name) ? "red.100":"transparent"}
                            height={{base: "2.5rem", "2xl":"3rem"}}
                        />
                        <InputRightElement h="full">
                            <Button
                                variant={'ghost'}
                                fontSize={"xl"}
                                p="0"
                                h="full"
                                borderTopLeftRadius={0}
                                borderBottomLeftRadius={0}
                                _focus={{outline:"none"}}
                                onClick={() =>
                                    setShowPassword((showPassword) => !showPassword)
                                }>
                                {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                            </Button>
                        </InputRightElement>
                    </InputGroup>
                )}
                rules={rules}
            />
            {!hidden && (
                // @ts-ignore
                <Text color="red" fontSize='sm'>{_.get(errors, name) ? _.get(errors, `${name}.message`) : null}</Text>
            )}
        </div>
    )
}
