import { Route, Switch, useRouteMatch } from "react-router-dom";
import SidebarLayout from "../../../../layouts/SidebarLayout";
import { IColumnTable } from "../../../../types";
import ResourcesTable from "../Resources.table";
import ResourcesForm from "../Resources.form";

export default function Universtities() {
  const { path } = useRouteMatch();

  const universitiesColumns: IColumnTable[] = [
    {
      textAlign: "left",
      label: "Nom",
    },
    {
      textAlign: "center",
      label: "Statut",
    },
    {
      textAlign: "left",
      label: "",
    },
  ];

  return (
    <SidebarLayout>
      <Switch>
        <Route exact path={`${path}`}>
          <ResourcesTable path={path} columns={universitiesColumns} />
        </Route>
        <Route path={`${path}/modifier/:name`}>
          <ResourcesForm path={path} />
          <ResourcesTable path={path} columns={universitiesColumns} />
        </Route>
        <Route path={`${path}/ajouter`}>
          <ResourcesForm path={path} />
          <ResourcesTable path={path} columns={universitiesColumns} />
        </Route>
        <Route>
          <h1>Page Not Founddd</h1>
        </Route>
      </Switch>
    </SidebarLayout>
  );
}
