import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from "@chakra-ui/modal";
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@chakra-ui/tabs";
import OrderHistorySteps from "./components/OrderHistorySteps";
import OrderSummary from "./components/OrderSummary";

interface PackOrderProps {
  isOpen: boolean;
  onClose: () => void;
  order: any;
}

function PackOrderModal({ order, onClose, isOpen }: PackOrderProps) {
  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        size={"xl"}
        scrollBehavior={"inside"}
      >
        <ModalOverlay />
        <ModalContent rounded={"1rem"}>
          <ModalHeader>{`${order.firstName} ${order.lastName}`}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Tabs>
              <TabList>
                <Tab>Détails</Tab>
                <Tab>Historique</Tab>
              </TabList>

              <TabPanels>
                <TabPanel>
                  <OrderSummary order={order} />
                </TabPanel>
                <TabPanel>
                  <OrderHistorySteps history={order.history} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default PackOrderModal;
