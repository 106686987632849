import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  BlobProvider,
  Image,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import alphabet from "../../utils/alphabet.json";
import { Button } from "@chakra-ui/react";
import { Fragment } from "react";

// Define styles for the PDF content
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "white",
    paddingTop: "10mm",
    paddingLeft: "10mm",
    paddingRight: "10mm",
    paddingBottom: 65,
  },
  text: {
    marginBottom: 10,
    fontSize: 12,
  },
  question: {
    marginBottom: "8px",
    marginTop: "8px",
  },
  title: {
    color: "#4ec6f3",
    textTransform: "uppercase",
    fontSize: "12px",
    marginBottom: "10px",
  },
  questionId: {
    fontSize: "9px",
    marginBottom: "3px",
    fontWeight: "bold",
  },
  ennonce: {
    fontSize: "9px",
    lineHeight: "1.5px",
    marginBottom: "5px",
  },
  option: {
    fontSize: "9px",
    marginTop: "2px",
    marginBottom: "2px",
  },
  image: {
    fontSize: "15px",
  },
});

function removeHtmlTagsAndAttributes(htmlString: string) {
  var regex = /<[^>]+>/g;
  return htmlString.replace(regex, "");
}

const Question = ({question}: any) => (
  <View style={styles.question}>
    <Text style={styles.questionId}>
      Q{question.order}. {question._id.slice(-5)}
    </Text>
    {question.value.blocks?.map((block: any) =>
      block.type !== "image" ? (
        <Text key={block.id} style={styles.ennonce}>
          {removeHtmlTagsAndAttributes(
            block.data.text?.replace(/&nbsp;|\n/g, "") ?? ""
          )}
        </Text>
      ) : (
        <Text style={styles.image}>Image: {block.data.file.url}</Text>
      )
    )}
    {question.options?.map((option: any, i: number) => (
      <View key={option._id}>
        <Text style={styles.option}>
          {alphabet[i]}. {option.value}
        </Text>
      </View>
    ))}
  </View>
);

const PDFFile = ({ data }: any) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View>
          {data?.map((qcm: any) => (
            <View key={qcm._id.qcmId}>
              <Text style={styles.title}>
                {qcm.questions[0]?.qcm.subject.name}/
                {qcm.questions[0]?.qcm.university.name}/
                {qcm.questions[0]?.qcm.year}
              </Text>
              {qcm.questions?.map((question: any) => (
                <Fragment key={question._id}>
                  <Question question={question} />
                  {question.children?.length !== 0 &&
                    question.children?.reverse()?.map((children: any) => (
                      <Question question={children} key={children._id} />
                    ))}
                </Fragment>
              ))}
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
};

const ExportPDF = ({ data, specialityName, year }: any) => {
  return (
    <div>
      <PDFDownloadLink
        document={<PDFFile data={data} />}
        fileName={`${specialityName}-${year}`}
      >
        {({ loading }) => (
          <Button my="4" isLoading={loading} loadingText="Chargement...">
            Télécharger PDF
          </Button>
        )}
      </PDFDownloadLink>
    </div>
  );
};

export default ExportPDF;
