import { Box, Stack, Text, VStack } from "@chakra-ui/react";
import moment from "moment";
import InfoCircleIcon from "../../../../assets/icons/InfoCircleIcon";
import { useSidebar } from "../../../../context/SidebarContext";
import SidebarHeader from "../../Remarques/components/SidebarHeader";

interface Report {
  _id: string;
  createdAt: string;
  message: string;
  comment?: string;
  user: {
    fullname: string;
  };
}

const sortEntriesByCreatedAt = (entries: Report[]): Report[] => {
  return entries.sort((a, b) => {
      const dateA = new Date(a.createdAt).getTime();
      const dateB = new Date(b.createdAt).getTime();
      return dateB - dateA; // Sort from newest to oldest
  });
};

export default function RemarquesSidebar() {
  const {
    state: { selectedQuestion, reports },
  } = useSidebar();

  return (
    <Stack spacing={6} align="stretch">
      <SidebarHeader
        title="Remarques"
        icon={<InfoCircleIcon color="#4B62F0" />}
      />

      {selectedQuestion ? (
        sortEntriesByCreatedAt(reports).map((report: Report) => (
          <ReportItem key={report._id} report={report} />
        ))
      ) : (
        <EmptyList />
      )}
    </Stack>
  );
}

const EmptyList = () => (
  <Box
    rounded={"xl"}
    border="2px solid"
    borderColor={"newLightGrey"}
    h="40"
    display={"flex"}
    alignItems="center"
    justifyContent={"center"}
  >
    <Text>Selectioner une question pour voir les remarques</Text>
  </Box>
);

const ReportItem = ({ report }: { report: Report }) => {
  return (
    <VStack
      rounded={"xl"}
      border="2px solid"
      borderColor={"newLightGrey"}
      p="4"
      alignItems={"flex-start"}
      spacing="0"
    >
      <Text color="blue-light" fontWeight={"medium"}>
        {report.user.fullname}
      </Text>
      <Text fontWeight={"semibold"}>Erreur: {report.message}</Text>
      <Text fontWeight={"semibold"}>
        {moment(report.createdAt).format("DD/MM/YYYY")}
      </Text>
      {report.comment && (
        <Text color={"#7f7f7f"} fontStyle="italic">
          {report.comment}
        </Text>
      )}
    </VStack>
  );
};
