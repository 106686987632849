import { ReactElement } from "react";
import { HStack, Box, Heading, Text } from "@chakra-ui/react";
import SidebarLayout from "./SidebarLayout";
import IconButtonWithTooltip from "../components/IconButtonWithTooltip";
import { Filter, useSidebar } from "../context/SidebarContext";

interface button {
  icon: ReactElement;
  onClick: () => void;
  title: string;
  value?: string;
  isDisabled?: boolean;
  isHidden?: boolean;
}
interface HeaderProps {
  breadcrumbs: string | any;
  basePage?: string;
  buttons?: button[];
}

const BreadcrumbComponent = ({
  subject,
  university,
  resolved,
  year,
  message,
}: Filter) => {
  const getResolvedLabel = () => {
    if (resolved === "true") {
      return "Traitée";
    } else if (resolved === "false") {
      return "Non traitée";
    }
    return null;
  };

  const renderBreadcrumbItem = (label: string, value: string | null | undefined) => {
    return value ? <span key={label}>{value}</span> : null;
  };

  const breadcrumbs = [
    renderBreadcrumbItem("resolved", getResolvedLabel()),
    renderBreadcrumbItem("subject", subject),
    renderBreadcrumbItem("year", year),
    renderBreadcrumbItem("university", university),
    renderBreadcrumbItem("message", message),
  ].filter(Boolean);

  return (
    <Text as="span" color="blue-light" fontSize={"lg"}>
      {breadcrumbs.length > 0
        ? breadcrumbs.reduce((prev, curr): any => [prev, " / ", curr])
        : null}
    </Text>
  );
};

const Header = ({ breadcrumbs, buttons, basePage }: HeaderProps) => {
  const {
    state: { sidebar },
  } = useSidebar();
  return (
    <HStack
      pos="sticky"
      top="0"
      py="4"
      px="8"
      borderBottom="2px solid"
      borderColor="newLightGrey"
      h="20"
      justifyContent={"space-between"}
      bg="white"
    >
      <HStack gap="0">
        <Heading fontSize={"2xl"} color="blue-light">
          {basePage}
        </Heading>
        {breadcrumbs && <BreadcrumbComponent {...breadcrumbs} />}
      </HStack>
      <HStack>
        {buttons?.map((button) => (
          !button.isHidden &&
          <IconButtonWithTooltip
            key={button.title}
            isActive={sidebar === button.value}
            {...button}
          />
        ))}
      </HStack>
    </HStack>
  );
};

export const LeftSide = ({ children }: { children: ReactElement|ReactElement[] }) => {
  return (
    <Box flex="2" p="2" pl="8" pr="6" overflow="auto">
      {children}
    </Box>
  );
};
export const RightPart = ({
  children,
  footerButtons,
}: {
  children: ReactElement;
  footerButtons: button[];
}) => {
  const {
    state: { sidebar, isSidebarOpen },
  } = useSidebar();
  return (
    <Box
      flex={isSidebarOpen ? "1": "0"}
      transition="all 0.3s ease-in-out"
      borderLeft="2px solid"
      borderColor="newLightGrey"
      display="flex"
      flexDirection={"column"}
      overflow="auto"
    >
      <Box flex="1" p="4">
        {children}
      </Box>
      <HStack
        position="sticky"
        bottom="0px"
        bg="white"
        p="4"
        borderTop="2px solid"
        borderColor="newLightGrey"
      >
        {footerButtons?.map((button) => (
          <IconButtonWithTooltip
            key={button.title}
            {...button}
            isActive={sidebar === button.value}
          />
        ))}
      </HStack>
    </Box>
  );
};
export default function FixedRightSidebar({
  children,
  breadcrumbs,
  buttons,
  basePage,
}: HeaderProps & {
  children: ReactElement|ReactElement[];
}) {
  return (
    <SidebarLayout withoutPadding>
      <Header basePage={basePage} breadcrumbs={breadcrumbs} buttons={buttons} />
      <HStack align="stretch" height="calc(100vh - 80px)">
        {children}
      </HStack>
    </SidebarLayout>
  );
}
