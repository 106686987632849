import { useCallback } from "react";
import { createReactEditorJS } from "react-editor-js";
// @ts-ignore
import Marker from "@editorjs/marker";
// @ts-ignore
import Underline from "@editorjs/underline";
// @ts-ignore
import Header from "@editorjs/header";
// @ts-ignore
import Table from "@editorjs/table";
// @ts-ignore
import List from "@editorjs/list";
// @ts-ignore
import Warning from "@editorjs/warning";
// @ts-ignore
import LinkTool from "@editorjs/link";
// @ts-ignore
import Image from "@editorjs/image";
// @ts-ignore
import Quote from "@editorjs/quote";
// @ts-ignore
import Delimiter from "@editorjs/delimiter";
// @ts-ignore
import SimpleImage from "@editorjs/simple-image";
// @ts-ignore
import ColorPlugin from "editorjs-text-color-plugin";

export default function Editor({ uploadImage, editorCore, placeholder, defaultValue }: any) {
  const ReactEditorJS = createReactEditorJS();
  const EDITOR_JS_TOOLS = {
    table: Table,
    marker: Marker,
    list: List,
    warning: Warning,
    linkTool: LinkTool,
    image: {
      class: Image,
      config: {
        endpoints: {
          byFile: process.env.REACT_APP_API_URL + "/api/images/question/upload",
        },
        uploader: {
          uploadByFile: uploadImage,
        },
      },
    },
    header: Header,
    quote: Quote,
    delimiter: Delimiter,
    simpleImage: SimpleImage,
    underline: Underline,
    color: ColorPlugin,
  };

  const handleInitialize = useCallback((instance: any) => {
    editorCore.current = instance;
  }, []);
  return (
    <ReactEditorJS
      tools={EDITOR_JS_TOOLS}
      placeholder={placeholder}
      onInitialize={handleInitialize}
      defaultValue={defaultValue}
    />
  );
}
