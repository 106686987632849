import { HamburgerIcon, EditIcon, AddIcon } from "@chakra-ui/icons";
import {
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
  useDisclosure,
} from "@chakra-ui/react";
import { BiArchiveIn } from "react-icons/bi";
import { IoTrashOutline } from "react-icons/io5";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { useHistory, useParams } from "react-router-dom";
import Dialog from "../Dialog/Dialog";

export default function SerieDropDownMenu({
  data,
  archiveOneSerie,
  archiveSerieLoading,
  deleteOneSerie,
  deleteSerieLoading,
  outsideSerie,
  count,
  isExam,
  onOpen,
}: any) {
  const history = useHistory();
  const { day } = useParams<{ day: string }>();

  return (
    <Menu placement="bottom-end">
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<HamburgerIcon />}
        // icon={count && outsideSerie ? <HamburgerIcon /> : <BiDotsVerticalRounded /> }
        variant={"outline"}
        onClick={(e: any) => e.stopPropagation()}
        _active={{ bg: "gray.50" }}
      />
      <MenuList zIndex={11}>
        <MenuItem icon={<EditIcon />} onClick={onOpen}>
          Modifier information
        </MenuItem>
        <MenuItem
          icon={<AddIcon />}
          onClick={(e: any) => {
            e.stopPropagation();
            history.push(
              `/questions/creer?${count ? "type=examen&" : ""}${
                day ? `jour=${day}&` : ""
              }q=${outsideSerie ? data?._id : data?.serie._id}&t=${
                count ? count : data?.questionsCount
              }`
              // history.push(isExam ? `/questions/creer?q=${outsideSerie ? data?._id : data?.serie._id}&t=${count}` : `/questions/creer?q=${outsideSerie ? data?._id : data?.serie._id}&t=${data?.questionsCount}`
            );
          }}
        >
          Créer question
        </MenuItem>
        <Dialog
          question="Confirmer votre action"
          description={`Êtes-vous sûr de vouloire ${
            outsideSerie
              ? data?.archived
                ? "publier"
                : "archiver"
              : data?.serie?.archived
              ? "publier"
              : "archiver"
          } ${isExam ? "cet examen" : "cette série"} ?`}
          onClick={() =>
            archiveOneSerie(outsideSerie ? data?._id : data?.serie._id)
          }
          isLoading={archiveSerieLoading}
          full
        >
          <MenuItem icon={<BiArchiveIn />} as="span">
            {outsideSerie
              ? data?.archived
                ? "Publier"
                : "Archiver"
              : data?.serie?.archived
              ? "Publier"
              : "Archiver"}
          </MenuItem>
        </Dialog>
        <Dialog
          question="Confirmer votre action"
          description={
            "Êtes-vous sûr de vouloire supprimer cette série ? Vous ne pouvez pas récupérer les données supprimés."
          }
          onClick={() =>
            deleteOneSerie(outsideSerie ? data?._id : data?.serie?._id)
          }
          isLoading={deleteSerieLoading}
          full
        >
          <MenuItem icon={<IoTrashOutline />} as="span">
            Supprimer
          </MenuItem>
        </Dialog>
      </MenuList>
    </Menu>
  );
}
