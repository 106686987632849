import { Route, Switch, useRouteMatch } from "react-router-dom";
import QuestionForm from "../../pages/Unauthorized/Questions/Question.form";
import Question2Form from "../../pages/Unauthorized/Questions/Question2.form";
import QuestionsTable from "../../pages/Unauthorized/Questions/Questions.table";

export default function QuestionsContainer() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      {/* <Route exact path={`${path}`}>
        <QuestionsTable path={path} />
      </Route> */}
      <Route exact path={`${path}/creer`}>
        <Question2Form path={path}/>
        {/* <QuestionForm path={path}/> */}
      </Route>
      <Route exact path={`${path}/modifier/:questionId`}>
        {/* <QuestionForm path={path}/> */}
        <Question2Form path={path}/>
      </Route>
      <Route>
        <h1>Page Not Found</h1>
      </Route>
    </Switch>
  );
}
