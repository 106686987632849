import {
  Stepper,
  useSteps,
  Step,
  StepSeparator,
  StepIndicator,
  StepStatus,
  Box,
  StepTitle,
  StepDescription,
} from "@chakra-ui/react";
import moment from "moment";

function OrderHistorySteps({ history = {} }: { history: any }) {
  const steps = Object.values(history).flat();
  const { activeStep } = useSteps({
    index: steps.length,
    count: steps?.length,
  });

  return (
    <Stepper
      index={activeStep}
      orientation="vertical"
      minHeight={"150px"}
      height="300px"
      gap="0"
    >
      {steps?.map((step: any, index: number) => (
        <Step key={index}>
          <StepIndicator>
            <StepStatus />
          </StepIndicator>

          <Box flexShrink="0">
            <StepTitle>{step.status}</StepTitle>
            <StepDescription>
              {moment(step.date).format("Do MMMM YYYY, HH:mm")}
            </StepDescription>
          </Box>

          <StepSeparator />
        </Step>
      ))}
    </Stepper>
  );
}

export default OrderHistorySteps;
