import { Route, Switch, useRouteMatch, Redirect } from "react-router-dom";
import Specialities from "../../pages/Unauthorized/Resources/Specialities/Specialities";
import Subjects from "../../pages/Unauthorized/Resources/Subjects/Subjects";
import Universtities from "../../pages/Unauthorized/Resources/Universtities/Universtities";

export default function ResourcesContainer() {
  const { path, url, isExact } = useRouteMatch();

  if (isExact) {
    return <Redirect to={`${url}/specialites`} />;
  }

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <h1>Resources</h1>
      </Route>
      <Route path={`${path}/specialites`}>
        <Specialities />
      </Route>
      <Route path={`${path}/facultes`}>
        <Universtities />
      </Route>
      <Route path={`${path}/sujets`}>
        <Subjects />
      </Route>
      <Route>
        <h1>Page Not Found</h1>
      </Route>
    </Switch>
  );
}
