import {
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
  IconButton,
  Stack,
  Spinner,
  Box,
  HStack,
  Center,
  RadioGroup,
  Radio,
  useToast,
} from "@chakra-ui/react";
import moment from "moment";
import { useCallback, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { BiPencil } from "react-icons/bi";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { saveQuestionToSerie } from "../../services/questions/services";
import { getQcmTypes, searchSerie } from "../../services/series/services";
import { getSpecialities } from "../../services/specialities/services";
import { getSubjects } from "../../services/subjects/services";
import { getUniversities } from "../../services/university/services";
import { IInputField } from "../../types";
import Field from "../Field/Field";
import MyToast from "../Toast/Toast";

export default function Popup({ question, refetch }: any) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    data: subjects,
    isLoading: subjectsLoading,
    isError: subjectError,
  } = useQuery("subjects", () => getSubjects(), {
    refetchOnWindowFocus: false,
    retry: false,
  });
  const {
    data: specialities,
    isLoading: specialitiesLoading,
    isError: specialitiesError,
  } = useQuery("specialities", () => getSpecialities(), {
    refetchOnWindowFocus: false,
    retry: false,
  });
  const {
    data: universities,
    isLoading: universitiesLoading,
    isError: universitiesError,
  } = useQuery("universities", () => getUniversities(), {
    refetchOnWindowFocus: false,
    retry: false,
  });

  const {
    data: qcmTypes,
    isLoading: qcmTypesLoading,
    isError: qcmTypesError,
  } = useQuery("qcm-types", () => getQcmTypes(), {
    refetchOnWindowFocus: false,
    retry: false,
  });

  if (subjectError || specialitiesError || universitiesError || qcmTypesError)
    return <p>Error!</p>;

  return (
    <>
      <IconButton
        aria-label="modifier information"
        icon={<BiPencil fontSize="1.6rem" />}
        rounded="full"
        variant={"ghost"}
        onClick={onOpen}
        _active={{}}
        _focus={{ bg: "gray.100" }}
      />

      <Modal
        blockScrollOnMount={false}
        isCentered
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior={"inside"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Information de base</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {subjectsLoading ||
            specialitiesLoading ||
            universitiesLoading ||
            qcmTypesLoading ? (
              <Box h="32" display={"grid"} placeItems="center">
                <Spinner />
              </Box>
            ) : (
              <ModalForm
                subjects={subjects}
                specialities={specialities}
                universities={universities}
                qcmTypes={qcmTypes}
                question={question && question?.qcm}
                refetch={refetch}
                onClose={onClose}
              />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

const ModalForm = ({
  specialities,
  subjects,
  universities,
  qcmTypes,
  question,
  refetch,
  onClose
}: any) => {
  const [serie, setSerie] = useState(null);
  const { questionId } = useParams<{ questionId: string }>();
  const methods = useForm({
    defaultValues: {
      type: question && question.type ? question.type : "",
      speciality: question && question.speciality ? question.speciality._id : "",
      subject: question && question.subject ? question.subject._id : "",
      university: question && question.university ? question.university._id : "",
      year: question && question.year ? question.year : "",
    },
  });
  
  const toast = useToast();
  const informationFields: IInputField[] = [
    {
      type: "select",
      name: "type",
      placeholder: "Série / Examen blanc",
      options: qcmTypes.map((el: any) => ({
        id: el._id,
        label: el.name,
        value: el._id,
      })),
    },
    {
      type: "select",
      name: "speciality",
      placeholder: "Spécialité",
      height: "15rem",
      options: specialities.map((el: any) => ({
        id: el._id,
        label: el.name,
        value: el._id,
      })),
    },
    {
      type: "select",
      name: "subject",
      placeholder: "Sujet",
      height: "15rem",
      options: subjects
        .filter((x: any) => x.speciality._id == methods.watch("speciality"))
        .map((el: any) => ({
          id: el._id,
          label: el.name,
          value: el._id,
        })),
    },
    {
      type: "select",
      name: "university",
      placeholder: "Faculté",
      options: universities.map((el: any) => ({
        id: el._id,
        label: el.name,
        value: el._id,
      })),
    },
    {
      type: "text",
      name: "year",
      placeholder: "Année",
    },
  ];

  const {
    mutateAsync: searchSeries,
    isLoading: loadingSeries,
    data: series,
  } = useMutation(searchSerie);

  const onsubmit = useCallback((data: any) => {
    let _data = Object.fromEntries(
      Object.entries(data).filter(([_, v]) => v != "")
    );
    searchSeries(_data);
    setSerie(null);
  }, []);

  const handleChange = (serieId: any) => {
    setSerie(serieId);
  };

  const { mutateAsync: saveQuestion, isLoading: loadingQuestion } = useMutation(
    saveQuestionToSerie,
    {
      onSuccess: () => {
        MyToast({ toast, status: "success", title: "Question modifié." });
        refetch();
        onClose();
      },
      onError: ({
        response: {
          data: { message },
        },
      }) => {
        toast({
          title: "Impossible de se connecter.",
          description: message,
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "bottom-right",
        });
      },
    }
  );

  const save = () => {
    saveQuestion({ id: questionId, serieId: serie });
  };

  const getSelectValue = (fieldName: string) => {
    switch (fieldName) {
      case "subject":
        return methods.getValues("subject");
      case "speciality":
        return methods.getValues("speciality");
      case "university":
        return methods.getValues("university");
      case "type":
        return methods.getValues("type");
      default:
        return "";
    }
  };
  return (
    <FormProvider {...methods}>
      <Stack spacing={3} as="form" onSubmit={methods.handleSubmit(onsubmit)}>
        {informationFields.map((field: any) => (
          <Field
            key={field.name}
            {...field}
            value={getSelectValue(field.name)}
            bg="darkGrey"
          />
        ))}
        {loadingSeries && (
          <Center>
            <Spinner />
          </Center>
        )}
        {series && (
          <Stack bg="gray.200" p="2" rounded={"lg"}>
            <Text fontWeight={700}>Les séries:</Text>
            <RadioGroup name="serie" onChange={handleChange}>
              <Stack spacing={3} className="series-radio">
                {series.map((serie: any) => (
                  <Radio value={serie._id} key={serie._id} colorScheme="green">
                    <HStack
                      justify={"space-between"}
                      bg="gray.50"
                      rounded={"lg"}
                      p="3"
                      align={"stretch"}
                    >
                      <Text>{serie.questionCount} questions</Text>
                      <Text fontSize={"sm"} color="gray.500">
                        {moment(serie.createdAt).fromNow()}
                      </Text>
                    </HStack>
                  </Radio>
                ))}
              </Stack>
            </RadioGroup>
          </Stack>
        )}
        <ModalFooter pr="0" mt="3">
          <Button mr={3} variant="ghost" disabled={loadingQuestion} onClick={onClose}>
            Annuler
          </Button>
          <Button
            mr={3}
            variant="secondary"
            type="submit"
            disabled={loadingQuestion}
          >
            Filtrer
          </Button>
          <Button
            variant="primary"
            disabled={!serie}
            onClick={save}
            isLoading={loadingQuestion}
            loadingText={"En cours"}
          >
            Sauvegarder
          </Button>
        </ModalFooter>
      </Stack>
    </FormProvider>
  );
};
