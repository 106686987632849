import { extendTheme } from '@chakra-ui/react'
import { button } from "./components/button";
import { styles } from "./styles";

const overrides = extendTheme({
    ...styles,
    components: {
      Button: {
        baseStyle: button.baseStyle,
        variants: button.variants,
      },
      Tooltip: {
        baseStyle: {
          borderRadius: "md"
        }
      }
      // Input: {
      //   baseStyle: {
      //     height: "9rem"
      //   },
      // }
    }
    // borders,
    // Other foundational style overrides go here
    // components: {
    //   Button,
    //   // Other components go here
    // },
  })
  
  export default overrides