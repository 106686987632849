import {
  Flex,
  Box,
  Stack,
  Button,
  useColorModeValue,
  Image,
  useToast,
} from "@chakra-ui/react";
import { Link, useHistory } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import EmailIcon from "../../../assets/icons/EmailIcon";
import KeyIcon from "../../../assets/icons/KeyIcon";
import logo from "../../../assets/img/ecnplus-logo.svg";
import Field from "../../../components/Field/Field";
import { IInputField } from "../../../types";
import AuthLayout from "../../../layouts/AuthLayout";
import { useMutation } from "react-query";
import { loginUser } from "../../../services/auth/services";
import { useEffect } from "react";

const loginFields: IInputField[] = [
  {
    type: "text",
    name: "email",
    placeholder: "Adresse email",
    icon: <EmailIcon />,
    rules: {
      required: "Veuillez saisir votre adresse email",
    },
  },
  {
    type: "password",
    name: "password",
    placeholder: "Mot de passe",
    icon: <KeyIcon />,
    rules: {
      required: "Veuillez saisir votre mot de passe",
    },
  },
];

export default function Login() {
  const methods = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });
  const history = useHistory();
  const toast = useToast();

  const onSubmit = (data: any) => {
    mutateAsync(data)
  };

  const { mutateAsync, isLoading } =
  useMutation(loginUser, {
    onError: ({response: {data: { message }}}) => {
          toast({
            title: 'Impossible de se connecter.',
            description: message,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: "bottom-right"
          })
    },
    onSuccess: (res) => localStorage.setItem("token", res.token)
  });

  useEffect(() => {
    if (localStorage.getItem("token")) {
      history.push("/tableau-de-bord");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("token")]);

  return (
    <AuthLayout>
          <FormProvider {...methods}>
            <Stack spacing={4} as="form" onSubmit={methods.handleSubmit(onSubmit)}>
              {loginFields.map((field: IInputField) => (
                <Field {...field} bg="light-blue-light" key={field.name}/>
              ))}
              <Stack spacing={4}>
                <Stack
                  direction={{ base: "column", sm: "row" }}
                  align={"start"}
                  justify={"flex-end"}
                >
                  <Link to="/mot-de-passe-oublie/email">Mot de passe oublié?</Link>
                </Stack>
                <Button type="submit" variant={"primary"} disabled={isLoading}>
                  {isLoading ? "Connexion en cours..." : "Se connecter"}
                </Button>
              </Stack>
            </Stack>
          </FormProvider>
    </AuthLayout>
  );
}
