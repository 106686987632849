
export default function QandRIcon({
  color = "#4B62F0",
}: {
  color?: string;
}) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 22.81C11.31 22.81 10.66 22.46 10.2 21.85L8.7 19.85C8.67 19.81 8.55 19.75 8.49 19.75H8C3.83 19.75 1.25 18.62 1.25 13V8C1.25 3.58 3.58 1.25 8 1.25H14C14.41 1.25 14.75 1.59 14.75 2C14.75 2.41 14.41 2.75 14 2.75H8C4.42 2.75 2.75 4.42 2.75 8V13C2.75 17.52 4.3 18.25 8 18.25H8.5C9.01 18.25 9.59 18.54 9.9 18.95L11.4 20.95C11.75 21.41 12.25 21.41 12.6 20.95L14.1 18.95C14.43 18.51 14.95 18.25 15.5 18.25H16C19.58 18.25 21.25 16.58 21.25 13V10C21.25 9.59 21.59 9.25 22 9.25C22.41 9.25 22.75 9.59 22.75 10V13C22.75 17.42 20.42 19.75 16 19.75H15.5C15.42 19.75 15.35 19.79 15.3 19.85L13.8 21.85C13.34 22.46 12.69 22.81 12 22.81Z"
        fill={color}
      />
      <path
        d="M12 12C11.44 12 11 11.55 11 11C11 10.45 11.45 10 12 10C12.55 10 13 10.45 13 11C13 11.55 12.56 12 12 12Z"
        fill={color}
      />
      <path
        d="M16 12C15.44 12 15 11.55 15 11C15 10.45 15.45 10 16 10C16.55 10 17 10.45 17 11C17 11.55 16.56 12 16 12Z"
        fill={color}
      />
      <path
        d="M8 12C7.44 12 7 11.55 7 11C7 10.45 7.45 10 8 10C8.55 10 9 10.45 9 11C9 11.55 8.56 12 8 12Z"
        fill={color}
      />
      <path
        d="M19.5 7.75C17.71 7.75 16.25 6.29 16.25 4.5C16.25 2.71 17.71 1.25 19.5 1.25C21.29 1.25 22.75 2.71 22.75 4.5C22.75 6.29 21.29 7.75 19.5 7.75ZM19.5 2.75C18.54 2.75 17.75 3.54 17.75 4.5C17.75 5.46 18.54 6.25 19.5 6.25C20.46 6.25 21.25 5.46 21.25 4.5C21.25 3.54 20.46 2.75 19.5 2.75Z"
        fill={color}
      />
    </svg>
  );
}
