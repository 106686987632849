import axiosInstance from "../../utils/axiosInstance";

export const createOptions = async (data: any) => {
  let token = localStorage.getItem("token");
  return await axiosInstance({
    method: "post",
    url: `/options/create`,
    data: data.options,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => {
    return {
      options: res.data,
      action: data.action,
      index: data.index
    };
  });
};

export const updateManyOptions = async (data: any) => {
  let token = localStorage.getItem("token");
  return await axiosInstance({
    method: "PUT",
    url: `/options/update-many-options`,
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data);
};
