import { useContext, useEffect, useState } from "react";
import { Grid, GridItem, Heading, Stack } from "@chakra-ui/react";
import SidebarLayout from "../../../layouts/SidebarLayout";
import { useQuery } from "react-query";
import {
  getMostActiveUsers,
  getMostDoneQcms,
  getNewJoiners,
} from "../../../services/users/services";
import PageHeading from "../../../components/PageHeading/PageHeading";
import ActifUsers from "../../../components/Dashboard/ActifUsers";
import MostDoneQcms from "../../../components/Dashboard/MostDoneQcms";
import RecentJoiners from "../../../components/Dashboard/RecentJoiners";
import TodayJoiners from "../../../components/Dashboard/TodayJoiners";
import { SocketContext } from "../../../context/socket";
import Forbidden from "../../../components/Forbidden/Forbidden";
import { useAuthProvider } from "../../../auth-provider/AuthProvider";

export default function Dashboard() {
  const { data: auth } = useAuthProvider();
  const [qcmView, setQcmView] = useState("Série");
  const [onlineUsers, setOnlineUsers] = useState(0);
  const socket = useContext(SocketContext);
  const showOnlineUsers =
    auth?.role === "admin" ||
    auth?.permissions.find((el: any) => el.name.includes("dashboard"));

  useEffect(() => {
    if (showOnlineUsers) {
      socket?.emit("patientJoin");

      socket?.on("patientList", (onlineClients: number) => {
        setOnlineUsers(onlineClients);
      });
    }
  }, [socket]);

  const {
    data: mostActive,
    isLoading: mostActiveLoading,
    isError: isMostActiveUsersError,
  } = useQuery("most-active-users", getMostActiveUsers, {
    refetchOnWindowFocus: false,
    staleTime: 60000 * 15,
    retry: false,
  });
  const {
    data: mostDoneQcms,
    isLoading: mostDoneQcmsLoading,
    isError: isMostDoneQcmsError,
  } = useQuery(["most-done-qcms", qcmView], () => getMostDoneQcms(qcmView), {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    retry: false,
  });
  const {
    data: newJoiners,
    isLoading: newJoinersLoading,
    isError: isNewJoinersError,
  } = useQuery(["new-joiners"], getNewJoiners, {
    refetchOnWindowFocus: false,
    retry: false,
  });

  return (
    <SidebarLayout>
      <PageHeading title={"Dashboard"} />
      <Stack spacing={6}>
        <Grid
          gridTemplateColumns={{ base: "1fr", lg: "repeat(3, 1fr)" }}
          gap={6}
          mt="4"
        >
          <GridItem
            rounded="1rem"
            bg="lightGrey"
            overflow="hidden"
            display={"flex"}
            h="full"
            p="4"
          >
            {showOnlineUsers ? (
              <Stack align={"center"} flex={1} justify="center">
                <Heading
                  as="h2"
                  color={"blue-light"}
                  fontSize="2xl"
                  bg="lightGrey"
                  zIndex={1}
                >
                  Utilisateurs connectés
                </Heading>
                <Heading>{onlineUsers}</Heading>
              </Stack>
            ) : (
              <Forbidden
                msg={"Vous n'avez pas l'autorisation de voire ce contenu"}
              />
            )}
          </GridItem>
          <GridItem rounded="1rem" bg="lightGrey" overflow="hidden">
            {isNewJoinersError ? (
              <Forbidden
                msg={"Vous n'avez pas l'autorisation de voire ce contenu"}
              />
            ) : (
              <RecentJoiners
                newJoinersLoading={newJoinersLoading}
                recentJoiners={newJoiners?.recentJoiners}
              />
            )}
          </GridItem>
          <GridItem rounded="1rem" bg="lightGrey" overflow="hidden">
            {isNewJoinersError ? (
              <Forbidden
                msg={"Vous n'avez pas l'autorisation de voire ce contenu"}
              />
            ) : (
              <TodayJoiners
                newJoinersLoading={newJoinersLoading}
                todayJoiners={newJoiners?.todayJoiners}
              />
            )}
          </GridItem>
        </Grid>
        <Grid
          gridTemplateColumns={{ base: "1fr", lg: "repeat(2, 1fr)" }}
          gap={6}
          h={{ base: "900px", lg: "563px" }}
          alignItems={"stretch"}
        >
          <GridItem bg="lightGrey" rounded={"1rem"} overflowY="auto">
            {isMostActiveUsersError ? (
              <Forbidden
                msg={"Vous n'avez pas l'autorisation de voire ce contenu"}
              />
            ) : (
              <ActifUsers
                mostActiveLoading={mostActiveLoading}
                mostActive={mostActive}
              />
            )}
          </GridItem>
          <GridItem bg="lightGrey" rounded={"1rem"} overflowY="auto">
            {isMostDoneQcmsError ? (
              <Forbidden
                msg={"Vous n'avez pas l'autorisation de voire ce contenu"}
              />
            ) : (
              <MostDoneQcms
                setQcmView={setQcmView}
                qcmView={qcmView}
                mostDoneQcmsLoading={mostDoneQcmsLoading}
                mostDoneQcms={mostDoneQcms}
              />
            )}
          </GridItem>
        </Grid>
      </Stack>
    </SidebarLayout>
  );
}
