import { Grid, GridItem, Heading, Stack, Text } from "@chakra-ui/react";
import moment from "moment";
import PageHeading from "../PageHeading/PageHeading";

export default function UserDetailsHeader({
  uniqueSeries,
  uniqueExams,
  seriesAttempts,
  examsAttempts,
  seriesTotalAnsweredQuestions,
  examsTotalAnsweredQuestions,
  lastLogin,
}: {
  uniqueSeries: number;
  uniqueExams: number;
  seriesAttempts: number;
  examsAttempts: number;
  seriesTotalAnsweredQuestions: number;
  examsTotalAnsweredQuestions: number;
  lastLogin: any;
}) {
  return (
    <Stack spacing={4}>
      <Grid
        gridTemplateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }}
        gap={6}
      >
        <GridItem>
          <Stack
            flexWrap={"wrap"}
            bg="lightGrey"
            rounded={"1rem"}
            p="4"
            h="full"
            justify={{ base: "space-between", md: "center" }}
            align={{ base: "flex-start", md: "center" }}
            direction={{ base: "row", md: "column" }}
          >
            <Heading fontSize={"16px"} color="blue-light" fontWeight={500}>
              Dernière connexion
            </Heading>
            <Text>
              {moment(lastLogin ?? new Date()).format("DD/MM/YYYY HH:mm")}
            </Text>
          </Stack>
        </GridItem>
        <GridItem>
          <Stack
            flexWrap={"wrap"}
            bg="lightGrey"
            rounded={"1rem"}
            p="4"
            h="full"
            justify={{ base: "space-between", lg: "center" }}
            align={{ base: "flex-start", lg: "center" }}
            direction={{ base: "row", lg: "column" }}
          >
            <Grid gridTemplateColumns={{base: "1fr", lg: "1fr 1fr"}} gap={{base: 4, lg: 0}}>
                <GridItem>
                    <Stack align={{base: "flex-start", lg: "center"}} spacing={{base: 0, lg: 2}}>
                        <Heading fontSize={"16px"} color="blue-light" fontWeight={500}>
                            Séries
                        </Heading>
                        <Text>
                            {uniqueSeries} séries <Text display={"inline"} as="sup">({seriesAttempts} tentatives)</Text>
                        </Text>
                    </Stack>
                </GridItem>
                <GridItem borderLeft={{base: "none", lg: "1px solid"}} borderColor={{base: "none", lg: "blackAlpha.300"}} pl={{base: 0, lg: "4"}}>
                    <Stack align={{base: "flex-start", lg: "center"}} spacing={{base: 0, lg: 2}}>
                        <Heading fontSize={"16px"} color="blue-light" fontWeight={500}>
                            Examens
                        </Heading>  
                            <Text>
                                {uniqueExams} examens <Text display={"inline"} as="sup">({examsAttempts} tentatives)</Text>
                            </Text>
                    </Stack>
                </GridItem>
            </Grid>
      
          </Stack>
        </GridItem>
        <GridItem>
          <Stack
               flexWrap={"wrap"}
               bg="lightGrey"
               rounded={"1rem"}
               p="4"
               h="full"
               justify={{ base: "space-between", lg: "center" }}
               align={{ base: "flex-start", lg: "center" }}
               direction={{ base: "row", lg: "column" }}
          >
            <Grid gridTemplateColumns={{base: "1fr", lg: "1fr 1fr"}} gap={{base: 4, lg: 0}}>
                <GridItem pr={{base: 0, lg: "4"}}>
                    <Stack align={{base: "flex-start", lg: "center"}} spacing={{base: 0, lg: 2}}>
                        <Heading fontSize={"16px"} color="blue-light" fontWeight={500}>
                            Séries
                        </Heading>
                        <Text>
                            {seriesTotalAnsweredQuestions} questions
                        </Text>
                    </Stack>
                </GridItem>
                <GridItem borderLeft={{base: "none", lg: "1px solid"}} borderColor={{base: "none", lg: "blackAlpha.300"}} pl={{base: 0, lg: "4"}}>
                    <Stack align={{base: "flex-start", lg: "center"}} spacing={{base: 0, lg: 2}}>
                        <Heading fontSize={"16px"} color="blue-light" fontWeight={500}>
                            Examens
                        </Heading>  
                            <Text>
                                {examsTotalAnsweredQuestions} questions
                            </Text>
                    </Stack>
                </GridItem>
            </Grid>
            {/* <Stack spacing={0}>
              <Heading
                as="h3"
                fontSize={"16px"}
                color="blue-light"
                fontWeight={500}
              >
                Questions répondu Série / Examen
              </Heading>
            </Stack>
            <Text>
              {seriesTotalAnsweredQuestions} / {examsTotalAnsweredQuestions}
            </Text> */}
          </Stack>
        </GridItem>
      </Grid>
    </Stack>
  );
}
