export default function SidebarOpenIcon({
  color = "#4B62F0",
}: {
  color?: string;
}) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.9707 22.75H8.9707C3.5407 22.75 1.2207 20.43 1.2207 15V9C1.2207 3.57 3.5407 1.25 8.9707 1.25H14.9707C20.4007 1.25 22.7207 3.57 22.7207 9V15C22.7207 20.43 20.4107 22.75 14.9707 22.75ZM8.9707 2.75C4.3607 2.75 2.7207 4.39 2.7207 9V15C2.7207 19.61 4.3607 21.25 8.9707 21.25H14.9707C19.5807 21.25 21.2207 19.61 21.2207 15V9C21.2207 4.39 19.5807 2.75 14.9707 2.75H8.9707Z"
        fill={color}
      />
      <path
        d="M7.9707 22.75C7.5607 22.75 7.2207 22.41 7.2207 22V2C7.2207 1.59 7.5607 1.25 7.9707 1.25C8.3807 1.25 8.7207 1.59 8.7207 2V22C8.7207 22.41 8.3907 22.75 7.9707 22.75Z"
        fill={color}
      />
      <path
        d="M14.9696 15.3099C14.7796 15.3099 14.5896 15.2399 14.4396 15.0899L11.8796 12.5299C11.5896 12.2399 11.5896 11.7599 11.8796 11.4699L14.4396 8.90988C14.7296 8.61988 15.2096 8.61988 15.4996 8.90988C15.7896 9.19988 15.7896 9.67988 15.4996 9.96988L13.4796 11.9999L15.5096 14.0299C15.7996 14.3199 15.7996 14.7999 15.5096 15.0899C15.3596 15.2399 15.1696 15.3099 14.9696 15.3099Z"
        fill={color}
      />
    </svg>
  );
}
