import { IMyToast } from "../../types";

const MyToast = ({toast, title, description, status, duration = 3000}: IMyToast) => {
  return toast({
    title,
    description,
    status,
    duration,
    isClosable: true,
    position: "bottom-right",
  })
}

export default MyToast;
