import axiosInstance from "../../utils/axiosInstance";

export const getUniversities = async () => {
    let token = localStorage.getItem("token");
    return await axiosInstance({
      method: "get",
      url: `/university/with-archived`,
      headers:{
        Authorization: `Bearer ${token}`
      }
    }).then((res: any) => res.data);
};

export const getOneUniversity = async (name: string) => {
  let token = localStorage.getItem("token");
  return await axiosInstance({
    method: "get",
    url: `/university/${name}`,
    headers:{
      Authorization: `Bearer ${token}`
    }
  }).then((res: any) => res.data);
};

export const createUniversity = async (data: {name: string}) => {
  let token = localStorage.getItem("token");
  return await axiosInstance({
    method: "post",
    url: `/university`,
    data,
    headers:{
      Authorization: `Bearer ${token}`
    }
  }).then((res: any) => res.data);
};

export const updateUniversity = async (data: {id: string, name: string}) => {
  let token = localStorage.getItem("token");
  return await axiosInstance({
    method: "put",
    url: `/university/${data.id}`,
    data: {name: data.name},
    headers:{
      Authorization: `Bearer ${token}`
    }
  }).then((res: any) => res.data);
};

export const deleteUniversity = async (id: string) => {
  let token = localStorage.getItem("token");
  return await axiosInstance({
    method: "delete",
    url: `/university/${id}`,
    headers:{
      Authorization: `Bearer ${token}`
    }
  }).then((res: any) => res.data);
};

export const archiveUniversity = async (id: string) => {
  let token = localStorage.getItem("token");
  return await axiosInstance({
    method: "put",
    url: `/university/archive/${id}`,
    headers:{
      Authorization: `Bearer ${token}`
    }
  }).then((res: any) => res.data);
};