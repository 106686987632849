import { Button, HStack, Stack } from "@chakra-ui/react";
import { FormProvider, useForm } from "react-hook-form";
import FilterSquareIcon from "../../../../assets/icons/FilterSquareIcon";
import Field from "../../../../components/Field/Field";
import { useSidebar } from "../../../../context/SidebarContext";
import { IInputField } from "../../../../types";
import SidebarHeader from "../../Remarques/components/SidebarHeader";

export default function SidebarFilterOrders() {
  const {
    handleChangeOrdersFilter,
    handleResetFilter,
    state: { filter },
  } = useSidebar();
  const methods = useForm({
    defaultValues: {
      voucherStatus: filter.voucherStatus,
      status: filter.status,
      reference: filter.reference,
      orderType: filter.orderType,
      pickUpStore: filter.pickUpStore,
    },
  });

  const getSelectValue = (fieldName: string) => {
    switch (fieldName) {
      case "voucherStatus":
        return methods.getValues("voucherStatus");
      case "status":
        return methods.getValues("status");
      case "pickUpStore":
        return methods.getValues("pickUpStore");
      case "reference":
        return methods.getValues("reference");
        case "orderType":
          return methods.getValues("orderType");
      default:
        return "";
    }
  };

  const filterFields: IInputField[] = [
    {
      type: "text",
      name: "reference",
      placeholder: "Référence",
      isUpperCase: true,
      borderColor: "newLightGrey",
      borderWidth: "2px",
      borderRadius: "xl",
      marginBottom: "8px",
    },
    {
      type: "select",
      name: "status",
      placeholder: "Etat de la commande",
      isUpperCase: true,
      options: [
        {
          id: 6,
          label: "Toutes",
          value: "",
        },
        {
          id: 0,
          label: "Nouvelle",
          value: "new",
        },
        {
          id: 1,
          label: "Confirmée",
          value: "confirmed",
        },
        {
          id: 7,
          label: "Code coupon activé",
          value: "activated_voucher",
        },
        {
          id: 5,
          label: "Terminée",
          value: "completed",
        },
      ],
    },
    {
      type: "select",
      name: "voucherStatus",
      placeholder: "Etat de la coupon",
      isUpperCase: true,
      options: [
        {
          id: 5,
          label: "Toutes",
          value: "",
        },
        {
          id: 1,
          label: "Non activé et commande confirmée",
          value: "pending",
        },
        {
          id: 1,
          label: "Non activé et nouvelle commande",
          value: "idle",
        },
        {
          id: 2,
          label: "Utilisé",
          value: "used",
        },
        {
          id: 3,
          label: "Activé",
          value: "activated",
        },
      ],
    },
    {
      type: "select",
      name: "pickUpStore",
      placeholder: "Boutique",
      isUpperCase: true,
      options: [
        {
          id: 5,
          label: "Toutes",
          value: "",
        },
        {
          id: 1,
          label: "Tunis",
          value: "Tunis",
        },
        {
          id: 1,
          label: "Sousse",
          value: "Sousse",
        },
      ],
    },
  ];

  const onSubmit = (data: any) => {
    handleChangeOrdersFilter(data);
  };

  const handleReset = () => {
    methods.setValue("voucherStatus", "");
    methods.setValue("status", "");
    methods.setValue("reference", "");
    methods.setValue("orderType", "");
    methods.setValue("pickUpStore", "");
    handleResetFilter();
  };
  return (
    <Stack align="stretch" spacing={6}>
      <SidebarHeader title="Filter" icon={<FilterSquareIcon />} />
      <FormProvider {...methods}>
        <Stack as="form" onSubmit={methods.handleSubmit(onSubmit)}>
          {filterFields.map((field: any) => (
            <Field
              key={field.name}
              {...field}
              value={getSelectValue(field.name)}
              bg="white"
              borderColor="newLightGrey"
              borderWidth="2px"
              borderRadius="xl"
            />
          ))}
          <HStack justify={"flex-end"} align="center">
            <Button
              variant={"outline"}
              flexGrow="1"
              rounded={"xl"}
              borderColor="newLightGrey"
              borderWidth="2px"
              fontWeight="medium"
              py="6"
              _hover={{ bg: "newLightGrey" }}
              onClick={handleReset}
            >
              Réinitialiser
            </Button>
            <Button
              variant={"outline"}
              type="submit"
              flexGrow="1"
              rounded={"xl"}
              borderColor="newLightGrey"
              borderWidth="2px"
              fontWeight="medium"
              py="6"
              _hover={{ bg: "newLightGrey" }}
            >
              Filtrer
            </Button>
          </HStack>
        </Stack>
      </FormProvider>
    </Stack>
  );
}
