import { Divider, Heading } from "@chakra-ui/react";

export default function PageHeading({ title }: { title: string }) {
  return (
    <div>
      <Heading
        lineHeight={"1.5"}
        fontSize={{ base: "lg", xl: "xl", "2xl": "2xl" }}
      >
        {title}
      </Heading>
      <Divider
        borderColor="gold-yellow"
        w={"2.5rem"}
        background={"gold-yellow"}
        borderRadius={"full"}
        borderWidth={"0.15rem"}
      />
    </div>
  );
}
