import {
  Menu,
  MenuButton,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
  IconButton,
  useColorModeValue,
  MenuDivider,
} from "@chakra-ui/react";
import { BiFilterAlt } from "react-icons/bi";
import { useHistory } from "react-router-dom";

export default function FilterSpecialities({
  path,
  setFilterByStatus,
  filterByStatus,
  filterByJour,
  setFilterByJour,
  setValues,
}: any) {
  const history = useHistory();

  const handleChangeStatus = (status: string | string[]) => {
    history.push(`${path}?status=${status}&jour=${filterByJour}`);
    // setValues({ maxValue: 10, minValue: 0, page: 1 });
    setFilterByStatus(status);
  };

  const handleChangeJour = (jour: string | string[]) => {
    history.push(`${path}?status=${filterByStatus}&jour=${jour}`);
    // setValues({ maxValue: 10, minValue: 0, page: 1 });
    setFilterByJour(jour);
  };

  return (
    <Menu closeOnSelect={true} placement="bottom-end">
      <MenuButton
        height={{ base: "2.5rem", "2xl": "3rem" }}
        width={"12"}
        as={IconButton}
        aria-label="filter specialities"
        icon={<BiFilterAlt />}
        variant="outline"
      />
      <MenuList minWidth="240px" bg={useColorModeValue("white", "blue-dark")}>
        <MenuOptionGroup
          value={filterByStatus}
          title="Filtrer par"
          type="radio"
          onChange={handleChangeStatus}
        >
          <MenuItemOption value="Archivés">Archivés</MenuItemOption>
          <MenuItemOption value="Non archivés">Non archivés</MenuItemOption>
          <MenuItemOption value="Tous">Tous</MenuItemOption>
          <MenuDivider />
        </MenuOptionGroup>
        <MenuOptionGroup
          value={filterByJour}
          type="radio"
          onChange={handleChangeJour}
        >
          <MenuItemOption value="Jour 1">Jour 1</MenuItemOption>
          <MenuItemOption value="Jour 2">Jour 2</MenuItemOption>
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
}
