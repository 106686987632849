import { useColorModeValue } from "@chakra-ui/react";

export default function AvatarIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g id="user" opacity="0.5">
        <rect
          id="Rectangle_17"
          data-name="Rectangle 17"
          width="24"
          height="24"
          rx="5"
          fill="#ededed"
          opacity="0"
        />
        <g id="Group_19" data-name="Group 19" transform="translate(1.2 1.2)">
          <path
            id="Path_24"
            data-name="Path 24"
            d="M10.8,11.475A5.175,5.175,0,1,1,15.975,6.3,5.182,5.182,0,0,1,10.8,11.475Zm0-9A3.825,3.825,0,1,0,14.625,6.3,3.834,3.834,0,0,0,10.8,2.475Z"
            fill={useColorModeValue("#292d32","white")}
          />
          <path
            id="Path_25"
            data-name="Path 25"
            d="M18.531,20.475a.68.68,0,0,1-.675-.675c0-3.1-3.168-5.625-7.056-5.625S3.744,16.695,3.744,19.8a.675.675,0,1,1-1.35,0c0-3.843,3.771-6.975,8.406-6.975s8.406,3.132,8.406,6.975A.68.68,0,0,1,18.531,20.475Z"
            fill={useColorModeValue("#292d32","white")}
          />
        </g>
      </g>
    </svg>
  );
}
