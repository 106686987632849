import { useRef } from "react";
import {
    Button,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton
} from "@chakra-ui/react";
import AnnouncesForm from "./AnnouncesForm"

function AnnouncesModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = useRef<any>();
  const finalRef = useRef<any>();

  return (
    <>
      <Button onClick={onOpen}>Nouvelle annonce</Button>
      <Modal
        blockScrollOnMount={false}
        isOpen={isOpen}
        onClose={onClose}
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isCentered
        size={"lg"}
        autoFocus
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Nouvelle annonce</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <AnnouncesForm onClose={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default AnnouncesModal;
