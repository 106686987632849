import axiosInstance from "../../utils/axiosInstance";

export const getQcmTypes = async () => {
  let token = localStorage.getItem("token");
  return await axiosInstance({
    method: "get",
    url: `/qcm-types`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data);
};

export const getSeriesBySubject = async (subjectId: string, query: any) => {
  let token = localStorage.getItem("token");
  return await axiosInstance({
    method: "get",
    url: `/qcm/by-university/${subjectId}?archived=true${
      query.toString() ? "&" + query.toString() : ""
    }`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data);
};

export const createSerie = async (data: any) => {
  let token = localStorage.getItem("token");
  return await axiosInstance({
    method: "post",
    url: `/qcm/create`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data);
};

export const getSerieByIdWithQuestions = async (id: string) => {
  let token = localStorage.getItem("token");
  return await axiosInstance({
    method: "get",
    url: `/qcm/with-questions/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data);
};

export const deleteSerie = async (id: string) => {
  let token = localStorage.getItem("token");
  return await axiosInstance({
    method: "delete",
    url: `/qcm/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data);
};

export const archiveSerie = async (id: string) => {
  let token = localStorage.getItem("token");
  return await axiosInstance({
    method: "put",
    url: `/qcm/archive/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data);
};

export const searchSerie = async (data: any) => {
  let token = localStorage.getItem("token");
  return await axiosInstance({
    method: "post",
    url: `/qcm/search`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data);
};

export const updateSeriesInfo = async ({id, data}: any) => {
  let token = localStorage.getItem("token");
  return await axiosInstance({
    method: "put",
    url: `/qcm/update/${id}`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data);
};