import React, { ReactNode, useContext, useEffect, useState } from "react";
import {
  IconButton,
  Box,
  CloseButton,
  Flex,
  Icon,
  useColorModeValue,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  BoxProps,
  FlexProps,
  Button,
  Fade,
  HStack,
  Image,
  Badge,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tooltip,
} from "@chakra-ui/react";
import { ReactText } from "react";
import HomeIcon from "../assets/icons/HomeIcon";
import SeriesIcon from "../assets/icons/SeriesIcon";
import ExamsIcon from "../assets/icons/ExamsIcon";
import logo_white from "../assets/img/logo_white.svg";
import UserIcon from "../assets/icons/UserIcon";
import { useAuthProvider } from "../auth-provider/AuthProvider";
import BellIcon from "../assets/icons/BellIcon";
import { useHistory, Link as RouterLink } from "react-router-dom";
import AccordionMenu from "../components/AccordionMenu/AccordionMenu";
import QuestionIcon from "../assets/icons/QuestionIcon";
import { SocketContext } from "../context/socket";
import ReportIcon from "../assets/icons/ReportIcon";
import { getNotSeenNotificationCount } from "../services/Notifications/services";
import { logout } from "../services/auth/services";
import { useQuery } from "react-query";
import { ChevronDownIcon, HamburgerIcon } from "@chakra-ui/icons";
import { IoLogOutOutline } from "react-icons/io5";
import { FiUsers } from "react-icons/fi";
import AnnonceIcon from "../assets/icons/AnnonceIcon";
import HierarchyIcon from "../assets/icons/HierarchyIcon";
import HierarchyIcon2 from "../assets/icons/HierarchyIcon2";
import InfoCircleIcon from "../assets/icons/InfoCircleIcon";
import UniversityIcon2 from "../assets/icons/UniversityIcon2";
import { TfiAnnouncement } from "react-icons/tfi";

interface LinkItemProps {
  name: string;
  icon?: any;
  href: string;
  permission?: string;
}
const LinkItems: Array<LinkItemProps> = [
  {
    name: "Accueil",
    icon: HomeIcon,
    href: "/tableau-de-bord",
    permission: "dashboard",
  },
  { name: "Série", icon: SeriesIcon, href: "/series", permission: "series" },
  {
    name: "Examen blanc",
    icon: ExamsIcon,
    href: "/examen-blanc",
    permission: "exams",
  },
  // { name: "Questions", icon: QuestionIcon, href: "/questions" },
  {
    name: "Utilisateurs",
    icon: FiUsers,
    href: "/utilisateurs",
    permission: "users",
  },
  {
    name: "Remarques",
    icon: InfoCircleIcon,
    href: "/remarques",
    permission: "remarques",
  },
  {
    name: "Annonces",
    icon: AnnonceIcon,
    href: "/annonces",
    permission: "annonces",
  },
  {
    name: "Commandes",
    icon: TfiAnnouncement,
    href: "/commandes",
    permission: "pack-orders",
  },
];
const SubLinkItems: Array<LinkItemProps> = [
  {
    name: "Facultés",
    icon: UniversityIcon2,
    href: "/resources/facultes",
    permission: "resources",
  },
  {
    name: "Spécialités",
    icon: HierarchyIcon,
    href: "/resources/specialites",
    permission: "resources",
  },
  {
    name: "Sujets",
    icon: HierarchyIcon2,
    href: "/resources/sujets",
    permission: "resources",
  },
  // { name: "Statut", href: "/resources/statut" },
];

export default function SidebarLayout({
  children,
  withoutPadding = false,
}: {
  children: ReactNode;
  withoutPadding?: boolean;
}) {
  const [newNotification, setNewNotification] = useState(false);
  const socket = useContext(SocketContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { location } = useHistory();

  useEffect(() => {
    socket?.on("adminMsg", (x: any) => {
      setNewNotification(true);
    });

    return () => {
      socket?.off("adminMsg");
    };
  }, [socket]);

  const { data: notificationCount } = useQuery(
    [`notifications`, newNotification],
    getNotSeenNotificationCount,
    {
      // enabled: Boolean(newNotification)
      refetchOnWindowFocus: true,
    }
  );

  return (
    <Box minH="100vh" bg={useColorModeValue("blue-light", "blue-dark")}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "flex" }}
        newNotification={newNotification}
        notificationCount={notificationCount}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav
        display={{ base: "flex", md: "none" }}
        onOpen={onOpen}
        newNotification={newNotification}
        notificationCount={notificationCount}
      />
      <Box
        ml="20"
        p={withoutPadding ? 0 : { base: "4", "2xl": "8" }}
        // borderTopLeftRadius={{ base: 0, md: "1.5rem" }}
        // borderBottomLeftRadius={{ base: 0, md: "1.5rem" }}
        bg={useColorModeValue("white", "dark-blue-dark")}
        h={"100vh"}
        overflowY={"auto"}
        position={"absolute"}
        width="calc(100% - 80px)"
        // width={{
        //   base: "100%",
        //   md: "calc(100% - 240px)",
        //   "2xl": "calc(100% - 20rem)",
        // }}
        // boxShadow={useColorModeValue(
        //   "-20px 1px 0px 3px rgb(69,90,222)",
        //   "none"
        // )}
      >
        <Fade in key={location.pathname}>
          {children}
        </Fade>
      </Box>
    </Box>
  );
}

interface SidebarProps extends BoxProps {
  onClose: () => void;
  newNotification?: boolean;
  notificationCount?: number;
}

const SidebarContent = ({
  onClose,
  newNotification,
  notificationCount,
  ...rest
}: SidebarProps) => {
  const { data: auth } = useAuthProvider();
  const { location, push } = useHistory();
  const userPermissions = auth?.permissions?.map((el: any) => el.name);

  return (
    <Flex
      direction={"column"}
      bg={useColorModeValue("primary", "blue-dark")}
      // w={{ base: "full", md: 60, "2xl": 80 }}
      w="20"
      pos="fixed"
      minH="100vh"
      zIndex={2}
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="2" justifyContent="space-between">
        <img src={logo_white} alt="ECNPlus" />
        <CloseButton
          display={{ base: "flex", md: "none" }}
          onClick={onClose}
          color="white"
        />
      </Flex>
      <Box
        // flexGrow={1}
        h="calc(100vh - 12rem)"
        overflowY={"auto"}
        overflowX={"hidden"}
        className="sidebar-menu-list"
      >
        {LinkItems.map((link) => {
          const show = userPermissions?.find((el) =>
            el.includes(link.permission)
          );
          if (show || !link.permission || auth?.role === "admin") {
            return (
              <NavItem key={link.name} icon={link.icon} href={link.href}>
                {link.name}
              </NavItem>
            );
          }
        })}
        {/* <Box> */}
        <AccordionMenu>
          {SubLinkItems.map((link) => {
            const show = userPermissions?.find((el) =>
              el.includes(link.permission)
            );
            if (show || !link.permission || auth?.role === "admin") {
              return (
                <RouterLink to={link.href} key={link.name}>
                  <Tooltip
                    label={link.name || ""}
                    hasArrow
                    placement="right"
                    offset={[-5, 0]}
                  >
                    <Box
                      style={{ textDecoration: "none" }}
                      _focus={{ boxShadow: "none" }}
                      color={"white"}
                      fontWeight={"light"}
                      position={"relative"}
                      className={
                        location.pathname.includes(link.href)
                          ? "link-item"
                          : undefined
                      }
                    >
                      <Flex
                        mt="2"
                        // mx="4"
                        align="center"
                        borderRadius="lg"
                        role="group"
                        cursor="pointer"
                        justifyContent={"center"}
                        padding="0px"
                        p="2"
                        mx="1"
                        _hover={{
                          bg: location.pathname.includes(link.href)
                            ? "white"
                            : "muted-blue",
                          color: location.pathname.includes(link.href)
                            ? "blue-light"
                            : "white",
                        }}
                        bg={
                          location.pathname.includes(link.href)
                            ? "blue-light"
                            : "transparent"
                        }
                        color={
                          location.pathname.includes(link.href)
                            ? "blue-light"
                            : "white"
                        }
                        h="12"
                        {...rest}
                      >
                        {link.icon && (
                          // @ts-ignore
                          <Icon color="red" as={link?.icon ?? <UserIcon />} />
                        )}
                      </Flex>
                    </Box>
                  </Tooltip>
                </RouterLink>
                // <NavItem key={link.name} icon={link.icon} href={link.href}>
                //   {link.name}
                // </NavItem>
              );
            }
          })}
        </AccordionMenu>
        {/* </Box> */}
      </Box>
      {/* <HStack
        spacing={{ base: 1, "2xl": 4 }}
        px="4"
        // mb="30px"
        h="20"
        justify={"space-between"}
        display={{ base: "none", md: "flex" }}
      > */}
      {/* <RouterLink to="/profil" style={{ flexGrow: 1 }}>
          <Button
            leftIcon={<UserIcon />}
            variant={"ghost"}
            _hover={{
              bg: useColorModeValue(
                location.pathname.includes("/profil") ? "white" : "muted-blue",
                location.pathname.includes("/profil") ? "white" : "#1F2C42"
              ),
            }}
            color={
              location.pathname.includes("/profil") ? "blue-light" : "white"
            }
            bg={location.pathname.includes("/profil") ? "white" : "transparent"}
            outline={"none"}
            px="3"
            w="full"
            justifyContent={"flex-start"}
            h={{ base: "1.8rem", "2xl": "2.5rem" }}
          >
            <Text
              isTruncated
              maxW={{
                base: "calc(240px - 130px)",
                "2xl": "calc(20rem - 10rem)",
              }}
              fontSize={{ base: "xs", "2xl": "sm" }}
            >
              {auth?.fullname || "Nom Prénom"}
            </Text>
          </Button>
        </RouterLink> */}
      <Button
        variant={"ghost"}
        p="0"
        mx="4"
        _active={{ bg: "muted-blue" }}
        onClick={() => push("/notifications")}
        _hover={{ bg: useColorModeValue("muted-blue", "#1F2C42") }}
        color={
          location.pathname.includes("/notifications") ? "blue-light" : "white"
        }
        bg={
          location.pathname.includes("/notifications") ? "white" : "transparent"
        }
      >
        <BellIcon
          color={
            location.pathname.includes("/notifications") ? "#4B62F0" : "#fff"
          }
        />
        {(newNotification || notificationCount !== 0) && (
          <Badge
            bg="red"
            rounded={"full"}
            position={"absolute"}
            top="1"
            right={"1"}
            color="white"
            fontSize={"xs"}
          >
            {notificationCount}
          </Badge>
        )}
      </Button>
      <ProfileDropDown maxW={"200px"} />
      {/* </HStack> */}
    </Flex>
  );
};

interface NavItemProps extends FlexProps {
  icon: any;
  href: string;
  children: ReactText;
}
const NavItem = ({ icon, children, href, ...rest }: NavItemProps) => {
  const { location } = useHistory();

  return (
    <RouterLink to={href}>
      <Tooltip label={children} hasArrow placement="right" offset={[-5, 0]}>
        <Box
          style={{ textDecoration: "none" }}
          _focus={{ boxShadow: "none" }}
          color={"white"}
          fontWeight={"light"}
          position={"relative"}
          className={location.pathname.includes(href) ? "link-item" : undefined}
        >
          <Flex
            my="2"
            mx="3"
            align="center"
            borderRadius="lg"
            role="group"
            cursor="pointer"
            justifyContent={"center"}
            padding="0px"
            _hover={{
              bg: useColorModeValue(
                location.pathname.includes(href) ? "blue-light" : "muted-blue",
                location.pathname.includes(href) ? "white" : "#1F2C42"
              ),
              color: location.pathname.includes(href) ? "blue-light" : "white",
            }}
            bg={location.pathname.includes(href) ? "blue-light" : "transparent"}
            color={location.pathname.includes(href) ? "blue-light" : "white"}
            // h={{ base: "2.5rem", "2xl": "3.5rem" }}
            h="12"
            {...rest}
          >
            {icon && <Icon as={icon} color="white" />}
            {/* <Text
            ml="3"
            fontSize={{ base: "0.8rem", "2xl": "1rem" }}
            fontWeight={"normal"}
          >
            {children}
          </Text> */}
          </Flex>
        </Box>
      </Tooltip>
    </RouterLink>
  );
};

interface MobileProps extends FlexProps {
  onOpen: () => void;
  notificationCount?: number;
  newNotification?: boolean;
}

const MobileNav = ({
  onOpen,
  notificationCount,
  newNotification,
  ...rest
}: MobileProps) => {
  const { location, push } = useHistory();
  const { data: auth } = useAuthProvider();
  return (
    <Flex
      // ml={{ base: 0, md: 60, "2xl": 80 }}
      ml="20"
      px={{ base: 4, md: 24 }}
      height="16"
      alignItems="center"
      bg={useColorModeValue("blue-light", "blue-dark")}
      bgGradient={useColorModeValue(
        "linear-gradient(90deg, rgba(75,98,240,1) 0%, rgba(69,90,222,1) 100%)",
        "blue-dark"
      )}
      // borderBottomWidth="1px"
      borderBottomColor={useColorModeValue("gray.200", "gray.700")}
      justifyContent="space-between"
      {...rest}
    >
      <HStack spacing={2}>
        <IconButton
          variant="outline"
          onClick={onOpen}
          size="lg"
          aria-label="open menu"
          border="none"
          icon={<HamburgerIcon color="white" />}
          _focus={{}}
          _hover={{}}
          _active={{}}
        />
        <Image src={logo_white} alt="ECNPlus" ml="4" />
      </HStack>

      <HStack spacing={{ base: 1, "2xl": 4 }} h="20" justify={"space-between"}>
        <Button
          variant={"ghost"}
          px="0"
          _hover={{ bg: "muted-blue" }}
          _active={{ bg: "muted-blue" }}
          onClick={() => push("/notifications")}
        >
          <BellIcon />
          {(newNotification || notificationCount !== 0) && (
            <Badge
              bg="red"
              rounded={"full"}
              position={"absolute"}
              top="1"
              right={"1"}
              color="white"
              fontSize={"xs"}
            >
              {notificationCount}
            </Badge>
          )}
        </Button>
        <ProfileDropDown />
        {/* <RouterLink to="/profil" style={{ flexGrow: 1 }}>
          <Button
            leftIcon={<UserIcon />}
            variant={"ghost"}
            _hover={{
              bg: useColorModeValue(
                location.pathname.includes("/profil") ? "white" : "muted-blue",
                location.pathname.includes("/profil") ? "white" : "#1F2C42"
              ),
            }}
            color={
              location.pathname.includes("/profil") ? "blue-light" : "white"
            }
            bg={location.pathname.includes("/profil") ? "white" : "transparent"}
            outline={"none"}
            px="3"
            w="full"
            justifyContent={"flex-start"}
            h={{ base: "1.8rem", "2xl": "2.5rem" }}
          >
            <Text
              isTruncated
              maxW={{
                base: "calc(240px - 130px)",
                "2xl": "calc(20rem - 10rem)",
              }}
              fontSize={{ base: "xs", "2xl": "sm" }}
            >
              {auth?.fullname || "Fahd Chedli"}
            </Text>
          </Button>
        </RouterLink> */}

        {/* <Button
          variant={"ghost"}
          px="0"
          _hover={{ bg: useColorModeValue("muted-blue", "#1F2C42") }}
          _active={{ bg: "white" }}
          flexGrow={1}
          h={{ base: "1.8rem", "2xl": "2.5rem" }}
        >
          <BellIcon />
        </Button> */}
      </HStack>
    </Flex>
  );
};

function concatFirstLetters(fullname: string) {
  // Split the full name into an array of words
  const words = fullname.split(/\s+/);

  // Extract the first letter of each word and concatenate them
  const initials = words
    .map((word: string) => word.charAt(0).toUpperCase())
    .join("");

  return initials;
}

const ProfileDropDown = ({ maxW = "90px" }: { maxW?: string }) => {
  const { data: auth } = useAuthProvider();
  const { location, push } = useHistory();
  return (
    <Menu offset={[0,2]} placement="right">
      <MenuButton
        as={Button}
        rightIcon={<ChevronDownIcon />}
        bg={
          location.pathname.includes("/profil") ? "muted-blue" : "transparent"
        }
        color={"white"}
        _active={{ bg: "muted-blue", color: "white" }}
        _focus={{}}
        _hover={{ bg: "muted-blue" }}
        fontSize={{ base: "0.8rem", "2xl": "1rem" }}
        px="2"
        w="16"
        mx="auto"
        mt="2"
      >
        <Text isTruncated maxW={maxW}>
          {concatFirstLetters(auth?.fullname || "A") || "AB"}
        </Text>
      </MenuButton>
      <MenuList bg="muted-blue" border="none" p="0" overflow={"hidden"}>
        <RouterLink to="/profil">
          <MenuItem
            icon={<UserIcon />}
            _hover={{ bg: "whiteAlpha.300" }}
            _focus={{ bg: "whiteAlpha.300" }}
            color="white"
            bg="muted-blue"
          >
            Mon compte
          </MenuItem>
        </RouterLink>
        <MenuItem
          icon={<IoLogOutOutline color="#fff" />}
          color="white"
          _hover={{ bg: "whiteAlpha.300" }}
          _focus={{ bg: "whiteAlpha.300" }}
          onClick={() => logout(push)}
          bg="muted-blue"
        >
          Déconnexion
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
