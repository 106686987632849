import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Flex,
  Icon,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import GearIcon from "../../assets/icons/GearIcon";

export default function AccordionMenu({ children }: { children: any }) {
  const history = useHistory()
  const hover_focusStyle = {
    light: {
      bg: "muted-blue",
      color: "white",
    },
    dark: {
      bg: "#1F2C42",
      color: "white"
    }
  };
  return (
    <Accordion allowToggle defaultIndex={history.location.pathname.includes("/resources") ? [0] : undefined}>
      <AccordionItem border="none" outline={"none"} px="4">
          <AccordionButton
            border="none"
            _focus={{ outline: "none" }}
            _hover={useColorModeValue(hover_focusStyle.light, hover_focusStyle.dark)}
            _expanded={useColorModeValue(hover_focusStyle.light, hover_focusStyle.dark)}
            h={"12"}
            w="100%"
            px="12px"
            borderRadius="lg"
            color="white"
          >
            {/* <Flex flex="1" textAlign="left" align={"center"}> */}
              <Icon as={GearIcon} />
              {/* <Text ml="3" fontSize={{ base: "0.7rem", "2xl": "1rem" }}>Resources</Text> */}
            {/* </Flex> */}
            {/* <AccordionIcon /> */}
          </AccordionButton>
        <AccordionPanel ml="-4px" padding={0} display={"flex"} flexDirection="column" alignItems={"stretch"}>{children}</AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}
