export const button = {
    baseStyle:{
        borderRadius: "8px",
        fontWeight: "normal",
    },
    variants: {
        primary:  ({colorMode}:{colorMode: string}) => ({
            bg: colorMode === 'dark' ? 'blue-dark-light' : 'blue-light',
            color: "white",
            height: {base: "2.5rem", "2xl": "3rem"},
            _hover:{
                bg: "dark-blue-light",
                color: "white",
                _disabled: {
                    bg: "blue-light"
                }
            },
        }),
        secondary: {
            bg: "light-blue-light",
            color: "blue-light",
            height: {base: "2.5rem", "2xl": "3rem"},
            _hover: {
                bg:"blue-light",
                color: "white"
            }
        },
        tertiary: {
            bg: "dark-blue-light",
            color: "white",
            height: "3rem",
            _hover: {
                bg:"dark-blue-dark",
                color: "white"
            }
        },
        danger: {
            bg: "red",
            color: "white",
            height: "3rem",
            _hover: {
                bg:"darkRed",
                color: "white"
            }
        },
        warning: {
            bg: "gold-yellow",
            color: "black",
            height: "3rem",
            boxShadow: "0px 2px 10px 3px #f7d3727a",
            _hover: {
                bg:"gold-yellow",
                color: "black",
                boxShadow: "0px -2px 10px 3px #f7d3727a",
            }
        },
        default: {
            bg: "lightGrey",
            color: "black",
            height: "3rem",
            _hover: {
                bg: "light-blue-light",
                color: "blue-light",
            }
        }
    }
}