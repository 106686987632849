import { Route, Switch, useRouteMatch } from "react-router-dom";
import SidebarLayout from "../../layouts/SidebarLayout";
import UserDetails from "../../pages/Unauthorized/Users/UserDetails";
import UsersList from "../../pages/Unauthorized/Users/UsersList";

export default function UserContainer() {
  const { path } = useRouteMatch();

  return (
    <SidebarLayout>
      <Switch>
        <Route exact path={`${path}`}>
          <UsersList path={path}/>
        </Route>
        <Route path={`${path}/:userId`}>
          <UserDetails />
        </Route>
        <Route>
          <h1>Page Not Found</h1>
        </Route>
      </Switch>
    </SidebarLayout>
  );
}
