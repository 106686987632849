import { Spinner } from "@chakra-ui/react";
import { Td, Tr } from "../Table/Table";

export default function LoadingTable({height = "350px"}: { height?: string }) {
  return (
    <Tr h={height}>
      <Td colSpan="12" textAlign="center">
        <Spinner size={"lg"} color="gold-yellow" />
      </Td>
    </Tr>
  );
}
