import axiosInstance from "../../utils/axiosInstance";

export const getSpecialities = async () => {
    let token = localStorage.getItem("token");
    return await axiosInstance({
      method: "get",
      url: `/speciality/with-count`,
      headers:{
        Authorization: `Bearer ${token}`
      }
    }).then((res: any) => res.data);
};

export const getOneSpeciality = async (name: string) => {
    let token = localStorage.getItem("token");
    return await axiosInstance({
      method: "get",
      url: `/speciality/${name}`,
      headers:{
        Authorization: `Bearer ${token}`
      }
    }).then((res: any) => res.data);
};

export const updateSpeciality = async (data: any) => {
    let token = localStorage.getItem("token");
    return await axiosInstance({
      method: "put",
      url: `/speciality/${data.id}`,
      data: {name: data.name, jour: data.jour, shortname: data.shortname},
      headers:{
        Authorization: `Bearer ${token}`
      }
    }).then((res: any) => res.data);
};


export const archiveSpeciality = async (id: any) => {
    let token = localStorage.getItem("token");
    return await axiosInstance({
      method: "put",
      url: `/speciality/archive/${id}`,
      headers:{
        Authorization: `Bearer ${token}`
      }
    }).then((res: any) => res.data);
};

export const deleteSpeciality = async (id: any) => {
  let token = localStorage.getItem("token");
  return await axiosInstance({
    method: "delete",
    url: `/speciality/${id}`,
    headers:{
      Authorization: `Bearer ${token}`
    }
  }).then((res: any) => res.data);
};


export const createSpeciality = async (data: any) => {
  let token = localStorage.getItem("token");
  return await axiosInstance({
    method: "post",
    url: `/speciality`,
    data,
    headers:{
      Authorization: `Bearer ${token}`
    }
  }).then((res: any) => res.data);
};