import {
  Breadcrumb,
  BreadcrumbItem,
  Flex,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import PageHeading from "../PageHeading/PageHeading";

export default function BreadCrumb({ items, isArchived }: any) {
  const textColorCurrent = useColorModeValue("black", "white");
  const textColor = useColorModeValue("blackAlpha.600", "whiteAlpha.600");
  const bgText = useColorModeValue("red.100", "red.900");

  return (
    <Flex align={"center"} justify="space-between">
      <Breadcrumb>
        {items.map((item: any, i: number) => {
          if (i === 0) {
            return (
              <BreadcrumbItem key={i}>
                <Link to={item.link}>
                  <PageHeading title={item.title} />
                </Link>
              </BreadcrumbItem>
            );
          } else {
            return (
              <BreadcrumbItem key={i}>
                <Link to={item.link}>
                  <Text color={item.isCurrent ? textColorCurrent : textColor}>
                    {item.title}
                  </Text>
                </Link>
              </BreadcrumbItem>
            );
          }
        })}
      </Breadcrumb>
      {isArchived && 
      <Text
        fontSize={"xs"}
        fontWeight={500}
        bg={bgText}
        p={1}
        px={2}
        ml="2"
        color={"red.300"}
        rounded={"full"}
      >
        Archivée
      </Text>
      }
    </Flex>
  );
}
