import { Center, Spinner, Button } from "@chakra-ui/react";

interface PaginationI {
  isFetchingNextPage: boolean;
  isLoading: boolean;
  nextPage: number;
  totalPages: number;
  setPage: (x: any) => void;
}
const PaginationTable = ({
  isFetchingNextPage,
  isLoading,
  nextPage,
  totalPages,
  setPage,
}: PaginationI) => {
  return isFetchingNextPage && !isLoading ? (
    <Center mt="3">
      <Spinner size={"md"} color="gold-yellow" />
    </Center>
  ) : nextPage < totalPages && !isFetchingNextPage ? (
    <Button
      w="full"
      borderTopRadius={"none"}
      variant="primary"
      onClick={() => setPage((prevState: number) => prevState + 1)}
    >
      Suivant
    </Button>
  ) : null;
};

export default PaginationTable;
