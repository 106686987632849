import { Filter } from "../../context/SidebarContext";
import axiosInstance from "../../utils/axiosInstance";

export const createQuestion = async (data: any) => {
  let token = localStorage.getItem("token");
  return await axiosInstance({
    method: "post",
    url: `/question/create/many`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => {
    return {
      question: res.data,
      action: data.action,
      index: data.index,
    };
  });
};

// export const createQuestion = async (data: any) => {
//   let token = localStorage.getItem("token");
//   return await axiosInstance({
//     method: "post",
//     url: `/question/create/many`,
//     data: {
//       value: data.value,
//       qcm: data.qcm,
//       options: data.options,
//       order: data.order,
//     },
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//   }).then((res: any) => {
//     return {
//       question: res.data,
//       action: data.action,
//       index: data.index
//     };
//   });
// };

export const getAllQuestions = async ({ page }: any) => {
  let token = localStorage.getItem("token");
  return await axiosInstance({
    method: "get",
    url: `/question/all?skip=${page * 20}&limit=20`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data);
};

export const getOneQuestion = async (id: string | undefined) => {
  let token = localStorage.getItem("token");
  return await axiosInstance({
    method: "get",
    url: `/question/single/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data);
};

export const updateOneQuestion = async (data: any) => {
  let token = localStorage.getItem("token");
  return await axiosInstance({
    method: "put",
    url: `/question/update/${data.id}`,
    data: {
      value: data.value,
      options: data.options,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data);
};

export const deleteQuestion = async (id: string) => {
  let token = localStorage.getItem("token");
  return await axiosInstance({
    method: "delete",
    url: `/question/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data);
};

export const saveQuestionToSerie = async (data: any) => {
  let token = localStorage.getItem("token");
  return await axiosInstance({
    method: "put",
    url: `/question/qcm/${data.id}`,
    data: { serieId: data.serieId },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data);
};

export const changeOrderQuestion = async (data: any) => {
  let token = localStorage.getItem("token");
  return await axiosInstance({
    method: "put",
    url: `/question/qcm/order/${data.id}`,
    data: { order: data.order },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data);
};

export const filterQuestions = async (queryString: string) => {
  let token = localStorage.getItem("token");
  return await axiosInstance({
    method: "get",
    url: `/question/qcm/filter/all${queryString}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data);
};

export const searchQuestion = async (queryString: string) => {
  let token = localStorage.getItem("token");
  return await axiosInstance({
    method: "get",
    url: `/question/search?${queryString}&limit=20`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data);
};

export const updateManyQuestions = async (data: any) => {
  let token = localStorage.getItem("token");
  return await axiosInstance({
    method: "put",
    url: `/question/many/update`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data);
};

export const updateQuestionNotation = async (data: any) => {
  let token = localStorage.getItem("token");
  return await axiosInstance({
    method: "put",
    url: `/question/update/${data.id}`,
    data: {
      notation: data.notation,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data);
};

export const markingAsResolved = async (data: any) => {
  let token = localStorage.getItem("token");
  const { message, questionId, resolved } = data;
  return await axiosInstance({
    method: "put",
    url: `/report/markasresolved`,
    data: {
      message,
      questionId,
      resolved,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data);
};

function objectToQueryString(obj: any) {
  const queryString = Object.keys(obj)
    .filter((key) => obj[key] !== "")
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    .join("&");

  return queryString;
}

export const filterSeriesQuestions = async ({
  page,
  limit,
  filter,
  paginated = true,
}: {
  page?: number;
  limit?: number;
  filter?: Filter;
  paginated?: boolean;
}) => {
  let token = localStorage.getItem("token");
  const filterQueryString = objectToQueryString(filter);
  const response = await axiosInstance({
    method: "get",
    url: `/question/filter/all?skip=${page}&limit=${limit}&${filterQueryString}&paginated=${Boolean(
      paginated
    )}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data[0];
};

export const filterQuestionsBySpecAndYear = async ({
  specialityId,
  year,
}: {
  specialityId: string;
  year: string;
}) => {
  let token = localStorage.getItem("token");
  const response = await axiosInstance({
    method: "get",
    url: `/question/speciality/${specialityId}/${year}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};
