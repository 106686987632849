import {
  Heading,
  List,
  ListItem,
  HStack,
  Avatar,
  Tooltip,
  Text,
  Stack,
  Spinner,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import moment from "moment";
import google from "../../assets/img/google.png";
import fb from "../../assets/img/fb.png";
import email from "../../assets/img/email.png";

interface Props {
  newJoinersLoading: boolean;
  recentJoiners: any;
}
export default function RecentJoiners({
  newJoinersLoading,
  recentJoiners,
}: Props) {
  return (
    <>
      <Heading
        as="h2"
        p="4"
        color={"blue-light"}
        fontSize="lg"
        position={"sticky"}
        top="0px"
        w="full"
        bg="lightGrey"
        zIndex={1}
      >
        Nouveaux arrivants
      </Heading>
      <List>
        {newJoinersLoading ? (
          <Stack height="200px" align={"center"} justify="center">
            <Spinner color="gold-yellow" />
          </Stack>
        ) : (
          recentJoiners.map((joiner: any, index: number) => (
            <ListItem
              px="4"
              py="2"
              key={joiner._id}
              display="flex"
              alignItems="center"
              justifyContent={"space-between"}
              bg={index % 2 === 0 ? "lightGrey" : "darkGrey"}
              
            >
              <HStack align="center" spacing={3} isTruncated>
                <Avatar src={joiner.avatar} size="xs" position={"relative"}>
                  <Avatar
                    boxSize="1.8em"
                    position={"absolute"}
                    bg="white"
                    p="1px"
                    bottom="0px"
                    right="-5px"
                    size="2xs"
                    src={
                      joiner.provider === "google"
                        ? google
                        : joiner.provider === "facebook"
                        ? fb
                        : email
                    }
                  />
                </Avatar>
                <Tooltip label={joiner._id?.fullname} hasArrow openDelay={1000}>
                  <Link to={`/utilisateurs/${joiner._id}`}>
                    <Text fontSize={"md"} isTruncated>
                      {joiner.fullname}
                    </Text>
                  </Link>
                </Tooltip>
              </HStack>
              <Tooltip
                label={moment(joiner.createdAt).format("DD/MM/YYYY, HH:mm")}
                hasArrow
                openDelay={1000}
              >
                <Text color="gray.600" fontSize={"sm"}>
                  {moment(joiner.createdAt).fromNow()}
                </Text>
              </Tooltip>
            </ListItem>
          ))
        )}
      </List>
    </>
  );
}
