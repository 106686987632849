import { Box, Button, Container, Flex, HStack, Tag, TagCloseButton } from "@chakra-ui/react";
import { useState } from "react";
import { useQuery } from "react-query";
import { Link, useHistory } from "react-router-dom";
import Forbidden from "../../../components/Forbidden/Forbidden";
import PageHeading from "../../../components/PageHeading/PageHeading";
import { Table, Td, Th, Tr } from "../../../components/Table/Table";
import { getSpecialities } from "../../../services/specialities/services";
import FilterSpecialities from "../../../components/FilterSpecialities/FilterSpecialities";
import useQueryParams from "../../../hooks/useQueryParams";
import { getUniversities } from "../../../services/university/services";
import UniversitiesTable from "./Universtities/Universities.table";
import SpecialitiesTable from "./Specialities/Specialities.table";
import { IColumnTable, IResourcesTable } from "../../../types";
import LoadingTable from "../../../components/LoadingTable/LoadingTable";
import { getSubjects } from "../../../services/subjects/services";
import SubjectsTable from "./Subjects/SubjectsTable";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";


export default function ResourcesTable({ path, columns }: IResourcesTable) {
  const [values, setValues] = useState({
    maxValue: 10,
    minValue: 0,
    page: 1
  });
  const query = useQueryParams();
  const history = useHistory();
  const [filterByStatus, setFilterByStatus] = useState(
    query.get("status") || "Tous"
  );
  const [filterByJour, setFilterByJour] = useState(query.get("jour") || "Tout");
  const [notAuthorized, setNotAuthorized] = useState<boolean | string>(false);

  const {
    data: specialities,
    isLoading: isLoadingSpecialities,
    refetch: refetchSpecialities,
  } = useQuery("specialities", getSpecialities, {
    refetchOnWindowFocus: false,
    retry: false,
    onError: (err: any) =>
      err.response.data.statusCode === 403 &&
      setNotAuthorized(err.response.data.message),
    enabled: Boolean(history.location.pathname.includes("/specialites")),
  });
  const {
    data: universities,
    isLoading: isLoadingUniversities,
    refetch: refetchUniversities,
  } = useQuery("universities", getUniversities, {
    refetchOnWindowFocus: false,
    retry: false,
    onError: (err: any) =>
      err.response.data.statusCode === 403 &&
      setNotAuthorized(err.response.data.message),
    enabled: Boolean(history.location.pathname.includes("/facultes")),
  });
  const {
    data: subjects,
    isLoading: isLoadingSubjects,
    refetch: refetchSubjects,
  } = useQuery("subjects", getSubjects, {
    refetchOnWindowFocus: false,
    retry: false,
    onError: (err: any) =>
      err.response.data.statusCode === 403 &&
      setNotAuthorized(err.response.data.message),
    enabled: Boolean(history.location.pathname.includes("/sujets")),
  });

  // Handle Page Change
  const handleChange = (value: number) => {
    let data = specialities || universities || subjects;
    let dataLength = data.length / 10;

    if(value > 0 && dataLength + 1> value){
      setValues({
        minValue: (value - 1) * 10,
        maxValue: value * 10,
        page: value
      });
    }
  };

  const clearStatusFilter = () => {
    if (query.has("status")) {
      query.delete("status");
      history.replace({
        search: query.toString(),
      });
      setFilterByStatus("Tous");
      setValues({...values, page: 1})
    }
  };
  const clearJourFilter = () => {
    if (query.has("jour")) {
      query.delete("jour");
      history.replace({
        search: query.toString(),
      });
      setFilterByJour("Tout");
      setValues({...values, page: 1})
    }
  };

  const filterByDaySpeciality = (spec: any) => {
    if (filterByJour === "Jour 1" && spec.jour === "1") {
      return true;
    }
    if (filterByJour === "Jour 2" && spec.jour === "2") {
      return true;
    }
    if (filterByJour === "Tout") {
      return true;
    }
  };
  const filterByDaySubject = (subject: any) => {
    if (filterByJour === "Jour 1" && subject.speciality.jour === "1") {
      return true;
    }
    if (filterByJour === "Jour 2" && subject.speciality.jour === "2") {
      return true;
    }
    if (filterByJour === "Tout") {
      return true;
    }
  };

  const content = () => {
    switch (path) {
      case "/resources/specialites":
        return {
          component: <SpecialitiesTable
            data={specialities?.filter((el: any) =>
              filterByStatus === "Archivés"
                ? el.archived && filterByDaySpeciality(el)
                : filterByStatus === "Non archivés"
                ? !el.archived && filterByDaySpeciality(el)
                : true && filterByDaySpeciality(el)
            ).slice(values.minValue, values.maxValue)}
            path={path}
            refetch={refetchSpecialities}
          />,
              title: "Spécialités"
        }
      case "/resources/facultes":
        return {
          component: <UniversitiesTable
            data={universities}
            path={path}
            refetch={refetchUniversities}
          />,
          title: "Facultés"
        }
      case "/resources/sujets":
        return {
          component: <SubjectsTable
            data={subjects?.filter((el: any) =>
              filterByStatus === "Archivés"
                ? el.archived && filterByDaySubject(el)
                : filterByStatus === "Non archivés"
                ? !el.archived && filterByDaySubject(el)
                : true && filterByDaySubject(el)).slice(values.minValue, values.maxValue)}
            path={path}
            refetch={refetchSubjects}
          />,
          title: "Sujets"
        }
      default:
        return {
          component: null,
          title: ""
        };
    }
  };

  return (
    <Box mt="4">
      <HStack justifyContent={"space-between"}>
        <PageHeading title={content()?.title} />
        <HStack>
          {path !== "/resources/facultes" && (
            <FilterSpecialities
              setFilterByStatus={setFilterByStatus}
              filterByStatus={filterByStatus}
              filterByJour={filterByJour}
              setFilterByJour={setFilterByJour}
              path={path}
              setValues={setValues}
            />
          )}
          <Link to={`${path}/ajouter`}>
            <Button variant={"primary"}>Ajouter</Button>
          </Link>
        </HStack>
      </HStack>
      <Box my="4">
        <HStack>
          {filterByStatus !== "Tous" && (
            <Tag size={"lg"}>
              {filterByStatus}
              <TagCloseButton onClick={clearStatusFilter} />
            </Tag>
          )}
          {filterByJour !== "Tout" && (
            <Tag size={"lg"}>
              {filterByJour}
              <TagCloseButton onClick={clearJourFilter} />
            </Tag>
          )}
        </HStack>
      </Box>
      <Box overflowX="auto" mt="6" mb="0" borderRadius={"1rem"}>
        <Table>
          <thead>
            <Tr>
              {columns.map(
                ({ textAlign, label }: IColumnTable, index: number) => (
                  <Th key={index} textAlign={textAlign}>
                    {label}
                  </Th>
                )
              )}
            </Tr>
          </thead>
          <tbody>
            {isLoadingSpecialities || isLoadingUniversities || isLoadingSubjects ? (
              <LoadingTable />
            ) : notAuthorized ? (
              <Tr>
                <Td colSpan="10">
                  <Forbidden msg={notAuthorized} />
                </Td>
              </Tr>
            ) : (
              content()?.component
            )}
          </tbody>
          <tfoot>
            <Tr>
              <Th colSpan="10">
                  <Container maxW={"8xl"}>
                    <Flex justify={"space-between"}>
                      <Button variant={"ghost"} _hover={{bg: "whiteAlpha.200"}} _active={{}} leftIcon={<ChevronLeftIcon />} onClick={() => handleChange(values.page - 1)}>Précédent</Button>
                      <Button variant={"ghost"} _hover={{bg: "whiteAlpha.200"}} _active={{}} rightIcon={<ChevronRightIcon/>} onClick={() => handleChange(values.page+1)}>Suivant</Button>
                    </Flex>
                  </Container>
              </Th>
            </Tr>
          </tfoot>
        </Table>
      </Box>
    </Box>
  );
}
