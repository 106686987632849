import {
    Box,
    Button,
    FormLabel,
    ModalFooter,
    Spinner,
    Stack,
    useToast,
  } from "@chakra-ui/react";
  import { FormProvider, useForm } from "react-hook-form";
  import { useMutation } from "react-query";
  import { useHistory, useParams } from "react-router-dom";
  import Field from "../../../../components/Field/Field";
  import MyToast from "../../../../components/Toast/Toast";
import { createSubject, updateSubject } from "../../../../services/subjects/services";
  import { IInputField } from "../../../../types";
  
  
  const SubjectForm = ({ subject, onClose, specialities }: any) => {
    const params = useParams<{ name: string }>();
    const history = useHistory();
    const toast = useToast();

    const subjectFields: IInputField[] = [
        {
          type: "text",
          name: "name",
          placeholder: "Nom du sujet",
          rules: {
            required: "Veuillez entrer le nom du sujet",
          },
        },
        {
          type: "text",
          name: "shortname",
          placeholder: "Abréviation du sujet",
          rules: {
            required: "Veuillez entrer l'abréviation du sujet",
          },
        },
        {
          type: "number",
          name: "code",
          placeholder: "Code du sujet",
          rules: {
            required: "Veuillez entrer le code du sujet",
          },
        },
        {
            type: "select",
            name: "speciality",
            placeholder: "Spécialité",
            options: specialities.map((el:any) => ({
                id: el._id,
                label: el.name,
                value: el._id
            })),
            height: "20rem",
            rules: {
              required: "Veuillez sélectionner la spécialité",
            },
          },
      ];

      const subjectMethods = useForm<any>({
      defaultValues: {
        name: params.name || "",
        shortname: params.name ? subject.shortname : "",
        speciality: params.name ? subject.speciality : "",
        code: params.name ? subject.code : 0
      },
    });
  
    const { mutateAsync, isLoading } = useMutation(
      params.name ? updateSubject : createSubject,
      {
        onSuccess: async () => {
          MyToast({
            toast,
            status: "success",
            title: params.name ? "Sujet modifié." : "Sujet ajouté.",
          });
          history.goBack();
        },
        onError: (err: any) => {
          MyToast({
            toast,
            description: err.response.data.message,
            status: "warning",
            title: "Impossible",
          });
        },
      }
    );
  
    const onSubmit = (data: any) => {
      if (params.name) {
        mutateAsync({ ...data, id: subject._id });
      } else {
        mutateAsync(data);
      }
    };
  
    return (
      <FormProvider {...subjectMethods}>
        <Stack
          spacing={4}
          as="form"
          onSubmit={subjectMethods.handleSubmit(onSubmit)}
        >
          {subjectFields.map((field: IInputField) => (
            <Box key={field.name}>
              <FormLabel>{field.placeholder}</FormLabel>
              <Field
                value={
                  field.name === "speciality" ? subjectMethods.getValues("speciality") : ""
                }
                {...field}
                bg="darkGrey"
              />
            </Box>
          ))}
          <ModalFooter px="0">
            <Button onClick={onClose} variant={"ghost"} mr={3}>
              Annuler
            </Button>
            <Button variant={"primary"} type="submit" height="2.5rem">
              {isLoading ? (
                <Spinner size={"md"} color="gold-yellow" />
              ) : (
                "Sauvgarder"
              )}
            </Button>
          </ModalFooter>
        </Stack>
      </FormProvider>
    );
  };
  
  export default SubjectForm;
  