import { Route, Switch, useRouteMatch } from "react-router-dom";
import Announces from "../../pages/Unauthorized/Announces/Announces";

export default function AnnoncesContainer() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <Announces path={path}/>
      </Route>
      <Route>
        <h1>Page Not Found</h1>
      </Route>
    </Switch>
  );
}
