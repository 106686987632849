import * as React from "react";
import _ from "lodash";
import {
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  SimpleGrid,
  Text,
  useOutsideClick,
  VStack,
} from "@chakra-ui/react";
import { DateObj, useDayzed, RenderProps } from "dayzed";
import * as dateFns from "date-fns";
import { Controller, useFormContext, useFormState } from "react-hook-form";
import { CalendarIcon } from "@chakra-ui/icons";
import AnswerWrongIcon from "../../assets/icons/AnswerWrongIcon";

const MONTH_NAMES = [
  "Janv",
  "Févr",
  "Mars",
  "Avr",
  "Mai",
  "Juin",
  "Juill",
  "Août",
  "Sept",
  "Oct",
  "Nov",
  "Déc",
];
const DAY_NAMES = ["Lun", "Mar", "Mer", "Jeu", "Ven", "Sam", "Dim"];
const DATE_FORMAT = "dd/MM/yyyy";

const SingleDatepickerBackButtons = (
  props: any
) => {
  const { calendars, getBackProps } = props;
  return (
    <>
      <Button
        {...getBackProps({
          calendars,
          offset: 12,
        })}
        variant="ghost"
        size="sm"
      >
        {"<<"}
      </Button>
      <Button {...getBackProps({ calendars })} variant="ghost" size="sm">
        {"<"}
      </Button>
    </>
  );
};

const SingleDatepickerForwardButtons = (
  props: any
) => {
  const { calendars, getForwardProps } = props;
  return (
    <>
      <Button {...getForwardProps({ calendars })} variant="ghost" size="sm">
        {">"}
      </Button>
      <Button
        {...getForwardProps({
          calendars,
          offset: 12,
        })}
        variant="ghost"
        size="sm"
      >
        {">>"}
      </Button>
    </>
  );
};

const SingleDatepickerCalendar = (props: RenderProps) => {
  const { calendars, getDateProps, getBackProps, getForwardProps } = props;

  if (_.isEmpty(calendars)) {
    return null;
  }

  return (
    <Box>
      <HStack spacing={6} alignItems="baseline">
        {_.map(calendars, (calendar) => {
          return (
            <VStack key={`${calendar.month}${calendar.year}`}>
              <HStack>
                <SingleDatepickerBackButtons
                  calendars={calendars}
                  getBackProps={getBackProps}
                />
                <Heading size="xs" textAlign="center">
                  {MONTH_NAMES[calendar.month]} {calendar.year}
                </Heading>
                <SingleDatepickerForwardButtons
                  calendars={calendars}
                  getForwardProps={getForwardProps}
                />
              </HStack>
              <Divider />
              <SimpleGrid columns={7} spacing={1} textAlign="center">
                {_.map(DAY_NAMES, (day) => (
                  <Box key={`${calendar.month}${calendar.year}${day}`}>
                    <Text fontSize="xs" fontWeight="semibold">
                      {day}
                    </Text>
                  </Box>
                ))}
                {_.map(calendar.weeks, (week, weekIndex) => {
                  return _.map(week, (dateObj: DateObj, index) => {
                    const { date, today, prevMonth, nextMonth, selected } =
                      dateObj;

                    const key = `${calendar.month}${calendar.year}${weekIndex}${index}`;
                    const isDisabled = prevMonth || nextMonth;

                    const style = () => {
                      const obj: any = {
                        variant: "outline",
                        borderColor: "transparent",
                      };

                      if (today) {
                        obj.borderColor = "purple.400";
                      }

                      if (selected) {
                        obj.bg = "purple.200";
                      }

                      return obj;
                    };

                    return (
                      <Button
                        {...getDateProps({
                          dateObj,
                          disabled: isDisabled,
                        })}
                        key={key}
                        size="xs"
                        {...style()}
                      >
                        {date.getDate()}
                      </Button>
                    );
                  });
                })}
              </SimpleGrid>
            </VStack>
          );
        })}
      </HStack>
    </Box>
  );
};

export const DatepickerField = ({
  name,
  rules,
  bg,
  hidden = false,
  disabled = false,
  value,
  onChange,
}: any) => {
  // export const SingleDatepicker = (props: SingleDatepickerProps) => {
  // const { value, disabled, onChange } = props;

  const ref = React.useRef<HTMLElement>(null);
  const initialFocusRef = React.useRef<HTMLInputElement>(null);

  const [proposedDate, setProposedDate] = React.useState<string>(
    value ? dateFns.format(value, DATE_FORMAT) : ""
  );
  const [popoverOpen, setPopoverOpen] = React.useState(false);

  useOutsideClick({
    ref: ref,
    // handler: () => setPopoverOpen(false),
  });

  const onChangePrime = (date?: Date) => {
    onChange(date);
    if (date) {
      setProposedDate(dateFns.format(date, DATE_FORMAT));
    }
  };

  const onDateSelected = (options: { selectable: boolean; date: Date }) => {
    const { selectable, date } = options;

    if (!selectable) {
      return;
    }

    if (!_.isNil(date)) {
      onChangePrime(date);
      setPopoverOpen(false);
      return;
    }
  };

  const dayzedData = useDayzed({
    showOutsideDays: true,
    onDateSelected,
    selected: value,
  });
  const { control, watch } = useFormContext();
  const { errors } = useFormState({ control });
  const bgColorMode = "red";
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={_.get(watch(), name)}
      render={({ field: { ref, ...rest } }) => (
        <Popover
          placement="bottom-start"
          variant="responsive"
          isOpen={popoverOpen}
          onClose={() => setPopoverOpen(false)}
          // initialFocusRef={initialFocusRef}
          isLazy
        >
          {/* @ts-ignore */}
          <PopoverTrigger>
            <InputGroup>
            {!hidden && _.get(errors, name) ? (
                <InputLeftElement
                  pointerEvents="none"
                  children={<AnswerWrongIcon />}
                  h={"full"}
                />
              ) : (
                <InputLeftElement
                  pointerEvents="none"
                  children={<CalendarIcon />}
                  h={"full"}
                />
              )}
              <Input
                bg="white"
                isDisabled={disabled}
                ref={initialFocusRef}
                onClick={() => setPopoverOpen(!popoverOpen)}
                {...rest}
                // height={{ base: "2.5rem", "2xl": "3rem" }}
                borderColor={
                  !hidden && _.get(errors, name) ? "red.500" : "gray.300"
                }
                style={{paddingLeft: "35px"}}
                _focus={{}}
                value={proposedDate}
                onChange={(e) => {
                  setProposedDate(e.target.value);
                }}
                onBlur={() => {
                  const d = dateFns.parse(
                    proposedDate,
                    DATE_FORMAT,
                    new Date()
                  );
                  dateFns.isValid(d)
                    ? onChangePrime(d)
                    : onChangePrime(undefined);
                }}
              />
            </InputGroup>
          </PopoverTrigger>
          <PopoverContent ref={ref} width="fit-content">
            <PopoverBody>
              <SingleDatepickerCalendar {...dayzedData} />
            </PopoverBody>
          </PopoverContent>
        </Popover>
      )}
    />
  );
};