import { Route, Switch, useRouteMatch } from "react-router-dom";
import Exams from "../../pages/Unauthorized/Exams/Exams";
import ExamsAdd from "../../pages/Unauthorized/Exams/ExamsAdd";
import ExamsUniversity from "../../pages/Unauthorized/Exams/ExamsUniversity";

export default function ExamsContainer() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <Exams />
      </Route>
      <Route path={`${path}/:year/:universityName`}>
        <ExamsUniversity />
      </Route>
      <Route exact path={`${path}/ajouter`}>
        <ExamsAdd />
      </Route>
      <Route>
        <h1>Page Not Found</h1>
      </Route>
    </Switch>
  );
}
