import axiosInstance from "../../utils/axiosInstance";

export const getSubjects = async () => {
    let token = localStorage.getItem("token");
    return await axiosInstance({
      method: "get",
      url: `/subject/with-archived`,
      headers:{
        Authorization: `Bearer ${token}`
      }
    }).then((res: any) => res.data);
};

export const getOneSubject = async (name: string) => {
    let token = localStorage.getItem("token");
    return await axiosInstance({
      method: "get",
      url: `/subject/by-name/${name}`,
      headers:{
        Authorization: `Bearer ${token}`
      }
    }).then((res: any) => res.data);
};

export const createSubject = async (data: any) => {
    let token = localStorage.getItem("token");
    return await axiosInstance({
      method: "post",
      url: `/subject`,
      data,
      headers:{
        Authorization: `Bearer ${token}`
      }
    }).then((res: any) => res.data);
};

export const updateSubject = async (data: any) => {
    let token = localStorage.getItem("token");
    return await axiosInstance({
      method: "put",
      url: `/subject/${data.id}`,
      data: {name: data.name, shortname: data.shortname, speciality: data.speciality, code: data.code},
      headers:{
        Authorization: `Bearer ${token}`
      }
    }).then((res: any) => res.data);
};

export const deleteSubject = async (id: string) => {
    let token = localStorage.getItem("token");
    return await axiosInstance({
      method: "delete",
      url: `/subject/${id}`,
      headers:{
        Authorization: `Bearer ${token}`
      }
    }).then((res: any) => res.data);
};


export const archiveSubject = async (id: string) => {
    let token = localStorage.getItem("token");
    return await axiosInstance({
      method: "put",
      url: `/subject/archive/${id}`,
      headers:{
        Authorization: `Bearer ${token}`
      }
    }).then((res: any) => res.data);
};


export const getSubjectsBySpecialityName = async (specialityName: string) => {
  let token = localStorage.getItem("token");
  return await axiosInstance({
    method: "get",
    url: `/subject/by-spec/${specialityName}?archived=true`,
    headers:{
      Authorization: `Bearer ${token}`
    }
  }).then((res: any) => res.data);
};