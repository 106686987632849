import {
  Breadcrumb,
  BreadcrumbItem,
  Heading,
  Icon,
  LinkBox,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useQuery } from "react-query";
import {
  Link,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import TestIcon from "../../../assets/icons/TestIcon";
import PageHeading from "../../../components/PageHeading/PageHeading";
import SidebarLayout from "../../../layouts/SidebarLayout";
import { getExamsCount } from "../../../services/Exams/services";
import { getOneUniversity } from "../../../services/university/services";
import ExamsList from "./ExamsList";

export default function ExamsUniversity() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <ExamDays path={path} />
      </Route>
      <Route path={`${path}/:day`}>
        <ExamsList path={path} />
      </Route>
    </Switch>
  );
}

const ExamDays = ({ path }: { path: string }) => {
  const { url } = useRouteMatch();
  const textColor = useColorModeValue("blackAlpha.600", "whiteAlpha.600");
  const headingColor = useColorModeValue("blue-light", "blue-light");
  const bgBox = useColorModeValue("lightGrey", "card-dark");
  const bgStack = useColorModeValue("gray.800", "white");
  const history = useHistory();
  const { universityName, year } =
    useParams<{ universityName: string; year: string }>();

    
    const { data: university, isLoading: loadingUniversity } = useQuery(
      `university-${universityName}`,
      () => getOneUniversity(universityName),
      {
        refetchOnWindowFocus: false,
      }
    );
    const universityID = university?._id;
    const { data: exams, isLoading: loadingexamsDays } = useQuery(
      ["days", universityID],
      () => getExamsCount({university: universityID, year}),
      {
        refetchOnWindowFocus: false,
        enabled: !!Boolean(university)
      }
    );

  return (
    <SidebarLayout>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to={"/examen-blanc"}>
            <PageHeading title={"Examen blanc"} />
          </Link>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <Text color={textColor} textTransform="capitalize">
            épreuve
          </Text>
        </BreadcrumbItem>
      </Breadcrumb>
      <Stack spacing={6} my={4}>
        <Heading as="h1" size="md" color={headingColor}>
          {universityName + " " + year}
        </Heading>
        <Stack direction={{ base: "column", sm: "row" }} spacing={4}>
          {[1, 2].map((jour: number) => (
            <LinkBox
              onClick={() => history.push(`${url}/${jour}`)}
              key={jour}
              as="article"
              w="full"
              maxW={{ base: "auto", md: "325px" }}
              h={"200px"}
              bg={bgBox}
              p="5"
              borderWidth="1px"
              borderColor={"transparent"}
              rounded=".8rem"
              transition={"all 0.15s ease-in-out"}
              _hover={{
                borderColor: "darkSky",
                boxShadow: "xs",
                cursor: "pointer",
              }}
              overflow="hidden"
            >
              <Stack color={bgStack} h="full" justify={"center"} align="center">
                <Icon as={TestIcon} />
                <Text>Jour {jour}</Text>
                {loadingexamsDays || loadingUniversity ? <p>Loading...</p> : <Text color="gray.500">{
                  exams.find((el: any) => +el?.jour === jour) ? <p>✅</p> : <p>❌</p>
                }</Text>}
              </Stack>
            </LinkBox>
          ))}
        </Stack>
      </Stack>
    </SidebarLayout>
  );
};
