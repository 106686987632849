import axiosInstance from "../../utils/axiosInstance";

export const getUsers = async (page: number) => {
  let token = localStorage.getItem("token");
  const response = await axiosInstance({
    method: "get",
    url: `/admin/clients/all?skip=${page * 20}&limit=20`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return {
    results: response.data.results,
    nextPage: page + 1,
    totalPages: response.data.totalPages,
    count: response.data.count,
  };
};

export const getUserDetails = async (userId: string) => {
  let token = localStorage.getItem("token");
  const response = await axiosInstance({
    method: "get",
    url: `/attempts/details/series/${userId}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getExamsAttempts = async (userId: string) => {
  let token = localStorage.getItem("token");
  const response = await axiosInstance({
    method: "get",
    url: `/attempts/details/exams/${userId}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getMostActiveUsers = async () => {
  let token = localStorage.getItem("token");
  const response = await axiosInstance({
    method: "get",
    url: `/attempts/users/most-active`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getMostDoneQcms = async (type: string) => {
  let token = localStorage.getItem("token");
  const response = await axiosInstance({
    method: "get",
    url: `/qcm/details/most-done?type=${type}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getNewJoiners = async () => {
  let token = localStorage.getItem("token");
  const response = await axiosInstance({
    method: "get",
    url: `/admin/clients/latest`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const verifyUserAccount = async (userId: string) => {
  let token = localStorage.getItem("token");
  const response = await axiosInstance({
    method: "put",
    url: `/admin/verify-user/${userId}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};
