import axiosInstance from "../../utils/axiosInstance";
import { objectToQueryString } from "../Remarques/services";

interface PackOrder {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  pickUpStore: string;
}

export const getPackOrders = async ({currentPage, filter}: {currentPage: number, filter: any}) => {
  let token = localStorage.getItem("token");
  const filterQueryString = objectToQueryString(filter)
  return await axiosInstance({
    method: "GET",
    url: `/pack-orders?skip=${(currentPage - 1) * 20}&limit=20&${filterQueryString}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data);
};

export const createPackOrder = async (data: PackOrder) => {
  let token = localStorage.getItem("token");
  return await axiosInstance({
    method: "POST",
    url: `/pack-orders`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data);
};


export const updatePackOrderStatus = async ({
  orderId,
  status,
}: {
  orderId: string;
  status: string|string[];
}) => {
  let token = localStorage.getItem("token");
  return await axiosInstance({
    method: "PUT",
    url: `/pack-orders/${orderId}`,
    data: {
      status,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data);
};
