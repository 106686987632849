import {
  Box,
  Button,
  FormLabel,
  ModalFooter,
  Spinner,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import Field from "../../../../components/Field/Field";
import MyToast from "../../../../components/Toast/Toast";
import {
  createUniversity,
  updateUniversity,
} from "../../../../services/university/services";
import { IInputField } from "../../../../types";

const universityFields: IInputField[] = [
  {
    type: "text",
    name: "name",
    placeholder: "Nom de faculté",
    rules: {
      required: "Veuillez entrer le nom de faculté",
    },
  },
];

const UniversityForm = ({ university, onClose }: any) => {
  const params = useParams<{ name: string }>();
  const history = useHistory();
  const toast = useToast();

  const universityMethods = useForm<any>({
    defaultValues: {
      name: params.name || "",
    },
  });

  const { mutateAsync, isLoading } = useMutation(
    params.name ? updateUniversity : createUniversity,
    {
      onSuccess: async () => {
        MyToast({
          toast,
          status: "success",
          title: params.name ? "Faculté modifiée." : "Faculté ajoutée.",
        });
        history.goBack();
      },
      onError: (err: any) => {
        MyToast({
          toast,
          description: err.response.data.message,
          status: "warning",
          title: "Impossible",
        });
      },
    }
  );

  const onSubmit = (data: any) => {
    if (params.name) {
      mutateAsync({ ...data, id: university._id });
    } else {
      mutateAsync(data);
    }
  };

  return (
    <FormProvider {...universityMethods}>
      <Stack
        spacing={4}
        as="form"
        onSubmit={universityMethods.handleSubmit(onSubmit)}
      >
        {universityFields.map((field: IInputField) => (
          <Box key={field.name}>
            <FormLabel>{field.placeholder}</FormLabel>
            <Field
              value={
                field.name === "jour" ? universityMethods.getValues("jour") : ""
              }
              {...field}
              bg="darkGrey"
            />
          </Box>
        ))}
        <ModalFooter px="0">
          <Button onClick={onClose} variant={"ghost"} mr={3}>
            Annuler
          </Button>
          <Button variant={"primary"} type="submit" height="2.5rem">
            {isLoading ? (
              <Spinner size={"md"} color="gold-yellow" />
            ) : (
              "Sauvgarder"
            )}
          </Button>
        </ModalFooter>
      </Stack>
    </FormProvider>
  );
};

export default UniversityForm;
