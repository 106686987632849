import { Box, FormLabel, HStack, Button } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { IInputField } from "../../types";
import Field from "../Field/Field";

export default function QuestionFormType({ specialities, subjects, methods, question }: any) {
  const getSelectValue = (fieldName: string) => {
    switch (fieldName) {
      case "subject":
        return methods.getValues("subject");
      case "speciality":
        return methods.getValues("speciality");
      default:
        return "";
    }
  };

  useEffect(() => {
    if(question){
        methods.setValue("speciality", question?.speciality._id);
        methods.setValue("subject", question?.subject._id);
        // methods.setValue("speciality", specialities.find((el: any) => el._id === question?.speciality._id));
        // methods.setValue("subject", subjects.find((el: any) => el._id === question?.subject._id));
    }
  }, [question])

  const serieFields: IInputField[] = [
    {
      type: "select",
      name: "speciality",
      placeholder: "Spécialité",
      options: specialities.map((el: any) => ({
        id: el._id,
        label: el.name,
        value: el._id,
      })),
      height: "20rem",
      rules: {
        required: "Veuillez sélectionner la spécialité",
      },
    },
    {
      type: "select",
      name: "subject",
      placeholder: "Sujet",
      options: subjects
        .filter((x: any) => x.speciality._id == methods.watch("speciality"))
        .map((el: any) => ({
          id: el._id,
          label: el.name,
          value: el._id,
        })),
      rules: {
        required: "Veuillez sélectionner le sujet",
      },
    },
  ];

  return (
    <FormProvider {...methods}>
      <HStack wrap={"wrap"} as="form">
        {serieFields.map((field: IInputField) => (
          <Box key={field.name} flexGrow="1">
            <FormLabel>{field.placeholder}</FormLabel>
            <HStack>
              <Box flexGrow={1}>
                <Field
                  value={getSelectValue(field.name)}
                  {...field}
                  bg="darkGrey"
                />
              </Box>
            </HStack>
          </Box>
        ))}
      </HStack>
    </FormProvider>
  );
}
