import Router from "./Routes";
import { Route, Switch } from "react-router-dom";
import Login from "./pages/Authorized/Login/Login";
import AuthProvider from "./auth-provider/AuthProvider";
import "./App.css";
import EnterEmail from "./pages/Authorized/ResetPassword/EnterEmail";
import ForgetPassword from "./pages/Authorized/ResetPassword/ForgetPassword";
import DashboardContainer from "./containers/Unauthorized/Dashboard.container";
import ProfileContainer from "./containers/Unauthorized/Profile.container";
import ResourcesContainer from "./containers/Unauthorized/Resources.container";
import QuestionsContainer from "./containers/Unauthorized/Questions.container";
import SeriesContainer from "./containers/Unauthorized/SeriesContainer";
import ExamsContainer from "./containers/Unauthorized/ExamsContainer";
import RemarquesContainer from "./containers/Unauthorized/RemarquesContainer";
import NewRemarquesContainer from "./containers/Unauthorized/NewRemarquesContainer";
import NotificationsContainer from "./containers/Unauthorized/NotificationsContainer";
import UserContainer from "./containers/Unauthorized/Users.container";
import { SocketContext, socket } from "./context/socket";
import AnnoncesContainer from "./containers/Unauthorized/AnnoncesContainer";
import { SidebarProvider } from "./context/SidebarContext";
import ProtectedRouteByRole from "./ProtectedRouteByRole";
import SidebarLayout from "./layouts/SidebarLayout";
import NotFound from "./pages/Authorized/NotFound/NotFound";
import PackOrdersContainer from "./containers/Unauthorized/PackOrdersContainer";

export default function App() {
  return (
    <SocketContext.Provider value={socket}>
      <Router>
        <Switch>
          <Route exact path="/">
            <Login />
          </Route>
          <Route exact path="/mot-de-passe-oublie/email">
            <EnterEmail />
          </Route>
          <Route exact path="/mot-de-passe-oublie/reinitialisation/:token">
            <ForgetPassword />
          </Route>
          <AuthProvider>
            <SidebarProvider>
              {/* <Route exact path="/tableau-de-bord">
                <DashboardContainer />
              </Route> */}
              <ProtectedRouteByRole
                path="/tableau-de-bord"
                component={DashboardContainer}
                permission="dashboard"
                exact
              />
              {/* <Route path="/series">
                <SeriesContainer />
              </Route> */}
              <ProtectedRouteByRole
                path="/series"
                component={SeriesContainer}
                permission="series"
              />
              {/* <Route path="/examen-blanc">
                <ExamsContainer />
              </Route> */}
              <ProtectedRouteByRole
                path="/examen-blanc"
                component={ExamsContainer}
                permission="exams"
              />
              <Route path="/questions">
                <QuestionsContainer />
              </Route>
              {/* <Route path="/utilisateurs">
                <UserContainer />
              </Route> */}
              <ProtectedRouteByRole
                path="/utilisateurs"
                component={UserContainer}
                permission="users"
              />
              {/* <Route path="/remarques">
              <RemarquesContainer />
            </Route> */}
              <ProtectedRouteByRole
                path="/remarques"
                component={NewRemarquesContainer}
                permission="remarques"
              />
              <ProtectedRouteByRole
                path="/annonces"
                component={AnnoncesContainer}
                permission="annonces"
              />
              <Route path="/profil">
                <ProfileContainer />
              </Route>
              <Route path="/notifications">
                <NotificationsContainer />
              </Route>
              <ProtectedRouteByRole
                path="/resources"
                component={ResourcesContainer}
                permission="resources"
              />
              <ProtectedRouteByRole
                path="/commandes"
                component={PackOrdersContainer}
                permission="pack-orders"
              />
              {/* <Route path="/resources">
                <ResourcesContainer />
              </Route> */}
              <Route path={"/unauthorized"}>
                <SidebarLayout>
                  <NotFound />
                </SidebarLayout>
              </Route>
            </SidebarProvider>
          </AuthProvider>
        </Switch>
      </Router>
    </SocketContext.Provider>
  );
}
