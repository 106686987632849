import { Stack, Box, Heading } from "@chakra-ui/react";
import RightDrawer from "../../../components/Drawer/RightDrawer";
import RadioGroup from "../../../components/RadioButtons/RadioButtons";

export default function FilterSeries({ btnRef, isOpen, onClose, filters, setFilters }: any) {
  return (
    <RightDrawer
      btnRef={btnRef}
      isOpen={isOpen}
      onClose={onClose}
      title={"Affichage"}
    >
      <Stack>
        <Box my="4">
          <Heading as="h6" fontSize={"sm"}>
            Regrouper par
          </Heading>
          <Box mt="2">
            <RadioGroup options={["Rien", "Faculté", "Année"]} name="groupBy" defaultValue={filters.groupBy} setFilters={setFilters}/>
          </Box>
        </Box>
        <Box my="4">
          <Heading as="h6" fontSize={"sm"}>
            Trier par
          </Heading>
          <Box mt="2">
            <RadioGroup options={["Faculté", "Année"]} name="orderBy" defaultValue={filters.orderBy} setFilters={setFilters}/>
          </Box>
        </Box>
      </Stack>
    </RightDrawer>
  );
}
