import React from "react";
import { Td, Tr } from "../../../../components/Table/Table";
import { Text, Tooltip } from "@chakra-ui/react";
import {
  formatQcmType,
  formatReportsErrorMsg,
  formatUniversityName,
} from "../../../../utils/utils";
import AnswerRightIcon from "../../../../assets/icons/AnswerRightIcon";
import AnswerWrongIcon from "../../../../assets/icons/AnswerWrongIcon";
import { Question } from "./AccordionQuestion";

interface Report {
  message: string;
  resolved: boolean;
  lastUpdatedAt: string;
  _id: string;
}

interface ReportDetails {
  updatedBy?: { fullname: string; _id: string };
  lastUpdatedAt: string;
}

interface Props {
  expandedRow: string | null;
  handleRowClick: (
    _id: string,
    questionId: Question,
    reportDetails: ReportDetails
  ) => void;
  oneReport: Report;
  speciality: string;
  subject: string;
  university: string;
  type: string;
  jour: string;
  year: string;
  message: string;
  order: number;
  reportsCount: number;
  question: Question;
  updatedBy?: {
    fullname: string;
    _id: string;
  };
}

export default function RemarqueRow({
  expandedRow,
  oneReport,
  handleRowClick,
  speciality,
  subject,
  university,
  type,
  jour,
  year,
  message,
  order,
  reportsCount,
  question,
  updatedBy,
}: Props) {
  return (
    <Tr
      height="50px"
      cursor="pointer"
      _hover={{ bg: "lightGrey" }}
      bg={expandedRow === oneReport._id ? "#EDF2F7" : "gray.50"}
      onClick={() =>
        handleRowClick(oneReport._id, question, {
          updatedBy,
          lastUpdatedAt: oneReport.lastUpdatedAt,
        })
      }
    >
      <Td>{oneReport.resolved ? <AnswerRightIcon /> : <AnswerWrongIcon />}</Td>
      <Td>J.{jour}</Td>
      <Td>
        <Tooltip label={speciality} hasArrow openDelay={2000}>
          <Text isTruncated maxW={"200px"}>
            {speciality}
          </Text>
        </Tooltip>
      </Td>
      <Td>
        <Tooltip label={subject} hasArrow openDelay={2000}>
          <Text isTruncated maxW={"200px"}>
            {subject}
          </Text>
        </Tooltip>
      </Td>
      <Td>{year}</Td>
      <Td>
        <Tooltip label={university} hasArrow openDelay={2000}>
          <p>{formatUniversityName(university)}</p>
        </Tooltip>
      </Td>
      <Td>{formatQcmType(type)}</Td>
      <Td>
        <Tooltip label={message} hasArrow openDelay={1000}>
          {formatReportsErrorMsg(message)}
        </Tooltip>
      </Td>
      <Td>{order}</Td>
      <Td>{reportsCount}</Td>
    </Tr>
  );
}
