import { TableContainer, Table, Thead, Tbody } from "@chakra-ui/react";
import { ReactElement } from "react";
import { Th, Tr } from "../Table/Table";

const CustomTable = ({
    columns,
    children,
  }: {
    columns: Array<string>;
    children: ReactElement;
  }) => {
    return (
      <TableContainer rounded={"1rem"} mt="4">
        <Table>
          <Thead>
            <Tr>
              {columns.map((col: string, i: number) => (
                <Th key={i}>{col}</Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>{children}</Tbody>
        </Table>
      </TableContainer>
    );
  };

  export default CustomTable