import { Box, Heading, Text, Button } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";

export default function NotFound() {
  const history = useHistory();
  return (
    <Box textAlign="center" py={10} px={6}>
      <Heading
        display="inline-block"
        as="h2"
        size="4xl"
        bgGradient={
          "linear-gradient(90deg, rgb(152 165 242) 0%, rgba(69, 90, 222, 1) 100%)"
        }
        backgroundClip="text"
      >
        404
      </Heading>
      <Text fontSize="18px" mt={3} mb={2}>
        Page Introuvable
      </Text>
      <Text color={"gray.500"} mb={6}>
        La page que vous voulez consulter n'existe pas ou n'est plus en ligne.
      </Text>

      <Button
        _hover={{
          bgGradient:
            "linear-gradient(90deg, rgba(69, 90, 222, 1) 0%, rgba(69, 90, 222, 1) 100%)",
        }}
        bgGradient="linear-gradient(90deg, rgb(152 165 242) 0%, rgba(69, 90, 222, 1) 100%)"
        color="white"
        onClick={() => history.push("/")}
      >
        Retourner à la page d'accueil
      </Button>
    </Box>
  );
}
