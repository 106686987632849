import { useCallback } from "react";
import {
  HStack,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import moment from "moment";
import { getStatusTag } from "../constants";

const OrderSummary = ({ order }: any) => {
  const tagOptions = useCallback((status: string) => getStatusTag(status), []);
  const isDelivery = order.orderType === "delivery";

  return (
    <>
      <HStack justifyContent={"space-between"} alignItems="flex-start" mb="2">
        <HStack flex={1} justify="space-between">
          <Text fontSize={"md"}>
            Commande:{" "}
            <Text as="span" fontWeight="bold">
              #{order.reference}
            </Text>
          </Text>
          <HStack align={"flex-end"}>
            <Tag
              size="md"
              bg={"white"}
              color={"black"}
              border="1px solid"
              borderColor={"black"}
              rounded={"xl"}
            >
              {order.pickUpStore}
            </Tag>
            <Tag
              size="md"
              colorScheme={tagOptions(order.status).colorScheme}
              rounded={"xl"}
            >
              {tagOptions(order.status).label}
            </Tag>
          </HStack>
        </HStack>
      </HStack>
      <Text color="gray.700">
        Commande reçu le:{" "}
        <Text as="span" color="black" fontWeight={"normal"}>
          {moment(order.createdAt).format("Do MMMM YYYY, HH:mm")}
        </Text>
      </Text>
      <Table border={"none"} mt="4">
        <Thead>
          <Tr>
            <Th
              p="1"
              border="none"
              color="#AAAAAA"
              fontWeight={"500"}
              width="50%"
            >
              Prénom
            </Th>
            <Th
              p="1"
              border="none"
              color="#AAAAAA"
              fontWeight={"500"}
              width="50%"
            >
              Nom
            </Th>
          </Tr>
        </Thead>

        <Tbody>
          <Tr>
            <Td p="1" border="none" color="black" fontWeight={"normal"}>
              {order.firstName}
            </Td>
            <Td p="1" border="none" color="black" fontWeight={"normal"}>
              {order.lastName}
            </Td>
          </Tr>
        </Tbody>
      </Table>
      <Table border={"none"}>
        <Thead>
          <Tr>
            <Th
              p="1"
              pb="0"
              border="none"
              color="#AAAAAA"
              fontWeight={"500"}
              width="50%"
            >
              E-mail
            </Th>
            <Th
              p="1"
              pb="0"
              border="none"
              color="#AAAAAA"
              fontWeight={"500"}
              width="50%"
            >
              Tél
            </Th>
          </Tr>
        </Thead>

        <Tbody>
          <Tr>
            <Td
              p="1"
              pb="0"
              border="none"
              color="black"
              fontWeight={"normal"}
              wordBreak="break-all"
            >
              {order.email}
            </Td>
            <Td p="1" pb="0" border="none" color="black" fontWeight={"normal"}>
              {order.phone}
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </>
  );
};

export default OrderSummary;

export const addCommas = (number: number) => {
  // Convert number to string
  let strNumber = number.toString();

  // Check if number has 3 digits
  if (strNumber.length === 3) {
    // strNumber = strNumber + ",000";
    strNumber = strNumber;
  } else {
    // Use regex to add commas
    strNumber = strNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return strNumber;
};
