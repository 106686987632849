import { useMutation, useQuery } from "react-query";
import { useHistory, useLocation, useParams } from "react-router-dom";
import SidebarLayout from "../../../layouts/SidebarLayout";
import {
  archiveSerie,
  deleteSerie,
  getSerieByIdWithQuestions,
} from "../../../services/series/services";
import {
  Accordion,
  HStack,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb";
import capitalizeText from "../../../utils/capitalizeText";
import MyToast from "../../../components/Toast/Toast";
import SerieDropDownMenu from "../../../components/QcmDropDownMenu/SerieDropDownMenu";
import { lazy, Suspense, useContext, useEffect } from "react";
import QuestionTableLoading from "../../../components/LoadingScreens/QuestionTableLoading";
import { SocketContext } from "../../../context/socket";
import SeriesInfoForm from "./SeriesInfoForm";
const AccordionQuestion = lazy(
  () => import("../../../components/AccordionQuestion/AccordionQuestion")
);

export default function SingleSerie() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const socket = useContext(SocketContext);
  const params = useParams<{
    specialityName: string;
    subjectName: string;
    serieId: string;
  }>();
  const { pathname } = useLocation();
  const history = useHistory();
  const toast = useToast();

  const {
    data,
    isLoading: serieIsLoading,
    refetch,
  } = useQuery(`single-serie-${params.serieId}`, () =>
    getSerieByIdWithQuestions(params.serieId)
  );

  const { mutateAsync: deleteOneSerie, isLoading: deleteSerieLoading } =
    useMutation(deleteSerie, {
      onSuccess: () => {
        MyToast({ toast, status: "success", title: "Série supprimée." });
        history.goBack();
      },
      onError: (err: any) => {
        MyToast({
          toast,
          description: err.response.data.message,
          status: "warning",
          title: "Impossible",
        });
      },
    });
  const { mutateAsync: archiveOneSerie, isLoading: archiveSerieLoading } =
    useMutation(archiveSerie, {
      onSuccess: (res) => {
        MyToast({
          toast,
          status: "success",
          title: res.archived ? "Série archivée." : "Série restaurée.",
        });
        if (res?.archived === false) {
          socket?.emit("serie", {
            qcm: {
              year: data?.serie?.year,
              qcmId: data?.serie?._id,
              university: data?.serie?.university?.name,
              speciality: data?.serie?.speciality?.name,
              subject: data?.serie?.subject?.name,
            },
          });
        }
        refetch();
      },
      onError: (err: any) => {
        MyToast({
          toast,
          description: err.response.data.message,
          status: "warning",
          title: "Impossible",
        });
      },
    });
  const bgText = useColorModeValue("red.100", "red.900");
  const breadcrumb = [
    {
      title: "Série",
      link: "/series",
      isCurrent: false,
    },
    {
      title: capitalizeText(data?.serie.speciality.name ?? ""),
      link: `/series/${data?.serie.speciality.name}`,
      isCurrent: false,
    },
    {
      title: capitalizeText(data?.serie.subject.name ?? ""),
      link: `/series/${data?.serie.speciality.name}/${data?.serie.subject.name}`,
      isCurrent: false,
    },
  ];
  return (
    <SidebarLayout>
      {serieIsLoading ? (
        <p>Loading...</p>
      ) : (
        <>
          <HStack justify={"space-between"}>
            <HStack>
              <BreadCrumb
                items={[
                  ...breadcrumb,
                  {
                    title:
                      data?.serie.university.name + " - " + data?.serie.year,
                    isCurrent: true,
                    link: "#",
                  },
                ]}
              />
              {data?.serie.archived && (
                <Text
                  fontSize={"xs"}
                  fontWeight={500}
                  bg={bgText}
                  p={1}
                  px={2}
                  color={"red.300"}
                  rounded={"full"}
                >
                  Archivée
                </Text>
              )}
            </HStack>
            <SerieDropDownMenu
              data={data}
              archiveOneSerie={archiveOneSerie}
              archiveSerieLoading={archiveSerieLoading}
              deleteOneSerie={deleteOneSerie}
              deleteSerieLoading={deleteSerieLoading}
              outsideSerie={false}
              onOpen={onOpen}
            />
          </HStack>
          <SeriesInfoForm onClose={onClose} isOpen={isOpen} serie={data?.serie}/>
          <Accordion defaultIndex={[0]} allowMultiple>
            {data?.questions.map((el: any, i: number) => (
              <Suspense fallback={<QuestionTableLoading />} key={i}>
                <AccordionQuestion
                  index={i}
                  data={el}
                  path={pathname}
                  refetch={refetch}
                  _id={true}
                />
              </Suspense>
            ))}
          </Accordion>
        </>
      )}
    </SidebarLayout>
  );
}
