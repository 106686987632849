import { CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Container,
  Stack,
  HStack,
  Heading,
  Flex,
  Text,
  useToast,
  Spinner,
  useColorModeValue,
} from "@chakra-ui/react";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import AvatarIcon from "../../../../assets/icons/AvatarIcon";
import EmailIcon from "../../../../assets/icons/EmailIcon";
import KeyIcon from "../../../../assets/icons/KeyIcon";
import Field from "../../../../components/Field/Field";
import PageHeading from "../../../../components/PageHeading/PageHeading";
import PermissionBlock from "../../../../components/PermissionBlock/PermissionBlock";
import MyToast from "../../../../components/Toast/Toast";
import Toast from "../../../../components/Toast/Toast";
import {
  createAdmin,
  getOneAdmin,
  updateAdmin,
} from "../../../../services/admin/services";
import { getPermissions } from "../../../../services/permissions/services";
import { ICollabForm, IInputField } from "../../../../types";

export default function CollaborateurFormWrapper() {
  const params = useParams<{ fullname: string }>();
  const {
    data: collaborateur,
    isLoading: isLoadingCollaborateur,
    isError,
  } = useQuery(
    `collaborateur-${params.fullname}`,
    () => getOneAdmin(params.fullname),
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: Boolean(params?.fullname),
    }
  );
  const { data: permissions, isLoading } = useQuery(
    "permissions",
    getPermissions,
    { refetchOnWindowFocus: false, retry: false }
  );

  if (isLoading || isLoadingCollaborateur) return <h1>Loading...</h1>;

  if (isError && params.fullname) {
    return (
      <Container maxW={"5xl"}>
        <Box bg="red.50" p="8" borderRadius={"lg"} textAlign="center">
          <Box textAlign={"center"} display={"inline-block"}>
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              bg={"red.500"}
              rounded={"50px"}
              w={"55px"}
              h={"55px"}
              textAlign="center"
            >
              <CloseIcon boxSize={"20px"} color={"white"} />
            </Flex>
          </Box>
          <Heading color={"red.300"} textAlign={"center"} mt={6} mb={2}>
            Administratur introuvable
          </Heading>
          <Text color={"gray.500"}>
            L'administrateur que vous voulez consulter n'existe.
          </Text>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxW={"5xl"}>
      <Box mb="4">
        <PageHeading title={params.fullname || "Ajouter Collaborateur"} />
      </Box>
      <CollaborateurForm
        collaborateur={collaborateur}
        params={params}
        permissions={permissions}
      />
    </Container>
  );
}

const CollaborateurForm = ({
  collaborateur,
  params,
  permissions,
}: ICollabForm) => {
  const methods = useForm<any>({
    defaultValues: {
      fullname: params.fullname ? collaborateur.fullname : "",
      email: params.fullname ? collaborateur.email : "",
      password: "",
      role: params.fullname ? collaborateur.role : "",
      permissions: params.fullname
        ? collaborateur.permissions.map((x: any) => x._id)
        : [],
    },
  });

  const input = methods.watch("role");
  const permissionSections = [
    "users",
    "admin",
    "universities",
    "specialities",
    "subjects",
    "resources",
    "exams",
    "series",
    "remarques",
    "questions",
    "annonce",
    "pack-orders",
    "dashboard",
  ];
  const toast = useToast();
  const history = useHistory();

  const onSubmit = (data: any) => {
    if (params.fullname) {
      // edit
      if (data.password === "") {
        delete data.password;
      }
      mutateUpdate(data);
    } else {
      // add
      mutateAsync(data);
    }
  };

  const { mutateAsync: mutateUpdate, isLoading: isLoadingUpdate } = useMutation(
    updateAdmin,
    {
      onSuccess: () => {
        MyToast({ toast, status: "success", title: "Collaborateur modifié." });
        history.goBack();
      },
      onError: (err: any) => {
        MyToast({
          toast,
          description: err.response.data.message,
          status: "warning",
          title: "Impossible",
        });
      },
    }
  );
  const { mutateAsync, isLoading } = useMutation(createAdmin, {
    onSuccess: () => {
      toast({
        title: "Nouveau collaborateur crée.",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "bottom-right",
      });
      history.goBack();
    },
    onError: (err: any) => {
      Toast({
        toast,
        description: err.response.data.message,
        status: "warning",
        title: "Impossible",
      });
    },
  });

  const formFields: IInputField[] = [
    {
      type: "text",
      name: "fullname",
      placeholder: "Nom et prénom",
      icon: <AvatarIcon />,
      rules: {
        required: "Veuillez saisir le nom et prénom",
      },
    },
    {
      type: "text",
      name: "email",
      placeholder: "Adresse email",
      icon: <EmailIcon />,
      rules: {
        required: "Veuillez saisir l'adresse email",
      },
    },
    {
      type: "password",
      name: "password",
      placeholder: "Mot de passe",
      icon: <KeyIcon />,
      rules: {
        required: params.fullname ? false : "Veuillez saisir un mot de passe",
      },
    },
    {
      type: "select",
      name: "role",
      placeholder: "Rôle",
      options: [
        {
          id: 1,
          label: "Administrateur",
          value: "admin",
        },
        {
          id: 2,
          label: "Collaborateur",
          value: "collaborateur",
        },
      ],
      rules: {
        required: "Veuillez sélectionner le rôle",
      },
    },
  ];
  const bgBoxColor = useColorModeValue("lightGrey", "blue-dark");

  return (
    <FormProvider {...methods}>
      <Stack as="form" onSubmit={methods.handleSubmit(onSubmit)}>
        <Stack bg={bgBoxColor} p="8" borderRadius={"1rem"} spacing={4}>
          {formFields.map((field: IInputField) => (
            <Field
              value={field.name === "role" ? methods.getValues("role") : ""}
              {...field}
              bg="darkGrey"
              key={field.name}
            />
          ))}
          {input === "collaborateur" && (
            <Box bg={bgBoxColor}>
              {permissionSections.map((section: string, i: number) => (
                <PermissionBlock
                  permissions={permissions}
                  section={section}
                  key={i}
                />
              ))}
            </Box>
          )}
        </Stack>
        <HStack spacing={4} justify={"flex-end"} my="4" alignItems={"stretch"}>
          <Button
            variant={"secondary"}
            w="10rem"
            type="reset"
            onClick={() => history.goBack()}
          >
            Annuler
          </Button>
          <Button
            type="submit"
            variant={"primary"}
            disabled={isLoading}
            w="10rem"
            height={"auto"}
          >
            {isLoading || isLoadingUpdate ? (
              <Spinner size={"lg"} color="gold-yellow" />
            ) : params.fullname ? (
              "Modifier"
            ) : (
              "Créer"
            )}
          </Button>
        </HStack>
      </Stack>
    </FormProvider>
  );
};
