import { MouseEvent, useEffect, useRef, useState } from "react";
import {
  useTable,
  usePagination,
  useSortBy,
  useFilters,
  Column,
  Cell,
  Row,
  HeaderGroup,
} from "react-table";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  useDisclosure,
  useToast,
  Button,
  HStack,
  Tag,
  TagLeftIcon,
  Text,
  VStack,
  Tooltip,
  IconButton,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogCloseButton,
  AlertDialogBody,
  AlertDialogFooter,
} from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  getPackOrders,
  updatePackOrderStatus,
} from "../../../services/PackOrders/services";
import PackOrderModal from "./PackOrderModal";
import { useHistory, useLocation } from "react-router";
import queryString from "query-string";
import MyToast from "../../../components/Toast/Toast";
import { FiFastForward, FiRewind } from "react-icons/fi";
import { getStatusTag, getVoucherStatus } from "./constants";
import VoucherTag from "./components/VoucherTag";
import moment from "moment";
import { useSidebar } from "../../../context/SidebarContext";
import { objectToQueryString } from "../../../services/Remarques/services";
import { LiaStoreAltSolid, LiaShippingFastSolid } from "react-icons/lia";
import OrderDetailsPdf from "./components/OrderDetailsPdf";
import { useAuthProvider } from "../../../auth-provider/AuthProvider";
import { IoIosCheckmarkCircle } from "react-icons/io";

interface TableProps {
  columns: Column<any>[];
  data: any[];
  loading: boolean;
  pageCount: number;
  onRowClick: (x: any) => void;
  setCurrentPage: (page: number) => void;
  currentPage: number;
  handleSort: (columnId: string, isDesc: boolean) => void;
  role: string;
}

const TableComponent = () => {
  const [order, setOrder] = useState({});
  const {
    state: { filter },
  } = useSidebar();
  const history = useHistory();
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState<number>(
    parseInt(queryString.parse(location.search).page as string, 10) || 1
  );
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const { data: auth } = useAuthProvider();

  const { data, isLoading } = useQuery(
    ["pack-orders", String(currentPage), ...Object.entries(filter)],
    () => getPackOrders({ currentPage, filter }),
    {
      refetchOnWindowFocus: false,
      onSuccess: () => history.push(`?page=${currentPage}`),
      onError: (err: any) => {
        MyToast({
          toast,
          description: err.response.data.message,
          status: "error",
          title: "Erreur",
        });
      },
      enabled: Boolean(currentPage !== 0),
    }
  );

  const onRowClick = (row: any) => {
    setOrder(row);
    onOpen();
  };

  useEffect(() => {
    if (objectToQueryString(filter)) {
      setCurrentPage(1);
    }
  }, [filter]);

  const handleSort = (columnId: string, isDesc: boolean) => {
    console.log({ columnId, isDesc });
  };

  return (
    <>
      <CustomTable
        columns={[
          {
            Header: "Référence",
            id: "reference",
            accessor: "reference",
            width: 0,
          },
          {
            Header: "Prénom",
            id: "firstName",
            accessor: "firstName",
            maxWidth: 70,
          },
          {
            Header: "Nom",
            id: "lastName",
            accessor: "lastName",
            maxWidth: 70,
          },
          { Header: "Contact", id: "email", accessor: "email", maxWidth: 120 },
          { Header: "Etat", id: "status", accessor: "status", maxWidth: 70 },
          ...(auth?.role === "admin"
            ? [
                {
                  Header: "Coupon",
                  id: "voucher",
                  accessor: "voucher",
                  maxWidth: 150,
                  width: "50px",
                },
              ]
            : []),
          {
            Header: "Boutique",
            id: "pickUpStore",
            accessor: "pickUpStore",
            maxWidth: 70,
          },
          {
            Header: "Date",
            id: "createdAt",
            accessor: "createdAt",
            width: 0,
          },
          {
            Header: "",
            id: "export",
            accessor: "export",
            maxWidth: 50,
          },
        ]}
        loading={isLoading}
        data={data?.results}
        pageCount={data?.totalPages}
        onRowClick={onRowClick}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        handleSort={handleSort}
        role={auth?.role || ""}
      />
      {data && (
        <PackOrderModal
          key={data}
          order={order}
          isOpen={isOpen}
          onClose={onClose}
        />
      )}
    </>
  );
};

const CustomTable = ({
  columns,
  data = [],
  loading,
  pageCount,
  onRowClick,
  setCurrentPage,
  currentPage,
  handleSort,
  role,
}: TableProps) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
  }: any = useTable<any>(
    {
      columns,
      data,
      // @ts-ignore
      manualPagination: true,
      pageCount: pageCount,
      manualSortBy: true,
      manualFilters: true,
    },
    useFilters,
    useSortBy,
    usePagination
  );
  const [selectedOrder, setselectedOrder] = useState<any>(null);
  const [actionType, setActionType] = useState<
    "setAsConfirmed" | "setActiveableVoucher"
  >("setAsConfirmed");
  const {
    isOpen: isDialogOpen,
    onOpen: onDialogOpen,
    onClose: onDialogClose,
  } = useDisclosure();
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: handleUpdateStatus, isLoading } = useMutation(
    updatePackOrderStatus,
    {
      onSuccess: () => {
        MyToast({
          toast,
          status: "success",
          title: "Commande modifiée.",
          duration: 5000,
        });
        queryClient.invalidateQueries(["pack-orders"]);
        onDialogClose();
      },
      onError: (err: any) => {
        MyToast({
          toast,
          description: err.response.data.message,
          status: "error",
          title: "Erreur",
        });
      },
    }
  );

  const setPackOrderAsConfirmedAndDelivered = (
    e: MouseEvent<HTMLButtonElement>,
    packOrder: any,
    action: "setAsConfirmed" | "setActiveableVoucher"
  ) => {
    e.stopPropagation();
    setselectedOrder(packOrder);
    setActionType(action);
    onDialogOpen();
  };

  const handleConfirmDialog = () => {
    if (actionType === "setAsConfirmed") {
      handleUpdateStatus({
        status: "confirmed",
        orderId: selectedOrder?._id || "",
      });
    } else if (actionType === "setActiveableVoucher") {
      handleUpdateStatus({
        status: "activated_voucher",
        orderId: selectedOrder?._id || "",
      });
    }
  };

  return (
    <>
      <TableContainer rounded={"xl"}>
        <Table {...getTableProps()} variant="simple">
          <Thead bg="blue-light">
            {headerGroups.map((headerGroup: HeaderGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    onClick={() => handleSort(column.id, !column.isSortedDesc)}
                    maxWidth={column.maxWidth}
                    width={column.width}
                    color="white"
                    cursor={"pointer"}
                  >
                    {column.render("Header")}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map(
              (
                row: Row & {
                  original: {
                    status: string;
                    voucherStatus: string;
                    reference: string;
                    voucher: string;
                    withSpirale: boolean;
                    phone: string;
                  };
                }
              ) => {
                prepareRow(row);
                return (
                  <Tr
                    {...row.getRowProps()}
                    onClick={() => onRowClick && onRowClick(row.original)}
                    bg="gray.50"
                    _hover={{ background: "gray.100" }}
                    cursor="pointer"
                  >
                    {row.cells.map((cell: Cell) => (
                      <Td
                        {...cell.getCellProps()}
                        maxWidth={cell.column.maxWidth}
                        overflow="hidden"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                        title={
                          cell.column.id === "voucher"
                            ? getVoucherStatus(row.original.voucherStatus)
                            : cell.column.id === "status"
                            ? getStatusTag(row.original?.status).label
                            : cell.value
                        }
                      >
                        {cell.column.id === "status" ? (
                          <Tag
                            size="md"
                            colorScheme={
                              getStatusTag(row.original?.status).colorScheme
                            }
                            rounded={"xl"}
                          >
                            <Text as="span" isTruncated>
                              {getStatusTag(row.original?.status).label}
                            </Text>
                          </Tag>
                        ) : cell.column.id === "voucher" && cell.value ? (
                          <VoucherTag
                            voucher={cell.value.replace(/(.{4})(?!$)/g, "$1-")}
                            voucherStatus={row.original.voucherStatus}
                          />
                        ) : cell.column.id === "createdAt" ? (
                          moment(cell.value).format("DD/MM/YYYY")
                        ) : cell.column.id === "export" ? (
                          <HStack>
                            {row.original.voucher && (
                              <OrderDetailsPdf
                                data={data.find(
                                  (el) =>
                                    el.reference === row.original.reference
                                )}
                              />
                            )}
                            {role === "admin" ? (
                              <Tooltip
                                label={
                                  row.original.status === "activated_voucher"
                                    ? "Code coupon activé"
                                    : "Activer le coupon"
                                }
                                hasArrow
                              >
                                <IconButton
                                  isDisabled={
                                    row.original.status !== "confirmed"
                                  }
                                  aria-label="Activer le coupon"
                                  icon={
                                    <IoIosCheckmarkCircle size={"1.2rem"} />
                                  }
                                  bg={"light-green"}
                                  color="white"
                                  onClick={(e: MouseEvent<HTMLButtonElement>) =>
                                    setPackOrderAsConfirmedAndDelivered(
                                      e,
                                      row.original,
                                      "setActiveableVoucher"
                                    )
                                  }
                                />
                              </Tooltip>
                            ) : (
                              <Tooltip
                                label="Marquer comme confirmée et livrée"
                                hasArrow
                              >
                                <IconButton
                                  isDisabled={row.original.status !== "new"}
                                  _hover={{}}
                                  // colorScheme={"messenger"}
                                  bg="blue-light"
                                  color="white"
                                  aria-label="Marquer comme confirmée et livrée"
                                  icon={<IoIosCheckmarkCircle />}
                                  onClick={(e: MouseEvent<HTMLButtonElement>) =>
                                    setPackOrderAsConfirmedAndDelivered(
                                      e,
                                      row.original,
                                      "setAsConfirmed"
                                    )
                                  }
                                />
                              </Tooltip>
                            )}
                          </HStack>
                        ) : cell.column.id === "email" ? (
                          <VStack spacing={0} alignItems="flex-start">
                            <Text>{cell.value}</Text>
                            <Text color="gray.500">{row.original.phone}</Text>
                          </VStack>
                        ) : (
                          cell.render("Cell")
                        )}
                      </Td>
                    ))}
                  </Tr>
                );
              }
            )}
          </Tbody>
        </Table>
      </TableContainer>

      <HStack my="2">
        <Button
          isDisabled={currentPage === 1 || loading}
          onClick={() => setCurrentPage(currentPage - 1)}
          bg={"gray.100"}
        >
          <FiRewind />
        </Button>
        <Button
          isDisabled={currentPage === pageCount || loading}
          onClick={() => setCurrentPage(currentPage + 1)}
          bg={"gray.100"}
        >
          <FiFastForward />
        </Button>
        <span>
          Page{" "}
          <strong>
            {currentPage} sur {pageCount}
          </strong>{" "}
        </span>
      </HStack>
      <ConfirmDialog
        order={selectedOrder}
        isOpen={isDialogOpen}
        onConfirm={handleConfirmDialog}
        actionType={actionType}
        onClose={onDialogClose}
        isLoading={isLoading}
      />
    </>
  );
};

export default TableComponent;

interface ConfirmDialogProps {
  isOpen: boolean;
  onClose: () => void;
  order: {
    _id: string;
    reference: string;
    firstName: string;
    lastName: string;
  } | null;
  onConfirm: () => void;
  actionType: "setAsConfirmed" | "setActiveableVoucher";
  isLoading: boolean;
}
const ConfirmDialog = ({
  isOpen,
  onClose,
  onConfirm,
  order,
  actionType,
  isLoading,
}: ConfirmDialogProps) => {
  const cancelRef = useRef<any>();

  return (
    <AlertDialog
      leastDestructiveRef={cancelRef}
      motionPreset="slideInBottom"
      onClose={onClose}
      isOpen={isOpen}
      isCentered
    >
      <AlertDialogOverlay />

      <AlertDialogContent>
        <AlertDialogHeader>Confirmation requise</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          <Text>
            {actionType === "setAsConfirmed"
              ? "Êtes-vous sûr de vouloir marquer cette commande comme confirmée et livrée ?"
              : "Êtes-vous sûr de vouloir activer le code coupon de cette commande ?"}
          </Text>
          <Text>{order?.reference}</Text>
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button onClick={onClose} ref={cancelRef} isDisabled={isLoading}>
            Non
          </Button>
          <Button
            colorScheme="red"
            ml={3}
            onClick={onConfirm}
            isLoading={isLoading}
          >
            Oui
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
