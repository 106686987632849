import { CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Flex,
  FormLabel,
  Heading,
  HStack,
  IconButton,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Stack,
  useColorModeValue,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { createRef, Suspense, useCallback, useRef, useState, lazy } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import DynamicInputList from "../../../components/DynamicInputList/DynamicInputList";
import Editor from "../../../components/Editor/Editor";
import ErrorBoundary from "../../../components/ErrorBoundary/ErrorBoundary";
import Popup from "../../../components/Modal/Popup";
import QuestionBreadCrumb from "../../../components/QuestionBreadCrumb/QuestionBreadCrumb";
import QuestionFormType from "../../../components/QuestionFormType/QuestionFormType";
import MyToast from "../../../components/Toast/Toast";
import useQueryParams from "../../../hooks/useQueryParams";
import SidebarLayout from "../../../layouts/SidebarLayout";
import {
  createOptions,
  updateManyOptions,
} from "../../../services/options/services";
import {
  changeOrderQuestion,
  createQuestion,
  getOneQuestion,
  updateManyQuestions,
} from "../../../services/questions/services";
import { getSpecialities } from "../../../services/specialities/services";
import { getSubjects } from "../../../services/subjects/services";
import axiosInstance from "../../../utils/axiosInstance";
import { debounce } from "../../../utils/debounce";
// const Editor = lazy(() => import('../../../components/Editor/Editor'));

export default function Question2Form({ path }: { path: string }) {
  const [questions, setQuestions] = useState<any>([
    {
      data: "",
      notation: "",
      options: [
        {
          value: "",
          isCorrect: false,
          justification: "",
          withJustification: false,
        },
        {
          value: "",
          isCorrect: false,
          justification: "",
          withJustification: false,
        },
        {
          value: "",
          isCorrect: false,
          justification: "",
          withJustification: false,
        },
        {
          value: "",
          isCorrect: false,
          justification: "",
          withJustification: false,
        },
        {
          value: "",
          isCorrect: false,
          justification: "",
          withJustification: false,
        },
      ],
      ref: createRef(),
    },
  ]);
  const methods = useForm({
    defaultValues: {
      speciality: "",
      subject: "",
    },
  });
  const params = useParams<{ questionId: string | undefined }>();
  const history = useHistory();
  const toast = useToast();
  const containerRef = useRef<any>();

  //   edit question, get the single question
  const {
    data: question,
    isLoading: loadingQuestion,
    isError,
    refetch,
  } = useQuery(
    `question-${params.questionId}`,
    () => getOneQuestion(params.questionId),
    {
      enabled: Boolean(params.questionId),
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess: (res: any) =>
        setQuestions(
          [
            { ...res, ref: createRef() },
            ...res.children.map((x: any) => ({ ...x, ref: createRef() })),
          ].map((el: any) => ({
            ...el,
            options: el.options.map((opt: any) => ({
              ...opt,
              withJustification: opt.justification ? true : false,
            })),
          }))
        ),
      onError: (err: any) => {
        MyToast({
          toast,
          description: err.response.data.message,
          status: "warning",
          title: "Impossible",
        });
      },
    }
  );

  const queryString = useQueryParams();
  const [questionOrder, setQuestionOrder] = useState(
    Number(queryString.get("t")) ? Number(queryString.get("t")) + 1 : 1
  );

  const handleSave = useCallback(
    async (action: string) => {
      let optionss: any = [];
      const allSavedData: any = [];
      for (let i = questions.length - 1; i >= 0; i--) {
        const question: any = questions[i];
        const savedData = await question.ref.current.save();
        allSavedData.push(savedData);
        setQuestions(
          questions.map((el: any, index: number) =>
            i === index ? { ...el, data: savedData } : el
          )
        );
        if (history.location.pathname.includes("modifier")) {
          let updatedOptions = await updateManyOptionsMutation(
            question.options
          );
          optionss = [...optionss, updatedOptions];
        } else {
          let xx = await createOptionsMutation({
            options: question.options.filter((opt: any) => opt.value),
            action,
            index: i,
          });
          optionss = [...optionss, xx.options];
        }
      }
      if (history.location.pathname.includes("modifier")) {
        updateQuestionMutation(
          questions.reverse().map((el: any, i: number) =>
            methods.getValues("speciality") && methods.getValues("subject")
              ? {
                  ...el,
                  value: allSavedData[i],
                  options: optionss[i],
                  speciality: methods.getValues("speciality"),
                  subject: methods.getValues("subject"),
                }
              : {
                  ...el,
                  value: allSavedData[i],
                  options: optionss[i],
                }
          )
        );
      } else {
        createQuestionMutation(
          questions.map((el: any, i: number) =>
            // questions.reverse().map((el: any, i: number) =>
            methods.getValues("speciality") && methods.getValues("subject")
              ? {
                  // create question for exam
                  ...el,
                  value: allSavedData[i],
                  qcm: queryString.get("q") || null,
                  speciality: methods.getValues("speciality"),
                  subject: methods.getValues("subject"),
                  options: optionss[i],
                  order: Number(queryString.get("t")) + 1,
                  notation: questions[questions.length - 1 - i]?.notation,
                  // order: questionOrder,
                }
              : {
                  ...el,
                  notation: questions[questions.length - 1 - i]?.notation,
                  value: allSavedData[i],
                  qcm: queryString.get("q") || null,
                  options: optionss[i],
                  order: Number(queryString.get("t")) + 1,
                  // order: questionOrder,
                }
          )
        );
        if (action === "createAndReset") {
          const oldRef = questions[0].ref;
          const x = await questions[0].ref.current.clear();
          containerRef.current.scrollIntoView();
          setQuestions([
            {
              data: x,
              notation: "",
              options: [
                {
                  value: "",
                  isCorrect: false,
                  justification: "",
                  withJustification: false,
                },
                {
                  value: "",
                  isCorrect: false,
                  justification: "",
                  withJustification: false,
                },
                {
                  value: "",
                  isCorrect: false,
                  justification: "",
                  withJustification: false,
                },
                {
                  value: "",
                  isCorrect: false,
                  justification: "",
                  withJustification: false,
                },
                {
                  value: "",
                  isCorrect: false,
                  justification: "",
                  withJustification: false,
                },
              ],
              ref: oldRef,
            },
          ]);
          queryString.set("t", String(Number(queryString.get("t")) + 1));
        } else {
          history.goBack();
        }
      }
    },
    [questions]
  );

  const flexBg = useColorModeValue("white", "dark-blue");

  const {
    mutateAsync: updateManyOptionsMutation,
    isLoading: updateManyOptionsLoading,
  } = useMutation(updateManyOptions, {
    onError: (err: any) => {
      MyToast({
        toast,
        description: err.response.data.message,
        status: "warning",
        title: "Impossible",
      });
    },
  });

  const { mutateAsync: updateQuestionMutation } = useMutation(
    updateManyQuestions,
    {
      onSuccess: async () => {
        MyToast({ toast, status: "success", title: "Question modifiée." });
        history.goBack();
      },
      onError: (err: any) => {
        MyToast({
          toast,
          description: err.response.data.message,
          status: "warning",
          title: "Impossible",
        });
      },
    }
  );

  //   Create Options
  const {
    mutateAsync: createOptionsMutation,
    isLoading: createOptionsLoading,
  } = useMutation(createOptions, {
    onError: (err: any) => {
      MyToast({
        toast,
        description: err.response.data.message,
        status: "warning",
        title: "Impossible",
      });
    },
  });

  const {
    mutateAsync: createQuestionMutation,
    isLoading: createQuestionLoading,
  } = useMutation(createQuestion, {
    onSuccess: () => {
      MyToast({ toast, status: "success", title: "Question crée." });
    },
    onError: (err: any) => {
      MyToast({
        toast,
        description: err.response.data.message,
        status: "warning",
        title: "Impossible",
      });
    },
  });

  const uploadImage = async (file: any) => {
    const formData = new FormData();
    formData.append("image", file);
    const response = await axiosInstance({
      method: "post",
      url: "/images/question/upload",
      data: formData,
    });
    return response.data;
  };

  const handleChange = (e: any, index: number, questionIndex: number) => {
    const list: any[] = questions[questionIndex].options;
    list[index][e.target.name] = e.target.value || e.target.checked;
    setQuestions(
      questions.map((el: any, i: number) =>
        i === questionIndex ? { ...el, options: list } : el
      )
    );
    if (e.target.name === "withJustification" && !e.target.checked) {
      list[index]["justification"] = "";
      setQuestions(
        questions.map((el: any, i: number) =>
          i === questionIndex ? { ...el, options: list } : el
        )
      );
    }
  };

  const handleAddInput = (questionIndex: number) => {
    setQuestions(
      questions.map((el: any, index: number) =>
        index === questionIndex
          ? {
              ...el,
              options: [
                ...el.options,
                {
                  value: "",
                  isCorrect: false,
                  justification: "",
                  withJustification: false,
                },
              ],
            }
          : el
      )
    );
  };

  const handleRemoveInput = (index: number, questionIndex: number) => {
    const list: any[] = questions[questionIndex].options;
    list.splice(index, 1);
    setQuestions(
      questions.map((el: any, i: number) =>
        i === questionIndex ? { ...el, options: list } : el
      )
    );
  };

  const addQuestion = () => {
    setQuestions([
      ...questions,
      {
        data: "",
        notation: "",
        options: [
          {
            value: "",
            isCorrect: false,
            justification: "",
            withJustification: false,
          },
          {
            value: "",
            isCorrect: false,
            justification: "",
            withJustification: false,
          },
          {
            value: "",
            isCorrect: false,
            justification: "",
            withJustification: false,
          },
          {
            value: "",
            isCorrect: false,
            justification: "",
            withJustification: false,
          },
          {
            value: "",
            isCorrect: false,
            justification: "",
            withJustification: false,
          },
        ],
        ref: createRef(),
      },
    ]);
  };
  const deleteQuestion = (index: number) => {
    const _questions = [...questions];
    _questions.splice(index, 1);
    setQuestions(_questions);
  };

  const { mutateAsync: changeOrder, isLoading: changeOrderLoading } =
    useMutation(changeOrderQuestion, {
      onSuccess: async (res) => {
        MyToast({
          toast,
          status: "success",
          title: "L'ordre de question a été changé.",
        });
      },
      onError: (err: any) => {
        MyToast({
          toast,
          description: err.response.data.message,
          status: "warning",
          title: "Impossible",
        });
      },
    });

  const handleChangeOrder = (_: string, num: number) => {
    changeOrder({ order: num, id: question._id });
  };

  const {
    data: subjects,
    isLoading: subjectsLoading,
    isError: subjectError,
  } = useQuery("subjects", () => getSubjects(), {
    refetchOnWindowFocus: false,
    retry: false,
    enabled: Boolean(queryString.get("type") === "examen"),
  });

  const {
    data: specialities,
    isLoading: specialitiesLoading,
    isError: specialitiesError,
  } = useQuery("specialities", () => getSpecialities(), {
    refetchOnWindowFocus: false,
    retry: false,
    enabled: Boolean(queryString.get("type") === "examen"),
  });

  const handleChangeNotation = (e: any, questionIndex: number) => {
    const list: any = questions[questionIndex];
    list.notation = e.target.value;
    setQuestions(
      questions.map((el: any, i: number) => (i === questionIndex ? list : el))
    );
    // if (e.target.name === "withJustification" && !e.target.checked) {
    //   list[index]["justification"] = "";
    //   setQuestions(
    //     questions.map((el: any, i: number) =>
    //       i === questionIndex ? { ...el, options: list } : el
    //     )
    //   );
    // }
  };

  if (loadingQuestion || subjectsLoading || specialitiesLoading) {
    return <p>Loading...</p>;
  }
  if (isError) {
    return <p>Erreur!</p>;
  }
  return (
    <SidebarLayout>
      <Box ref={containerRef}>
        {question && queryString.get("type") !== "examen" && (
          <>
            <HStack spacing={4} mb="3">
              <QuestionBreadCrumb question={question} />
              <Popup question={question} refetch={refetch} />
              <NumberInput
                defaultValue={question.order || 1}
                min={1}
                max={question.questionsCount}
                width="24"
                onChange={debounce(handleChangeOrder, 1000)}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </HStack>
            <Divider mb="4" />
          </>
        )}
        {queryString.get("type") === "examen" && (
          <fieldset
            style={{
              border: "1px solid rgba(0,0,0,.1)",
              borderRadius: "0.7rem",
              boxShadow: "0px 5px 15px rgba(0,0,0,0.1)",
              padding: "1rem",
              marginBottom: "25px",
            }}
          >
            <legend style={{ padding: "0px 0.5rem", fontWeight: "bold" }}>
              Informations sur la question
            </legend>
            <HStack align={"flex-end"}>
              {queryString.get("type") === "examen" && (
                <Box flexGrow={1}>
                  <QuestionFormType
                    specialities={specialities.filter(
                      (el: any) => el.jour === queryString.get("jour")
                    )}
                    subjects={subjects}
                    methods={methods}
                    question={question}
                  />
                </Box>
              )}
              {/* {!question && ( */}
              <VStack align={"flex-start"}>
                <FormLabel mb="1">Rang</FormLabel>
                <NumberInput
                  defaultValue={
                    Number(queryString.get("t"))
                      ? Number(queryString.get("t")) + 1
                      : question?.order
                  }
                  min={1}
                  max={
                    Number(queryString.get("t"))
                      ? Number(queryString.get("t")) + 1
                      : question?.questionsCount
                  }
                  width="24"
                  // onChange={(_: string, num: number) => setQuestionOrder(num)}
                  onChange={debounce(handleChangeOrder, 1000)}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </VStack>
              {/* )} */}
            </HStack>
          </fieldset>
        )}
        {!question && queryString.get("type") !== "examen" && (
          <HStack align={"center"} spacing={2} mb="3">
            <FormLabel mb="1">Rang</FormLabel>
            <NumberInput
              value={Number(queryString.get("t")) + 1}
              min={1}
              max={Number(queryString.get("t")) + 1}
              width="24"
              onChange={(_: string, num: number) => setQuestionOrder(num)}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </HStack>
        )}
        {questions.map((el: any, i: number) => (
          <Box key={i}>
            <HStack justify={"space-between"} align="flex-start">
              <VStack align={"flex-start"}>
                <HStack>
                  <Heading size={"md"}>Question N°{i + 1}</Heading>

                  {questions.length > 1 && (
                    <IconButton
                      aria-label="effacer cette question"
                      icon={<CloseIcon />}
                      onClick={() => deleteQuestion(i)}
                      size="sm"
                    />
                  )}
                </HStack>
                <Select
                  onChange={(e: any) => handleChangeNotation(e, i)}
                  placeholder="Sélectionner le système de notation"
                  defaultValue={el?.notation}
                >
                  <option value="divide_by_correct_answers">
                    Diviser sur les réponses correctes
                  </option>
                  <option value="all_or_nothing">Tout ou rien (0, 1)</option>
                  <option value="greater_or_equal_to_half_correct_answers">
                    Réponse partiellement correcte (0, 0.5, 1)
                  </option>
                </Select>
              </VStack>
              {/* {questions.length > 1 && i === questions.length - 1 && (
              <Button
                variant={"outline"}
                colorScheme="blue"
                onClick={addQuestion}
                size="sm"
              >
                Ajouter une autre question
              </Button>
            )}
            {questions.length === 1 && (
              <Button
                variant={"outline"}
                colorScheme="blue"
                onClick={addQuestion}
                size="sm"
              >
                Cas clinique ? ajouter une autre question
              </Button>
            )} */}
            </HStack>
            <Suspense fallback={<p>Chargement...</p>}>
              <Editor
                placeholder={"Write something"}
                defaultValue={params.questionId ? el.value : {}}
                editorCore={el.ref}
                uploadImage={uploadImage}
              />
            </Suspense>
            <Stack spacing={6} mt="6" mb="16">
              <ErrorBoundary>
                <DynamicInputList
                  inputList={el.options}
                  handleChange={(e: any, index: number) =>
                    handleChange(e, index, i)
                  }
                  handleAddInput={() => handleAddInput(i)}
                  handleRemoveInput={(index: number) =>
                    handleRemoveInput(index, i)
                  }
                />
              </ErrorBoundary>
            </Stack>
          </Box>
        ))}
        <Flex
          h="16"
          bg={flexBg}
          w="100%"
          position={"fixed"}
          left="0px"
          bottom="0px"
          boxShadow={"1px 5px 10px rgba(0, 0, 0, 0.5)"}
          justify="space-between"
          align={"center"}
          pr="8"
          zIndex={1}
        >
          <Box ml={{ base: "60", "2xl": "80" }} px={{ base: "4", "2xl": "8" }}>
            <Button
              variant={"outline"}
              colorScheme="blue"
              onClick={addQuestion}
              height={{ base: "2.5rem", "2xl": "3rem" }}
              // ml="80"
            >
              Ajouter une autre question
            </Button>
          </Box>
          <ButtonGroup variant="outline" spacing="6">
            {!history.location.pathname.includes("modifier") && (
              <Button
                variant={"ghost"}
                disabled={
                  createQuestionLoading ||
                  updateManyOptionsLoading ||
                  changeOrderLoading
                }
                onClick={() => history.goBack()}
                height={{ base: "2.5rem", "2xl": "3rem" }}
              >
                Quitter
              </Button>
            )}
            <Button
              variant={
                history.location.pathname.includes("modifier")
                  ? "secondary"
                  : "primary"
              }
              isLoading={createQuestionLoading || createOptionsLoading}
              loadingText="Sauvegarde en cours"
              onClick={() => {
                history.location.pathname.includes("modifier")
                  ? history.goBack()
                  : handleSave("createAndReset");
              }}
            >
              {history.location.pathname.includes("modifier")
                ? "Annuler"
                : "Sauvegarder puis créer un autre"}
            </Button>
            <Button
              variant={
                history.location.pathname.includes("modifier")
                  ? "primary"
                  : "secondary"
              }
              _hover={{ bg: "#eaecff" }}
              isLoading={
                createQuestionLoading ||
                updateManyOptionsLoading ||
                changeOrderLoading ||
                createOptionsLoading
              }
              onClick={() => handleSave("createAndGoBack")}
              loadingText="Sauvegarde en cours"
            >
              Sauvegarder
            </Button>
          </ButtonGroup>
        </Flex>
      </Box>
    </SidebarLayout>
  );
}
