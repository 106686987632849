import { useRef } from "react";
import {
  useDisclosure,
  useToast,
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogCloseButton,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "react-query";
import { removeAnnounce } from "../../../services/annonces/services";
import MyToast from "../../../components/Toast/Toast";

function RemoveAnnounceDialog({ announceId }: { announceId: string }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef<any>();
  const toast = useToast();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(removeAnnounce, {
    onSuccess: async (response) => {
      MyToast({
        toast,
        status: "success",
        title: `Annonce est supprimée`,
      });
      onClose();
      queryClient.invalidateQueries(`announces`);
    },
    onError: (err: any) => {
      MyToast({
        toast,
        description: err.response.data.message,
        status: "warning",
        title: "Impossible",
      });
    },
  });

  return (
    <>
      <Button size="xs" onClick={onOpen} variant="primary" height="2rem">
        Supprimer
      </Button>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Supprimer cette annonce?</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            Êtes-vous sûr de vouloir supprimer cette annonce ?
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Non
            </Button>
            <Button
              colorScheme="red"
              ml={3}
              onClick={() => mutateAsync(announceId)}
              isLoading={isLoading}
            >
              Oui
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}

export default RemoveAnnounceDialog;
