import { AddIcon } from "@chakra-ui/icons";
import {
  Stack,
  HStack,
  Textarea,
  Checkbox,
  IconButton,
  useColorModeValue,
  Flex,
  Text,
  VStack,
  Divider,
} from "@chakra-ui/react";
import { FaTimes } from "react-icons/fa";
import alphabet from "../../utils/alphabet.json";

export default function DynamicInputList({
  inputList,
  handleChange,
  handleAddInput,
  handleRemoveInput,
}: any) {
  const textAreaBg = useColorModeValue("white", "dark-blue-dark");
  const stackBg = useColorModeValue("gray.100", "block-dark");
  return inputList.map((el: any, i: number) => (
    <Stack key={i} bg={stackBg} p="4" rounded={"lg"} boxShadow="sm" spacing={4}>
      <HStack align={"flex-start"}>
        <Flex
          align="center"
          justify={"center"}
          h="10"
          w="10"
          px={2}
          rounded={"md"}
          bg={el.isCorrect ? "green.50" : "gray.50"}
          border="1px solid"
          borderColor={el.isCorrect ? "green.200" : "gray.100"}
        >
          <Text textAlign="center">{alphabet[i]}</Text>
        </Flex>
        <Textarea
          name="value"
          onChange={(e: any) => handleChange(e, i)}
          placeholder={`Option numéro ${i + 1}`}
          value={el.value}
          resize="none"
          bg={textAreaBg}
        />
        <VStack spacing={2}>
          {inputList.length !== 1 && (
            <IconButton
              aria-label="ajouter option"
              icon={<FaTimes color="white" />}
              onClick={() => handleRemoveInput(i)}
              bg={"red.300"}
              _hover={{ bg: "red.400" }}
              size="sm"
            />
          )}
          {inputList.length - 1 === i && (
            <IconButton
              aria-label="ajouter option"
              icon={<AddIcon />}
              onClick={handleAddInput}
              size="sm"
              bg={"gray.200"}
              _hover={{ bg: "gray.300" }}
            />
          )}
        </VStack>
      </HStack>
      <HStack align={"flex-start"} spacing="4">
        <Checkbox
          colorScheme="whatsapp"
          isChecked={el.isCorrect}
          name="isCorrect"
          onChange={(e: any) => handleChange(e, i)}
        >
          Correcte
        </Checkbox>
        <Divider
          orientation="vertical"
          height={"20px"}
          borderWidth="2px"
          rounded={"full"}
          borderColor="dark-blue-light"
          opacity={0.3}
        />
        <Checkbox
          colorScheme="messenger"
          isChecked={el.withJustification}
          name="withJustification"
          onChange={(e: any) => handleChange(e, i)}
        >
          Avec Justification
        </Checkbox>
      </HStack>
      {/* {el.isCorrect && ( */}
      {el.withJustification && (
        <Textarea
          name="justification"
          onChange={(e: any) => handleChange(e, i)}
          placeholder={`Justification`}
          value={el.justification}
          resize="none"
          bg={textAreaBg}
        />
      )}
    </Stack>
  ));
}
