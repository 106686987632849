import {
  HStack,
  Heading,
  ButtonGroup,
  Button,
  GridItem,
  List,
  Stack,
  Spinner,
  ListItem,
  Grid,
  Text,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

interface Props {
  setQcmView: (x: string) => void;
  qcmView: string;
  mostDoneQcmsLoading: boolean;
  mostDoneQcms: any;
}

export default function MostDoneQcms({
  setQcmView,
  qcmView,
  mostDoneQcmsLoading,
  mostDoneQcms,
}: Props) {
  return (
    <>
      <HStack
        justifyContent={"space-between"}
        flexWrap="wrap"
        position={"sticky"}
        top="0px"
        w="full"
        bg="lightGrey"
      >
        <Heading as="h2" p="4" color={"blue-light"} fontSize="lg">
          Les plus travaillés
        </Heading>
        <ButtonGroup pr="4">
          <Button
            value="Série"
            onClick={(e: any) => setQcmView(e.target.value)}
            bg={qcmView === "Série" ? "blue-light" : "white"}
            color={qcmView === "Série" ? "white" : "black"}
            _hover={{}}
            size="sm"
          >
            Série
          </Button>
          <Button
            value="Examen"
            onClick={(e: any) => setQcmView(e.target.value)}
            bg={qcmView === "Examen" ? "blue-light" : "white"}
            color={qcmView === "Examen" ? "white" : "black"}
            _hover={{}}
            size="sm"
          >
            Examen
          </Button>
        </ButtonGroup>
      </HStack>

      <Grid
        px="4"
        gridTemplateColumns={
          qcmView === "Série" ? "1fr 1fr 0.5fr 0.25fr 0.35fr" : "repeat(3, 1fr)"
        }
        gap={2}
        alignItems="center"
        mb="2"
      >
        {qcmView === "Série" && (
          <>
            <GridItem>
              <Text fontWeight={"semibold"} fontSize="sm">
                Specialité
              </Text>
            </GridItem>
            <GridItem>
              <Text fontWeight={"semibold"} fontSize="sm">
                Sujet
              </Text>
            </GridItem>
          </>
        )}
        <GridItem>
          <Text fontWeight={"semibold"} fontSize="sm">
            Faculté
          </Text>
        </GridItem>
        <GridItem>
          <Text fontWeight={"semibold"} fontSize="sm">
            Année
          </Text>
        </GridItem>
        <GridItem>
          <Text fontWeight={"semibold"} fontSize="sm">
            Fois
          </Text>
        </GridItem>
      </Grid>
      <List spacing={0}>
        {mostDoneQcmsLoading ? (
          <Stack height="400px" align={"center"} justify="center">
            <Spinner color="gold-yellow" />
          </Stack>
        ) : (
          mostDoneQcms.map((qcm: any, index: number) => (
            <ListItem
              key={qcm._id}
              bg={index % 2 === 0 ? "darkGrey" : "lightGrey"}
              px="4"
              py="2"
            >
              <Link
                to={
                  qcmView === "Série"
                    ? `/series/${qcm?.speciality?.name}/${qcm?.subject?.name}/${qcm?._id}`
                    : `/examen-blanc/${qcm.year}/${qcm?.university.name}`
                }
              >
                <Grid
                  gridTemplateColumns={
                    qcmView === "Série"
                      ? "1fr 1fr 0.5fr 0.25fr 0.35fr"
                      : "repeat(3, 1fr)"
                  }
                  gap={2}
                  alignItems="center"
                >
                  {qcmView === "Série" && (
                    <>
                      <GridItem isTruncated>
                        <Text fontSize="sm" isTruncated>
                          {qcm?.speciality?.name ?? "---"}
                        </Text>
                      </GridItem>
                      <GridItem isTruncated>
                        <Text fontSize="sm" isTruncated>
                          {qcm?.subject?.name ?? "---"}
                        </Text>
                      </GridItem>
                    </>
                  )}
                  <GridItem isTruncated>
                    <Text fontSize="sm" isTruncated>
                      {qcm?.university.name}
                    </Text>
                  </GridItem>
                  <GridItem isTruncated>
                    <Text fontSize="sm" isTruncated>
                      {qcm.year}
                    </Text>
                  </GridItem>
                  <GridItem isTruncated>
                    <Text fontSize="sm" isTruncated>
                      {qcm.times_done}
                    </Text>
                  </GridItem>
                </Grid>
              </Link>
            </ListItem>
          ))
        )}
      </List>
    </>
  );
}
