import {
  Button,
  Grid,
  Heading,
  HStack,
  Icon,
  Spinner,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { FormProvider } from "react-hook-form";
import { IInputField } from "../../types";
import Dialog from "../Dialog/Dialog";
import Field from "../Field/Field";

export default function ProfileBlock({
  fields,
  title,
  block,
  icon,
  methods,
  onSubmit,
  updateAccountLoading,
  changePasswordLoading
}: any) {
  return (
    <Stack
      bg={useColorModeValue("lightGrey", "block-dark")}
      p={{ base: 4, "2xl": "8" }}
      borderRadius={"1rem"}
      mt="6"
      as="form"
      onSubmit={methods.handleSubmit((x: any) => onSubmit(x, block))}
    >
      <HStack spacing={4} align={"flex-end"}>
        <Icon as={icon} />
        <Heading
          fontSize={{ base: "lg", xl: "xl", "2xl": "2xl" }}
          color="blue-light"
        >
          {title}
        </Heading>
      </HStack>
      <FormProvider {...methods}>
        {fields.map((field: IInputField) => (
          <Grid
            templateColumns={
              block === "account"
                ? { base: "1fr", "2xl": "0.1fr 1fr" }
                : { base: "1fr", "2xl": "0.3fr 1fr" }
            }
            gap={{ base: 2, "2xl": 6 }}
            alignItems={"center"}
            key={field.name}
          >
            <Text>{field.placeholder}</Text>
            <Field
              value={field.name === "role" ? methods.getValues("role") : ""}
              {...field}
              bg="darkGrey"
              key={field.name}
            />
          </Grid>
        ))}
      </FormProvider>
      <Dialog
        question="Êtes-vous sûr?"
        description="Êtes-vous sûr de modifier les données de votre compte?"
        isLoading={updateAccountLoading || changePasswordLoading}
        onClick={methods.handleSubmit((x: any) => onSubmit(x, block))}
      >
        <Button
        //   my="4"
          variant={"primary"}
          alignSelf="flex-start"
          disabled={updateAccountLoading || changePasswordLoading}
        >
          {block === "account" && updateAccountLoading ? (
            <Spinner size={"md"} color="gold-yellow" />
          ) : (
            block === "account" && "Sauvgarder"
          )}
          {block === "security" && changePasswordLoading ? (
            <Spinner size={"md"} color="gold-yellow" />
          ) : block === "security" && "Sauvgarder"}
        </Button>
      </Dialog>
    </Stack>
  );
}
