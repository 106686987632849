import { AddIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  HStack,
  Icon,
  IconButton,
  LinkBox,
  Stack,
  Tag,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import { useQuery } from "react-query";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import FilterIcon from "../../../assets/icons/FilterIcon";
import TestIcon from "../../../assets/icons/TestIcon";
import ArchivedTag from "../../../components/ArchivedTag/ArchivedTag";
import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb";
import useQueryParams from "../../../hooks/useQueryParams";
import SidebarLayout from "../../../layouts/SidebarLayout";
import { getSeriesBySubject } from "../../../services/series/services";
import capitalizeText from "../../../utils/capitalizeText";
import FilterSeries from "./FilterSeries";

export default function SeriesBySubject() {
  const { url } = useRouteMatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const query = useQueryParams();
  const [filters, setFilters] = useState({
    groupBy: query.get("groupBy") || "Faculté",
    orderBy: query.get("orderBy") || "Facluté",
  });
  const history = useHistory();

  const { subjectName, specialityName } =
    useParams<{ subjectName: string; specialityName: string }>();
  const {
    data: series,
    isLoading: isLoadingSeries,
    isFetching: isFetchingSeries,
  } = useQuery(
    [`series-${subjectName}`, query.getAll("groupBy"), query.getAll("orderBy")],
    () => getSeriesBySubject(subjectName, query),
    { refetchOnWindowFocus: false }
  );
  const breadcrumb = [
    {
      title: "Série",
      link: "/series",
      isCurrent: false,
    },
    {
      title: capitalizeText(specialityName),
      link: `/series/${specialityName}`,
      isCurrent: false,
    },
    {
      title: capitalizeText(subjectName),
      link: `/series/${specialityName}/${subjectName}`,
      isCurrent: true,
    },
  ];
  const bgBox = useColorModeValue("lightGrey", "card-dark");
  const bgStack = useColorModeValue("gray.800", "white");
  const textColor = useColorModeValue("blue-light", "blue-light");
  const btnRef = useRef<any>();

  function compare(a: any, b: any) {
    if (+a.year < +b.year) {
      return 1;
    }
    if (+a.year > +b.year) {
      return -1;
    }
    return 0;
  }

  return (
    <SidebarLayout>
      <Flex justify={"space-between"} wrap="wrap-reverse" alignItems={"center"}>
        {series && (
          <BreadCrumb
            items={breadcrumb}
            isArchived={
              filters.groupBy === "Rien"
                ? series[0]?.subject.archived
                : series[0]?.data[0]?.subject.archived
            }
          />
        )}
        <HStack>
          <IconButton
            ref={btnRef}
            onClick={onOpen}
            icon={<FilterIcon />}
            aria-label="filter"
            _hover={{ bg: "darkGrey" }}
            _active={{ bg: "blue-light", color: "white" }}
          />
          <Divider
            orientation="vertical"
            h="20px"
            borderWidth={"2px"}
            rounded="xl"
          />
          <Button
            leftIcon={<AddIcon />}
            onClick={() => history.push(`${url}/ajouter`)}
            variant="primary"
            size="sm"
            height="2.5rem"
          >
            Créer une série
          </Button>
        </HStack>
      </Flex>
      {isLoadingSeries || isFetchingSeries ? (
        "loading..."
      ) : filters.groupBy !== "Rien" ? (
        series.map((el: any) => (
          <Box key={el._id._id || el._id} my="4">
            <HStack align={"center"}>
              <Heading as="h1" size="md" color={textColor}>
                {el._id.name || el._id}
              </Heading>
              <Tag>{el.total}</Tag>
            </HStack>
            <Grid
              gridTemplateColumns={"repeat(auto-fill, minmax(200px, 1fr))"}
              justifyItems="center"
              gap="20px"
              my="4"
            >
              {el.data.sort(compare).map((serie: any) => (
                <LinkBox
                  key={serie._id}
                  as="article"
                  w="full"
                  maxW={"400px"}
                  bg={bgBox}
                  p="5"
                  borderWidth="1px"
                  borderColor={"transparent"}
                  rounded=".8rem"
                  transition={"all 0.15s ease-in-out"}
                  _hover={{
                    borderColor: "darkSky",
                    boxShadow: "xs",
                    cursor: "pointer",
                  }}
                  overflow="hidden"
                  onClick={() => history.push(`${url}/${serie._id}`)}
                >
                  <Stack
                    color={bgStack}
                    h="full"
                    justify={"space-between"}
                    align="center"
                  >
                    <Icon as={TestIcon} />
                    {filters.groupBy !== "Faculté" && (
                      <Text fontWeight={"bold"}>{serie.university.name}</Text>
                    )}
                    <Text>{serie.year}</Text>
                    <Text color="gray.500">
                      {serie.questionCount || 0} question(s)
                    </Text>
                    {serie.archived && <ArchivedTag />}
                  </Stack>
                </LinkBox>
              ))}
            </Grid>
          </Box>
        ))
      ) : (
        <Box my="4">
          <HStack align={"center"}>
            <Heading as="h1" size="md" color={textColor}>
              Toutes les séries
            </Heading>
            <Tag>{series.length}</Tag>
          </HStack>
          <Grid
            gridTemplateColumns={"repeat(auto-fill, minmax(200px, 1fr))"}
            justifyItems="center"
            gap="20px"
            my="4"
          >
            {series.sort(compare).map((serie: any) => (
              <LinkBox
                key={serie._id}
                as="article"
                w="full"
                maxW={"400px"}
                bg={bgBox}
                p="5"
                borderWidth="1px"
                borderColor={"transparent"}
                rounded=".8rem"
                transition={"all 0.15s ease-in-out"}
                _hover={{
                  borderColor: "darkSky",
                  boxShadow: "xs",
                  cursor: "pointer",
                }}
                overflow="hidden"
                onClick={() => history.push(`${url}/${serie._id}`)}
              >
                <Stack
                  color={bgStack}
                  h="full"
                  justify={"space-between"}
                  align="center"
                >
                  <Icon as={TestIcon} />
                  <Heading fontSize={"md"}>{serie.university.name}</Heading>
                  <Text>{serie.year}</Text>
                  <Text color="gray.500">
                    {serie.questionCount || 0} question(s)
                  </Text>
                </Stack>
              </LinkBox>
            ))}
          </Grid>
        </Box>
      )}
      <FilterSeries
        btnRef={btnRef}
        isOpen={isOpen}
        onClose={onClose}
        filters={filters}
        setFilters={setFilters}
      />
    </SidebarLayout>
  );
}
