export const styles = {
    fonts: {
        body: `Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
        heading: `Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
    },
    colors: {
        primary: "#253076",
        "gold-yellow": "#F7D372",
        "theme-orange": "#FCB731",
        darkSky: "#7FCACF",
        lightSky: "#BEE4E7",
        lightGrey: "#F4F4F4",
        newLightGrey: "#F1F3F3",
        darkGrey: "#EAEAEA",
        darkRed: "#BF4E4E",
        "extra-light-green": "#f0FFF4",
        "light-green": "#7FCF91",
        "new-light-blue": "#E8EBFC",
        "light-blue-light": "#F6F7FF",
        "blue-light": "#4B62F0",
        "dark-blue-light": "#283071",
        "muted-blue": "#5D72F2",

        "blue-dark": "#06142D",
        "dark-blue-dark": "#0D1D45",
        "blue-dark-light": "#5662B4",
        "block-dark": "#19294E",
        "card-dark": "#0F214D"
    }
}