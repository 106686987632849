import { useState } from "react";
import {
  Box,
  Button,
  Container,
  FormLabel,
  HStack,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { FormProvider, useForm } from "react-hook-form";
import { HiArrowNarrowRight } from "react-icons/hi";
import { useMutation, useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import Field from "../../../components/Field/Field";
import PageHeading from "../../../components/PageHeading/PageHeading";
import MyToast from "../../../components/Toast/Toast";
import useQueryParams from "../../../hooks/useQueryParams";
import SidebarLayout from "../../../layouts/SidebarLayout";
import { createSerie, getQcmTypes } from "../../../services/series/services";
import { getUniversities } from "../../../services/university/services";
import { IInputField } from "../../../types";
import Forbidden from "../../../components/Forbidden/Forbidden";

export default function ExamsAdd() {
  const [notAuthorized, setNotAuthorized] = useState<boolean | string>(false);
  const toast = useToast();

  const {
    data: universities,
    isLoading: universitiesLoading,
    isError: universitiesError,
  } = useQuery("universities", () => getUniversities(), {
    refetchOnWindowFocus: false,
    retry: false,
    onError: (err: any) => {
      MyToast({
        toast,
        description: err.response.data.message,
        status: "warning",
        title: "Impossible",
      });
      setNotAuthorized(true);
    },
  });
  const {
    data: qcmTypes,
    isLoading: qcmTypesLoading,
    isError: qcmTypesError,
  } = useQuery("qcm-types", () => getQcmTypes(), {
    refetchOnWindowFocus: false,
    retry: false,
  });
  return (
    <SidebarLayout>
      <Container maxW={"7xl"}>
        <PageHeading title="Créer un examen blanc" />
        {notAuthorized && <Forbidden msg={notAuthorized} />}
        {universitiesLoading || qcmTypesLoading ? (
          <p>Chargement...</p>
        ) : (
          !notAuthorized && (
            <ExamForm universities={universities} types={qcmTypes} />
          )
        )}
        {universitiesError || (qcmTypesError && <p>Error!</p>)}
      </Container>
    </SidebarLayout>
  );
}

const ExamForm = ({ universities, types }: any) => {
  const queryParams = useQueryParams();
  const methods = useForm({
    defaultValues: {
      year: queryParams.get("year") ? queryParams.get("year") : "",
      jour: queryParams.get("day") ? queryParams.get("day") : "",
      university: queryParams.get("university")
        ? queryParams.get("university")
        : "",
      duration: "",
      type: types.find((el: any) => el.name === "Examen")?._id,
    },
  });
  const toast = useToast();
  const history = useHistory();

  const examsFields: IInputField[] = [
    {
      type: "select",
      name: "university",
      placeholder: "Faculté",
      options: universities.map((el: any) => ({
        id: el._id,
        label: el.name,
        value: el._id,
      })),
      rules: {
        required: "Veuillez sélectionner la faculté",
      },
    },
    {
      type: "select",
      name: "year",
      placeholder: "Année",
      options: [...Array(new Date().getFullYear() - 2014)].map((_, i) => ({
        id: i,
        label: (new Date().getFullYear() - i).toString(),
        value: (new Date().getFullYear() - i).toString(),
      })),
      rules: {
        required: "Veuillez sélectionner l'année",
      },
    },
    {
      type: "select",
      name: "jour",
      placeholder: "Jour 1 ou 2",
      options: [
        {
          id: 1,
          label: "Jour 1",
          value: "1",
        },
        {
          id: 2,
          label: "Jour 2",
          value: "2",
        },
      ],
      rules: {
        required: "Veuillez sélectionner le jour de l'épreuve",
      },
    },
    {
      type: "number",
      name: "duration",
      placeholder: "Durée en minutes",
      rules: {
        required: "Veuillez sélectionner la durée",
      },
    },
  ];

  const getSelectValue = (fieldName: string) => {
    switch (fieldName) {
      case "university":
        return methods.getValues("university");
      case "year":
        return methods.getValues("year");
      case "jour":
        return methods.getValues("jour");
      default:
        return "";
    }
  };

  const { mutateAsync: createMutation, isLoading: createLoading } = useMutation(
    createSerie,
    {
      onSuccess: (res: any) => {
        MyToast({ toast, status: "success", title: "Examen blanc crée." });
        history.push(
          `/questions/creer?type=examen&jour=${res.jour}&q=${res.id}`
        );
      },
      onError: (err: any) => {
        MyToast({
          toast,
          description: err.response.data.message,
          status: "warning",
          title: "Impossible",
        });
      },
    }
  );

  const onSubmit = (data: any) => {
    createMutation(data);
    // socket?.emit("exam", {
    //   qcm: {
    //     duration: data.duration,
    //     jour: data.jour,
    //     year: data.year,
    //     qcmId: newExam.id,
    //     university:
    //       universities.find((el: any) => el._id === data.university)?.name ||
    //       "",
    //   },
    // });
  };

  return (
    <FormProvider {...methods}>
      <Stack
        as="form"
        onSubmit={methods.handleSubmit(onSubmit)}
        spacing={4}
        mt="6"
      >
        {examsFields.map((field: IInputField) => (
          <Box key={field.name}>
            <FormLabel>{field.placeholder}</FormLabel>
            <HStack>
              <Box flexGrow={1}>
                <Field
                  value={getSelectValue(field.name)}
                  {...field}
                  bg="darkGrey"
                />
              </Box>
            </HStack>
          </Box>
        ))}
        <Button
          isLoading={createLoading}
          loadingText="Création en cours"
          variant="primary"
          spinnerPlacement="end"
          type="submit"
          alignSelf={"flex-end"}
          rightIcon={<HiArrowNarrowRight />}
        >
          Créer
        </Button>
      </Stack>
    </FormProvider>
  );
};
