import {
  InputGroup,
  InputLeftElement,
  InputRightElement,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { Controller, useFormContext, useFormState } from "react-hook-form";
import { IInputField } from "../../types";
import _ from "lodash";
import AnswerWrongIcon from "../../assets/icons/AnswerWrongIcon";

const NumberField = ({
  name,
  label,
  rules,
  placeholder,
  icon,
  bg,
  hidden = false,
  disabled = false,
  type = "text",
  showControllers = true
}: IInputField) => {
  const { control, watch } = useFormContext();
  const { errors } = useFormState({ control });
  const bgColorMode = useColorModeValue(
    !hidden && _.get(errors, name) ? "red.50" : bg,
    !hidden && _.get(errors, name) ? "dark-blue-dark" : "dark-blue-dark"
  );
  return (
    <div>
      <Controller
        name={name}
        control={control}
        defaultValue={_.get(watch(), name)}
        render={({ field: { ref, ...rest } }) => (
          <InputGroup width={"100%"}>
            <NumberInput width={"full"} min={0}>
              <NumberInputField
                bg={bgColorMode}
                hidden={hidden}
                disabled={disabled}
                placeholder={placeholder}
                {...rest}
                // @ts-ignore
                label={label}
                borderRadius={"8px"}
                fontWeight={"normal"}
                _placeholder={{
                  fontWeight: "light",
                  color: "#bbb",
                  fontSize: { base: "0.9rem", "2xl": "1rem" },
                }}
                borderWidth={!hidden && _.get(errors, name) ? "1px" : "0px"}
                borderColor={
                  !hidden && _.get(errors, name) ? "red.100" : "transparent"
                }
                height={{ base: "2.5rem", "2xl": "3rem" }}
                width="100%"
              />
              {showControllers && <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>}
            </NumberInput>
            {/* <Input
                            bg={bgColorMode}
                            hidden={hidden}
                            type={type}
                            variant={"primary"}
                            disabled={disabled}
                            placeholder={placeholder}
                            // inputref={ref}
                            {...rest}
                            label={label}
                            // border={"none"}
                            size={"lg"}
                            borderRadius={"8px"}
                            fontWeight={"normal"}
                            _placeholder={{
                              fontWeight:"light",
                              color: "#bbb",
                              fontSize: {base: "0.9rem", "2xl":"1rem"}
                            }}
                            borderWidth={!hidden && _.get(errors, name) ? "1px":"0px"}
                            borderColor={!hidden && _.get(errors, name) ? "red.100":"transparent"}
                            height={{base: "2.5rem", "2xl":"3rem"}}
                        /> */}
            {!hidden && _.get(errors, name) && showControllers ? (
              <InputLeftElement
                pointerEvents="none"
                children={<AnswerWrongIcon />}
                // placement="right"
                h={"full"}
              />
            ) : null}
            {!hidden && _.get(errors, name) && !showControllers ? (
              <InputRightElement
                pointerEvents="none"
                children={<AnswerWrongIcon />}
                // placement="right"
                h={"full"}
              />
            ) : null}
          </InputGroup>
        )}
        rules={rules}
      />
      {!hidden && (
        <Text color="red" fontSize="sm">
          {/* @ts-ignore */}
          {_.get(errors, name) ? _.get(errors, `${name}.message`) : null}
        </Text>
      )}
    </div>
  );
};

export default NumberField;
