import { Heading, HStack, Icon } from "@chakra-ui/react";

export default function BlockHeader({icon, title}: {icon: any, title: string}) {
  return (
    <HStack spacing={4} align={"flex-end"}>
      <Icon as={icon} />
      <Heading
        fontSize={{ base: "lg", xl: "xl", "2xl": "2xl" }}
        color="blue-light"
      >
        {title}
      </Heading>
    </HStack>
  );
}
