import { Button, Heading, HStack, Square, Stack } from "@chakra-ui/react";
import { FormProvider, useForm } from "react-hook-form";
import { useQueries } from "react-query";
import FilterSquareIcon from "../../../../assets/icons/FilterSquareIcon";
import Field from "../../../../components/Field/Field";
import { useSidebar } from "../../../../context/SidebarContext";
import type { Filter } from "../../../../context/SidebarContext";
import { getSubjects } from "../../../../services/subjects/services";
import { getUniversities } from "../../../../services/university/services";
import { IInputField } from "../../../../types";
import SidebarHeader from "./SidebarHeader";
import { getSpecialities } from "../../../../services/specialities/services";
import { useAuthProvider } from "../../../../auth-provider/AuthProvider";
import { getAllAdmin } from "../../../../services/admin/services";

export default function SidebarFilter() {
  const {
    handleChangeFilter,
    handleResetFilter,
    state: { filter },
  } = useSidebar();
  const { data: auth } = useAuthProvider();
  const methods = useForm({
    defaultValues: {
      resolved: filter.resolved,
      jour: filter.jour,
      speciality: filter.speciality,
      subject: filter.subject,
      year: filter.year,
      university: filter.university,
      message: filter.message,
      collaborator: filter.collaborator,
    },
  });

  const [subjects, universities, specialities, collaborators] = useQueries([
    {
      queryKey: "subjects",
      queryFn: getSubjects,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    },
    {
      queryKey: "universities",
      queryFn: getUniversities,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    },
    {
      queryKey: "specialities",
      queryFn: getSpecialities,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    },
    {
      queryKey: "admin",
      queryFn: getAllAdmin,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      enabled: auth?.role === "admin",
    },
  ]);

  const getSelectValue = (fieldName: string) => {
    switch (fieldName) {
      case "collaborator":
        return methods.getValues("collaborator");
      case "jour":
        return methods.getValues("jour");
      case "speciality":
        return methods.getValues("speciality");
      case "subject":
        return methods.getValues("subject");
      case "university":
        return methods.getValues("university");
      case "year":
        return methods.getValues("year");
      case "resolved":
        return methods.getValues("resolved");
      case "message":
        return methods.getValues("message");
      default:
        return "";
    }
  };
  // Watch the selected value of `jour`
  const selectedJour = methods.watch("jour");
  // Watch the selected value of `speciality`
  const selectedSpeciality = methods.watch("speciality");

  const filterFields: IInputField[] = [
    {
      type: "select",
      name: "resolved",
      placeholder: "Etat",
      isUpperCase: true,
      options: [
        {
          id: 2,
          label: "Tous",
          value: "",
        },
        {
          id: 0,
          label: "Traitée",
          value: "true",
        },
        {
          id: 1,
          label: "Non traitée",
          value: "false",
        },
      ],
    },
    {
      type: "select",
      name: "jour",
      placeholder: "Epreuve",
      isUpperCase: true,
      options: [
        {
          id: 300,
          label: "Tous",
          value: "",
        },
        {
          id: 123,
          label: "Jour 1",
          value: "1",
        },
        {
          id: 456,
          label: "Jour 2",
          value: "2",
        },
      ],
    },
    {
      type: "select",
      name: "speciality",
      placeholder: "Spécialité",
      height: "20rem",
      isUpperCase: true,
      options: [
        {
          id: 300,
          label: "Tous",
          value: "",
        },
        ...(selectedJour
          ? specialities?.data
              ?.filter((el: any) => el.jour === selectedJour)
              ?.map((el: any) => ({
                id: el._id,
                label: el.name,
                value: el.name,
              }))
          : specialities?.data?.map((el: any) => ({
              id: el._id,
              label: el.name,
              value: el.name,
            })) ?? []),
      ],
    },
    {
      type: "select",
      name: "subject",
      placeholder: "Sujet",
      height: "20rem",
      isUpperCase: true,
      options: [
        {
          id: 300,
          label: "Tous",
          value: "",
        },
        ...(selectedSpeciality
          ? subjects?.data
              ?.filter((el: any) => el.speciality.name === selectedSpeciality)
              ?.map((el: any) => ({
                id: el._id,
                label: el.name,
                value: el.name,
              }))
          : selectedJour
          ? subjects?.data
              ?.filter((el: any) => el.speciality.jour === selectedJour)
              ?.map((el: any) => ({
                id: el._id,
                label: el.name,
                value: el.name,
              }))
          : subjects?.data?.map((el: any) => ({
              id: el._id,
              label: el.name,
              value: el.name,
            })) ?? []),
      ],
    },
    {
      type: "select",
      name: "year",
      placeholder: "Année",
      isUpperCase: true,
      options: [
        {
          id: 100,
          label: "Toutes les années",
          value: "",
        },
        ...[...Array(new Date().getFullYear() - 2014)].map((_, i) => ({
          id: i,
          label: (new Date().getFullYear() - i).toString(),
          value: (new Date().getFullYear() - i).toString(),
        })),
      ],
      height: "20rem",
    },
    {
      type: "select",
      name: "university",
      placeholder: "Faculté",
      isUpperCase: true,
      options: [
        {
          id: 4,
          label: "Toutes les facultés",
          value: "",
        },
        ...(universities?.data?.map((el: any) => ({
          id: el._id,
          label: el.name,
          value: el.name,
        })) ?? []),
      ],
    },
    {
      type: "select",
      name: "message",
      placeholder: "Erreur",
      isUpperCase: true,
      options: [
        {
          id: 4,
          label: "Toutes",
          value: "",
        },
        {
          id: 0,
          label: "Les réponses proposées",
          value: "Les réponses proposées",
        },
        {
          id: 1,
          label: "Explication",
          value: "Explication",
        },
        {
          id: 2,
          label: "La question est dupliquée",
          value: "La question est dupliquée",
        },
        {
          id: 3,
          label: "L'énoncé",
          value: "L'énoncé",
        },
      ],
    },
  ];
  
  if(auth?.role === "admin"){
    filterFields.push({
      type: "select",
      name: "collaborator",
      placeholder: "Collaborateur",
      isUpperCase: true,
      options: [
        {
          id: 4,
          label: "Toutes",
          value: "",
        },
        ...(collaborators?.data
          ?.filter((el: any) => el.role === "admin" ||
            el.permissions.find(
              (perm: any) =>
                perm.name === "read-remarques" ||
                perm.name === "update-remarques"
            )
          )
          ?.map((el: any) => ({
            id: el._id,
            label: el.fullname,
            value: el.fullname,
          })) ?? []),
      ],
    })
  }

  const onSubmit = (data: Filter) => {
    handleChangeFilter(data);
  };

  const handleReset = () => {
    methods.setValue("collaborator", "");
    methods.setValue("jour", "");
    methods.setValue("speciality", "");
    methods.setValue("subject", "");
    methods.setValue("year", "");
    methods.setValue("university", "");
    methods.setValue("message", "");
    methods.setValue("resolved", "");
    handleResetFilter();
  };
  return (
    <Stack align="stretch" spacing={6}>
      <SidebarHeader title="Filter" icon={<FilterSquareIcon />} />
      <FormProvider {...methods}>
        <Stack as="form" onSubmit={methods.handleSubmit(onSubmit)}>
          {filterFields.map((field: any) => (
            <Field
              key={field.name}
              {...field}
              value={getSelectValue(field.name)}
              bg="white"
              borderColor="newLightGrey"
              borderWidth="2px"
              borderRadius="xl"
            />
          ))}
          <HStack justify={"flex-end"} align="center">
            <Button
              variant={"outline"}
              flexGrow="1"
              rounded={"xl"}
              borderColor="newLightGrey"
              borderWidth="2px"
              fontWeight="medium"
              py="6"
              _hover={{ bg: "newLightGrey" }}
              onClick={handleReset}
            >
              Réinitialiser
            </Button>
            <Button
              variant={"outline"}
              type="submit"
              flexGrow="1"
              rounded={"xl"}
              borderColor="newLightGrey"
              borderWidth="2px"
              fontWeight="medium"
              py="6"
              _hover={{ bg: "newLightGrey" }}
            >
              Filtrer
            </Button>
          </HStack>
        </Stack>
      </FormProvider>
    </Stack>
  );
}
