import { createRef, useState } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  Box,
  Button,
  HStack,
  IconButton,
  Stack,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import OptionsPanel from "./OptionsPanel";
import type { Option } from "./OptionsPanel";
// @ts-ignore
import Output from "editorjs-react-renderer";
import { CloseIcon } from "@chakra-ui/icons";
import EditIcon from "../../../../assets/icons/EditIcon";
import Editor from "../../../../components/Editor/Editor";
import axiosInstance from "../../../../utils/axiosInstance";
import ErrorBoundary from "../../../../components/ErrorBoundary/ErrorBoundary";
import DynamicInputList from "../../../../components/DynamicInputList/DynamicInputList";
import { useMutation, useQueryClient } from "react-query";
import { updateManyOptions } from "../../../../services/options/services";
import MyToast from "../../../../components/Toast/Toast";
import {
  deleteQuestion,
  markingAsResolved,
  updateOneQuestion,
} from "../../../../services/questions/services";
import { useSidebar } from "../../../../context/SidebarContext";
import Dialog from "../../../../components/Dialog/Dialog";

export interface Question {
  order: number;
  _id: string;
  value: any;
  options: Option[];
  children: any[];
  updatedBy?: {
    fullname: string;
    _id: string;
  };
  lastUpdatedAt?: string;
  qcm: {
    speciality: {
      name: string;
      jour: string;
      _id: string;
    };
    subject: {
      name: string;
      _id: string;
    };
    university: {
      name: string;
      _id: string;
    };
    type: {
      name: string;
      _id: string;
    };
    year: string;
  };
}

const AccordionQuestion = ({
  question,
  selectedOptionsCounts,
  message,
  resolved,
  selectedOptionsIsLoading,
  isRemarque,
  isSeries,
}: {
  question: Question;
  selectedOptionsCounts: any;
  message?: string;
  resolved?: boolean;
  selectedOptionsIsLoading: boolean;
  isRemarque?: boolean;
  isSeries?: boolean;
}) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [questionClone, setQuestionClone] = useState<Question & { ref: any }>({
    ...question,
    ref: createRef(),
  });
  const {
    state: { filter, page },
  } = useSidebar();
  const bgAccordion = useColorModeValue("darkGrey", "blue-dark");
  const toast = useToast();

  const {
    mutateAsync: updateManyOptionsMutation,
    isLoading: updateManyOptionsLoading,
  } = useMutation(updateManyOptions, {
    onError: (err: any) => {
      MyToast({
        toast,
        description: err.response.data.message,
        status: "warning",
        title: "Impossible",
      });
    },
  });

  const {
    mutateAsync: updateQuestionMutation,
    isLoading: updateQuestionLoading,
  } = useMutation(updateOneQuestion, {
    onSuccess: async () => {
      MyToast({ toast, status: "success", title: "Question modifiée." });
    },
    onError: (err: any) => {
      MyToast({
        toast,
        description: err.response.data.message,
        status: "warning",
        title: "Impossible",
      });
    },
  });
  const {
    mutateAsync: handleMarkingAsResolved,
    isLoading: isMarkingAsResolved,
  } = useMutation(markingAsResolved, {
    onSuccess: async () => {
      MyToast({
        toast,
        status: "success",
        title: `Remarque marquée comme ${
          resolved ? "non traitée" : "traitée"
        }.`,
      });
    },
    onError: (err: any) => {
      MyToast({
        toast,
        description: err.response.data.message,
        status: "warning",
        title: "Impossible",
      });
    },
  });

  const uploadImage = async (file: any) => {
    const formData = new FormData();
    formData.append("image", file);
    const response = await axiosInstance({
      method: "post",
      url: "/images/question/upload",
      data: formData,
    });
    return response.data;
  };

  const handleChange = (e: any, index: number) => {
    const list: any[] = questionClone.options;
    list[index][e.target.name] = e.target.value || e.target.checked;
    setQuestionClone({ ...questionClone, options: list });
    if (e.target.name === "withJustification" && !e.target.checked) {
      list[index]["justification"] = "";
      setQuestionClone({ ...questionClone, options: list });
    }
  };

  const handleAddInput = () => {
    setQuestionClone({
      ...questionClone,
      options: [
        ...questionClone.options,
        {
          value: "",
          isCorrect: false,
          justification: "",
          withJustification: false,
        },
      ],
    });
  };

  const handleRemoveInput = (index: number) => {
    const list: any[] = questionClone.options;
    list.splice(index, 1);
    setQuestionClone({ ...questionClone, options: list });
  };
  const queryClient = useQueryClient();

  const handleClick = async () => {
    const newEditorValue = await questionClone?.ref.current.save();
    const optionsResponse = await updateManyOptionsMutation(
      questionClone.options
    );

    await updateQuestionMutation({
      id: questionClone._id,
      value: newEditorValue,
      options: optionsResponse,
    });
    setIsEditMode(false);
    queryClient.invalidateQueries([
      "groupedReports",
      page,
      ...Object.entries(filter),
    ]);
  };

  const handleReportResolved = async () => {
    await handleMarkingAsResolved({
      message,
      questionId: questionClone._id,
      resolved: !resolved,
    });
    queryClient.invalidateQueries([
      "groupedReports",
      page,
      ...Object.entries(filter),
    ]);
    setIsEditMode(false);
  };

  const {
    mutateAsync: handleDeleteQuestion,
    isLoading: deleteQuestionLoading,
  } = useMutation(deleteQuestion, {
    onSuccess: () => {
      MyToast({ toast, status: "success", title: "Question supprimée." });
      queryClient.invalidateQueries([
        "questions",
        page,
        ...Object.entries(filter),
      ]);
    },
    onError: (err: any) => {
      MyToast({
        toast,
        description: err.response.data.message,
        status: "warning",
        title: "Impossible",
      });
    },
  });

  return (
    <Accordion defaultIndex={[0]}>
      {isEditMode ? (
        <AccordionItem
          key={question?._id}
          bg={bgAccordion}
          overflow="hidden"
          outline={"1px solid #ECEFF1"}
        >
          <AccordionButton
            _active={{}}
            _focus={{}}
            _expanded={{ bg: "gray.50" }}
            bg={"gray.50"}
            as="span"
            cursor={"pointer"}
            padding="10px 20px"
          >
            <HStack justify={"space-between"} flex="1" align={"flex-start"}>
              <Box flex="1" textAlign="left" whiteSpace="normal">
                <Editor
                  placeholder={"Write something"}
                  defaultValue={questionClone.value}
                  editorCore={questionClone.ref}
                  uploadImage={uploadImage}
                />
              </Box>
              <IconButton
                bg="transparent"
                _hover={{ bg: "gray.100" }}
                aria-label="edit question"
                icon={isEditMode ? <CloseIcon /> : <EditIcon />}
                onClick={() => setIsEditMode(!isEditMode)}
              />
            </HStack>
          </AccordionButton>
          <Stack spacing={4} mt="6" mb="4">
            <Box>
              <ErrorBoundary>
                <DynamicInputList
                  inputList={questionClone.options}
                  handleChange={(e: any, index: number) =>
                    handleChange(e, index)
                  }
                  handleAddInput={() => handleAddInput()}
                  handleRemoveInput={(index: number) =>
                    handleRemoveInput(index)
                  }
                />
              </ErrorBoundary>
            </Box>
            <HStack justify={"flex-end"} px="4">
              <Button
                onClick={handleClick}
                isLoading={updateManyOptionsLoading || updateQuestionLoading}
                loadingText={"Sauvgarde en cours"}
                bg="extra-light-green"
                border="1px solid"
                borderColor="light-green"
                rounded="xl"
                fontWeight="medium"
                _hover={{
                  bg: "green.100",
                }}
              >
                Sauvgarder
              </Button>
              <Button
                disabled={updateManyOptionsLoading || updateQuestionLoading}
                bg="white"
                border="1px solid"
                borderColor="black"
                rounded="xl"
                fontWeight="medium"
                onClick={() => setIsEditMode(false)}
              >
                Annuler
              </Button>
            </HStack>
          </Stack>
        </AccordionItem>
      ) : (
        <AccordionItem
          key={question?._id}
          bg={"gray.50"}
          overflow="hidden"
          outline={"1px solid #ECEFF1"}
        >
          <AccordionButton
            _active={{}}
            _focus={{}}
            _expanded={{ bg: "gray.50" }}
            bg={"gray.50"}
            as="span"
            cursor={"pointer"}
            padding="10px 20px"
          >
            <HStack justify={"space-between"} flex="1" align={"flex-start"}>
              <Box flex="1" textAlign="left" whiteSpace="normal">
                <Output data={question.value} />
              </Box>
              <IconButton
                bg="transparent"
                _hover={{ bg: "gray.100" }}
                aria-label="edit question"
                icon={isEditMode ? <CloseIcon /> : <EditIcon />}
                onClick={() => setIsEditMode(!isEditMode)}
              />
            </HStack>
          </AccordionButton>
          <OptionsPanel
            options={question.options}
            selectedOptionsCounts={selectedOptionsCounts}
            selectedOptionsIsLoading={selectedOptionsIsLoading}
          />
          <Button
            display={isRemarque ? "inline-flex" : "none"}
            onClick={handleReportResolved}
            disabled={updateManyOptionsLoading || updateQuestionLoading}
            isLoading={isMarkingAsResolved}
            loadingText={"Sauvgarde en cours"}
            bg={resolved ? "#fff5f5" : "#f3ffff"}
            border="1px solid"
            borderColor={resolved ? "#ecc1c1" : "#b5ecec"}
            rounded="xl"
            fontWeight="medium"
            _hover={{
              bg: resolved ? "#ecc1c1" : "#b5ecec",
            }}
            float="right"
            m="4"
          >
            Marquer comme {resolved ? "non traitée" : "traitée"}
          </Button>
          <Dialog
            question="Confirmer votre action"
            description={
              "Êtes-vous sûr de vouloire supprimer cette question ? Vous ne pouvez pas récupérer les données supprimés."
            }
            isLoading={deleteQuestionLoading}
            onClick={async () => await handleDeleteQuestion(question._id)}
            withTooltip
            tooltipText="Supprimer"
          >
            <Button
              display={isSeries ? "inline-flex" : "none"}
              disabled={deleteQuestionLoading}
              loadingText={"Sauvgarde en cours"}
              bg={"#fff5f5"}
              border="1px solid"
              borderColor={"#ecc1c1"}
              rounded="xl"
              fontWeight="medium"
              _hover={{
                bg: "#ecc1c1",
              }}
              float="right"
              m="4"
            >
              Supprimer
            </Button>
          </Dialog>
        </AccordionItem>
      )}
    </Accordion>
  );
};

export default AccordionQuestion;
