import { useRef } from "react";
import { useHistory } from "react-router-dom";
import PageHeading from "../../../components/PageHeading/PageHeading";
import SidebarLayout from "../../../layouts/SidebarLayout";
import {
  useDisclosure,
  Stack,
  Box,
  FormLabel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  ModalCloseButton,
  HStack,
  useToast,
} from "@chakra-ui/react";
import { useMutation } from "react-query";
import { FormProvider, useForm } from "react-hook-form";
import Field from "../../../components/Field/Field";
import { IColumnTable, IInputField } from "../../../types";
import MyToast from "../../../components/Toast/Toast";
import AnnouncesModal from "./AnnouncesModal";
import AnnouncesTable from "./Announces.table";

export default function Announces({ path }: { path: string }) {
  const history = useHistory();

  return (
    <SidebarLayout>
      <HStack justify="space-between">
        <PageHeading title="Annonces" />
        <AnnouncesModal />
      </HStack>
      <AnnouncesTable />
    </SidebarLayout>
  );
}
