import { Route, Switch, useRouteMatch } from "react-router-dom";
import SidebarLayout from "../../../../layouts/SidebarLayout";
import ResourcesForm from "../Resources.form";
import ResourcesTable from "../Resources.table";
import { IColumnTable } from "../../../../types";

export default function Specialities() {
  const { path } = useRouteMatch();

  const specialitiesColumns: IColumnTable[] = [
    {
      textAlign: "left",
      label: "Nom",
    },
    {
      textAlign: "center",
      label: "Abréviation",
    },
    {
      textAlign: "center",
      label: "Jour",
    },
    {
      textAlign: "center",
      label: "Nombre de sujets",
    },
    {
      textAlign: "center",
      label: "Statut",
    },
    {
      textAlign: "left",
      label: "Créé à",
    },
    {
      textAlign: "left",
      label: "Modifié à",
    },
    {
      textAlign: "left",
      label: "",
    },
  ];

  return (
    <SidebarLayout>
      <Switch>
        <Route exact path={`${path}`}>
          <ResourcesTable path={path} columns={specialitiesColumns} />
        </Route>
        <Route path={`${path}/modifier/:name`}>
          <ResourcesForm path={path} />
          <ResourcesTable path={path} columns={specialitiesColumns} />
        </Route>
        <Route path={`${path}/ajouter`}>
          <ResourcesForm path={path} />
          <ResourcesTable path={path} columns={specialitiesColumns} />
        </Route>
        <Route>
          <h1>Page Not Founddd</h1>
        </Route>
      </Switch>
    </SidebarLayout>
  );
}
