import { InfoOutlineIcon } from "@chakra-ui/icons";
import { Box, Heading, Stack, Text, Tooltip } from "@chakra-ui/react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import LayerIcon from "../../../../assets/icons/LayerIcon";
import { useSidebar } from "../../../../context/SidebarContext";
import SidebarHeader from "./SidebarHeader";

export default function SidebarReportHistory() {
  const { location } = useHistory();
  const isRemarquesPage = location.pathname.includes("remarques");
  const {
    state: { selectedQuestion, selectedReportDetails },
  } = useSidebar();

  return (
    <Stack spacing={6} align="stretch">
      <SidebarHeader title="Historique" icon={<LayerIcon />} />
      <Stack align="stretch">
        <Box border="2px solid" borderColor="newLightGrey" rounded="xl" p="4">
          <Heading color="blue-light" fontSize={"xl"}>
            Question
            <Tooltip
              hasArrow
              label="L'énnoncé a été modifié ou les options."
              placement="bottom-start"
            >
              <InfoOutlineIcon fontSize={"xs"} float="right" />
            </Tooltip>
          </Heading>
          <Text fontSize={"md"} fontWeight="bold">
            Modification:{" "}
            <Text as="span" fontWeight={"normal"}>
              {selectedQuestion?.lastUpdatedAt
                ? moment(selectedQuestion?.lastUpdatedAt).format("DD/MM/YYYY")
                : "n/a"}
            </Text>
          </Text>
          <Text fontSize={"md"} fontWeight="bold">
            Par:{" "}
            <Text as="span" fontWeight={"normal"}>
              {selectedQuestion?.updatedBy?.fullname || "n/a"}
            </Text>
          </Text>
        </Box>
        {isRemarquesPage && (
          <Box border="2px solid" borderColor="newLightGrey" rounded="xl" p="4">
            <Heading color="blue-light" fontSize={"xl"}>
              Remarque
              <Tooltip
                hasArrow
                label="Remarque marquée comme traitée ou non traitée"
                placement="bottom-start"
              >
                <InfoOutlineIcon fontSize={"xs"} float="right" />
              </Tooltip>
            </Heading>
            <Text fontSize={"md"} fontWeight={"bold"}>
              Modification:{" "}
              <Text fontWeight={"normal"} as="span">
                {selectedReportDetails?.lastUpdatedAt
                  ? moment(selectedReportDetails?.lastUpdatedAt).format(
                      "DD/MM/YYYY"
                    )
                  : "n/a"}
              </Text>
            </Text>
            <Text fontSize={"md"} fontWeight={"bold"}>
              Par:{" "}
              <Text as="span" fontWeight={"normal"}>
                {selectedReportDetails?.updatedBy?.fullname || "n/a"}
              </Text>
            </Text>
          </Box>
        )}
      </Stack>
    </Stack>
  );
}
