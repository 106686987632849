import {
  Box,
  HStack,
  IconButton,
  Spinner,
  Tag,
  Text,
  useBreakpointValue,
  useToast,
} from "@chakra-ui/react";
import moment from "moment";
import { useMutation, useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import PageHeading from "../../../../components/PageHeading/PageHeading";
import { Th, Tr, Table, Td } from "../../../../components/Table/Table";
import { deleteAdmin, getAllAdmin } from "../../../../services/admin/services";
import { useState } from "react";
import Forbidden from "../../../../components/Forbidden/Forbidden";
import Dialog from "../../../../components/Dialog/Dialog";
import MyToast from "../../../../components/Toast/Toast";

export default function CollaborateursList({ path }: { path: string }) {
  const [notAuthorized, setNotAuthorized] = useState<boolean | string>(false);
  const { data, isLoading, refetch } = useQuery("all-admin", getAllAdmin, {
    refetchOnWindowFocus: false,
    retry: false,
    onError: (err: any) =>
      err.response.data.statusCode === 403 &&
      setNotAuthorized(err.response.data.message),
  });
  return (
    <Box mt="4">
      <PageHeading title="Collaborateurs List" />
      <Box overflowX="auto" mt="6" mb="0" borderRadius={"1rem"}>
        <Table>
          <thead>
            <Tr>
              <Th w="20%">Nom et Prénom</Th>
              <Th w="20%">Email</Th>
              <Th w="10%">Rôle</Th>
              <Th w="30%">Permissions</Th>
              <Th w="10%">Créé à</Th>
              <Th w="10%" />
            </Tr>
          </thead>
          <tbody>
            {isLoading ? (
              <Tr h="350px">
                <Td colSpan="6" textAlign="center">
                  <Spinner size={"lg"} color="gold-yellow" />
                </Td>
              </Tr>
            ) : notAuthorized ? (
              <Tr>
                <Td colSpan="10">
                  <Forbidden msg={notAuthorized} />
                </Td>
              </Tr>
            ) : (
              <AdminTableBody data={data} path={path} refetch={refetch}/>
            )}
          </tbody>
        </Table>
      </Box>
    </Box>
  );
}

const AdminTableBody = ({ data, path, refetch }: any) => {
  const sizes = useBreakpointValue({ base: "sm", "2xl": "md" });
  const history = useHistory();
  const toast = useToast()

  const { mutateAsync, isLoading } = useMutation(deleteAdmin, {
    onSuccess: () => {
      MyToast({toast, status: "success", title: "Collaborateur supprimé."})
      refetch()
    },
    onError: (err: any) => {
      MyToast({toast, description: err.response.data.message, status: "warning", title: "Impossible"})
    }
  })

  return data.map((el: any) => (
    <Tr key={el._id}>
      <Td>
        <Text fontSize={{ base: "0.8rem", "2xl": "sm" }} isTruncated maxW={"99%"}>{el.fullname}</Text>
      </Td>
      <Td>
        <Text fontSize={{ base: "0.8rem", "2xl": "sm" }}>{el.email}</Text>
      </Td>
      <Td>
        <Text fontSize={{ base: "0.8rem", "2xl": "sm" }}>
          <Tag fontSize={{ base: "0.8rem", "2xl": "sm" }}>{el.role}</Tag></Text>
      </Td>
      <Td>
        {el.permissions.slice(0, 2).map((x: any) => (
          <Tag
            key={x.name}
            mx="1"
            size={"sm"}
            variant="outline"
            colorScheme="messenger"
            whiteSpace={"nowrap"}
            fontSize={{ base: "0.8rem", "2xl": "sm" }}
          >
            {x.name}
          </Tag>
        ))}
        {el.permissions.slice(2).length !== 0 && (
          <Tag
            mx="1"
            size={"sm"}
            variant="solid"
            colorScheme="facebook"
            whiteSpace={"nowrap"}
            fontSize={{ base: "0.8rem", "2xl": "sm" }}
          >
            {"+" + el.permissions.slice(2).length}
          </Tag>
        )}
      </Td>
      <Td>
        <Text fontSize={{ base: "0.8rem", "2xl": "sm" }}>
          {moment(el.createdAt).format("DD/MM/YYYY")}
        </Text>
      </Td>
      <Td>
        <HStack spacing={2} justify={"flex-end"}>
          <IconButton
            aria-label="Modifier Collaborateur"
            size={sizes}
            icon={
              <EditIcon
                fontSize={{ base: "0.7rem", "2xl": "1rem" }}
                color={"blue-light"}
              />
            }
            onClick={() => history.push(`${path}/collaborateur/${el.fullname}`)}
          />
          <Dialog
            question="Êtes-vous sûr?"
            description="Les données supprimées ne pourront pas être récupérées ultérieurement. Êtes-vous sur de vouloir supprimer cet administrateur"
            isLoading={isLoading}
            onClick={() => mutateAsync(el._id)}
          >
            <IconButton
              aria-label="Supprimer Collaborateur"
              size={sizes}
              icon={
                <DeleteIcon
                  fontSize={{ base: "0.7rem", "2xl": "1rem" }}
                  color="red.500"
                />
              }
            />
          </Dialog>
        </HStack>
      </Td>
    </Tr>
  ));
};
