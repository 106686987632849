import React, {
  createContext,
  useReducer,
  useContext,
  ReactNode,
  useEffect,
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Question } from "../pages/Unauthorized/Remarques/components/AccordionQuestion";
import {
  FILTER_ORDERS_SIDEBAR,
  FILTER_SERIES_SIDEBAR_SERIES,
  FILTER_SIDEBAR,
  HISTORY_SIDEBAR,
} from "../pages/Unauthorized/Remarques/constants";

type Sidebar =
  | "filter"
  | "history"
  | "filter_series_sidebar_page"
  | "remarques_sidebar"
  | "filter_orders_sidebar";
export interface Filter {
  [key: string]: string | boolean | undefined;
  subject?: string;
  resolved?: string;
  university?: string;
  year?: string;
  message?: string;
}

interface Report {
  _id: string;
  createdAt: string;
  message: string;
  comment?: string;
  user: {
    fullname: string;
  };
}

interface ReportDetails {
  updatedBy?: { fullname: string; _id: string };
  lastUpdatedAt: string;
}
interface SidebarState {
  sidebar: Sidebar;
  selectedQuestion: Question | null;
  selectedReportDetails: ReportDetails | null;
  lastFilterFrom: string;
  filter: Filter;
  page: number;
  isSidebarOpen: boolean;
  reports: Report[];
}

interface SidebarContextType {
  state: SidebarState;
  dispatch: React.Dispatch<SidebarAction>;
  handleChangeSidebar: (content: Sidebar) => void;
  handleChangeFilter: (fitler: Filter) => void;
  handleChangeQuestionFilter: (
    fitler: Exclude<Filter, "message"> & { speciality: string; keyword: string }
  ) => void;
  handleChangeOrdersFilter: (
    fitler: Exclude<Filter, "message"> & { speciality: string; keyword: string }
  ) => void;
  handleResetFilter: () => void;
  handleChangePage: (page: number) => void;
  handleSelectQuestion: (question: Question) => void;
  handleSelectedReportDetails: (reportDetails: ReportDetails | null) => void;
  handleIsSidebarOpen: (isOpen: boolean) => void;
  handleSelectedReport: (reports: Report[]) => void;
  oldQueryString: any;
}

interface SidebarAction {
  type: string;
  payload?: any;
}

// Create a context
const SidebarContext = createContext<SidebarContextType | undefined>(undefined);

// Define actions
const CHANGE_SIDEBAR = "CHANGE_SIDEBAR";
const CHANGE_FILTER = "CHANGE_FILTER";
const CHANGE_PAGE = "CHANGE_PAGE";
const SET_SELECT_QUESTION = "SET_SELECT_QUESTION";
const SET_REPORT_DETAILS = "SET_REPORT_DETAILS";
const SET_IS_SIDEBAR_OPEN = "SET_IS_SIDEBAR_OPEN";
const SET_REPORTS = "SET_REPORTS";

// Reducer function
const sidebarReducer = (
  state: SidebarState,
  action: SidebarAction
): SidebarState => {
  switch (action.type) {
    case CHANGE_SIDEBAR:
      return {
        ...state,
        sidebar: action.payload,
      };
    case CHANGE_FILTER:
      return {
        ...state,
        filter: action.payload.filter,
        lastFilterFrom: action.payload.lastFilterFrom,
      };
    case CHANGE_PAGE:
      return {
        ...state,
        page: action.payload,
      };
    case SET_SELECT_QUESTION:
      return {
        ...state,
        selectedQuestion: action.payload,
      };
    case SET_REPORT_DETAILS:
      return {
        ...state,
        selectedReportDetails: action.payload,
      };
    case SET_IS_SIDEBAR_OPEN:
      return {
        ...state,
        isSidebarOpen: action.payload,
      };
    case SET_REPORTS:
      return {
        ...state,
        reports: action.payload,
      };
    default:
      return state;
  }
};

// Create a provider component
interface SidebarProviderProps {
  children: ReactNode;
}

const SidebarProvider: React.FC<SidebarProviderProps> = ({ children }) => {
  const { search } = useLocation();
  const history = useHistory();

  const queryString = new URLSearchParams(search);
  const [state, dispatch] = useReducer(sidebarReducer, {
    sidebar: FILTER_SIDEBAR,
    page: Number(queryString.get("page")) || 1,
    selectedQuestion: null,
    selectedReportDetails: null,
    isSidebarOpen: false,
    lastFilterFrom: "",
    reports: [],
    filter: {
      collaborator: queryString.get("collaborator")?.toString() ?? "",
      jour: queryString.get("jour")?.toString() ?? "",
      subject: queryString.get("subject")?.toString() ?? "",
      resolved: queryString.get("resolved")?.toString() ?? "",
      university: queryString.get("university")?.toString() ?? "",
      speciality: queryString.get("speciality")?.toString() ?? "",
      keyword: queryString.get("keyword")?.toString() ?? "",
      year: queryString.get("year")?.toString() ?? "",
      message: queryString.get("message")?.toString() ?? "",
      reference: queryString.get("reference")?.toString() ?? "",
      status: queryString.get("status")?.toString() ?? "",
      voucherStatus: queryString.get("voucherStatus")?.toString() ?? "",
      orderType: queryString.get("orderType")?.toString() ?? "",
      pickUpStore: queryString.get("pickUpStore")?.toString() ?? "",
    },
  });

  useEffect(() => {
    if (history.location.pathname.includes("/series")) {
      handleChangeSidebar(FILTER_SERIES_SIDEBAR_SERIES);
    } else if(history.location.pathname.includes("/commandes")){
      handleChangeSidebar(FILTER_ORDERS_SIDEBAR);
    }else{
      handleChangeSidebar(FILTER_SIDEBAR);
    }
  }, [history.location.pathname]);
  useEffect(() => {
    if (!history.location.pathname.startsWith(`/${state.lastFilterFrom}`)) {
      handleResetFilter();
    }
    handleChangePage(1);
  }, [history.location.pathname]);

  const handleChangeSidebar = (content: Sidebar) =>
    dispatch({
      type: CHANGE_SIDEBAR,
      payload: content,
    });

  const handleChangeFilter = (filter: Filter) =>
    dispatch({
      type: CHANGE_FILTER,
      payload: { filter, lastFilterFrom: "remarques" },
    });
  const handleChangeQuestionFilter = (filter: any) =>
    dispatch({
      type: CHANGE_FILTER,
      payload: { filter, lastFilterFrom: "series" },
    });

    const handleChangeOrdersFilter = (filter: any) =>
    dispatch({
      type: CHANGE_FILTER,
      payload: { filter, lastFilterFrom: "orders" },
    });

  const handleSelectQuestion = (question: Question) => {
    dispatch({
      type: SET_SELECT_QUESTION,
      payload: question,
    });
  };

  const handleResetFilter = () =>
    dispatch({
      type: CHANGE_FILTER,
      payload: {
        filter: {
          subject: "",
          university: "",
          resolved: "",
          year: "",
          message: "",
          keyword: "",
        },
        lastFilterFrom: "",
      },
    });

  function hasValues(obj: any) {
    let res: any = {};
    for (const key in state.filter) {
      if (
        state.filter[key] !== null &&
        state.filter[key] !== undefined &&
        state.filter[key] !== ""
      ) {
        res[key] = state.filter[key];
      }
    }

    if (Object.keys(res)?.length !== 0) {
      return res;
    }
    return false;
  }

  useEffect(() => {
    const hasFilter = hasValues(state.filter);
    let qs = new URLSearchParams({
      page: state.page,
      ...hasFilter,
    } as any).toString();
    history.push("?" + qs);
  }, [state.page]);
  useEffect(() => {
    const hasFilter = hasValues(state.filter);
    let qs = new URLSearchParams({ page: 1, ...hasFilter } as any).toString();
    handleChangePage(1);
    history.push("?" + qs);
  }, [state.filter]);

  const handleChangePage = (page: number) => {
    dispatch({
      type: CHANGE_PAGE,
      payload: page,
    });
  };

  const handleSelectedReportDetails = (details: ReportDetails | null) => {
    dispatch({
      type: SET_REPORT_DETAILS,
      payload: details,
    });
  };

  const handleIsSidebarOpen = (isOpen: boolean) => {
    dispatch({
      type: SET_IS_SIDEBAR_OPEN,
      payload: isOpen,
    });
  };

  const handleSelectedReport = (reports: Report[]) => {
    dispatch({
      type: SET_REPORTS,
      payload: reports,
    });
  };

  const value: SidebarContextType = {
    state,
    dispatch,
    handleChangeSidebar,
    handleChangeFilter,
    handleChangeQuestionFilter,
    handleChangeOrdersFilter,
    handleResetFilter,
    handleChangePage,
    handleSelectQuestion,
    handleSelectedReportDetails,
    handleIsSidebarOpen,
    handleSelectedReport,
    oldQueryString: queryString,
  };
  return (
    <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>
  );
};

// Create a custom hook for using the context
const useSidebar = (): SidebarContextType => {
  const context = useContext(SidebarContext);
  if (!context) {
    throw new Error("useSidebar must be used within a SidebarProvider");
  }
  return context;
};

export { SidebarProvider, useSidebar, CHANGE_SIDEBAR };
