import { Heading, HStack, Square } from "@chakra-ui/react";

export default function SidebarHeader({
  icon: Icon,
  title,
}: {
  icon: React.ReactElement;
  title: string;
}) {
  return (
    <HStack border="2px solid" borderColor="newLightGrey" rounded="xl">
      <Square p="2" borderRight="2px solid" borderColor="newLightGrey">
        {Icon}
      </Square>
      <Heading
        fontWeight={"normal"}
        color="blue-light"
        fontSize={"lg"}
        p="2"
        pl="0"
      >
        {title}
      </Heading>
    </HStack>
  );
}
