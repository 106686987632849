export default function EditIcon() {
  return (
    <svg
      id="edit"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <rect
        id="Rectangle_1061"
        data-name="Rectangle 1061"
        width="24"
        height="24"
        rx="5"
        fill="#292d32"
        opacity="0"
      />
      <g id="Group_1207" data-name="Group 1207" transform="translate(1.2 1.2)">
        <path
          id="Path_220"
          data-name="Path 220"
          d="M13.5,20.475H8.1c-4.887,0-6.975-2.088-6.975-6.975V8.1c0-4.887,2.088-6.975,6.975-6.975H9.9a.675.675,0,1,1,0,1.35H8.1c-4.149,0-5.625,1.476-5.625,5.625v5.4c0,4.149,1.476,5.625,5.625,5.625h5.4c4.149,0,5.625-1.476,5.625-5.625V11.7a.675.675,0,1,1,1.35,0v1.8C20.475,18.387,18.387,20.475,13.5,20.475Z"
          fill="#292d32"
        />
        <path
          id="Path_221"
          data-name="Path 221"
          d="M7.65,15.921A1.928,1.928,0,0,1,5.7,13.608L6.084,10.9a3.121,3.121,0,0,1,.783-1.566l7.092-7.092c1.791-1.791,3.609-1.791,5.4,0A3.758,3.758,0,0,1,20.691,5.22a4,4,0,0,1-1.332,2.412l-7.092,7.092a3.121,3.121,0,0,1-1.566.783l-2.709.387a1.5,1.5,0,0,1-.342.027ZM14.913,3.2,7.821,10.287a1.831,1.831,0,0,0-.405.8L7.029,13.8a.706.706,0,0,0,.153.612.706.706,0,0,0,.612.153l2.709-.387a1.784,1.784,0,0,0,.8-.4L18.4,6.678a2.762,2.762,0,0,0,.936-1.593,2.5,2.5,0,0,0-.936-1.9C16.956,1.746,15.966,2.151,14.913,3.2Z"
          fill="#292d32"
        />
        <path
          id="Path_222"
          data-name="Path 222"
          d="M17.865,8.847a.58.58,0,0,1-.18-.027,7.143,7.143,0,0,1-4.914-4.914.684.684,0,0,1,.468-.837.67.67,0,0,1,.828.468,5.783,5.783,0,0,0,3.978,3.978.68.68,0,0,1,.468.837A.662.662,0,0,1,17.865,8.847Z"
          fill="#292d32"
        />
      </g>
    </svg>
  );
}
