import {
  Accordion,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Heading,
  HStack,
  Stack,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { lazy, Suspense, useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb";
import QuestionTableLoading from "../../../components/LoadingScreens/QuestionTableLoading";
import PageHeading from "../../../components/PageHeading/PageHeading";
import Pagination from "../../../components/Pagination/Pagination";
import Informative from "../../../components/Result/Informative";
import SerieDropDownMenu from "../../../components/QcmDropDownMenu/SerieDropDownMenu";
import useQueryParams from "../../../hooks/useQueryParams";
import SidebarLayout from "../../../layouts/SidebarLayout";
import { getExamSingleExam } from "../../../services/Exams/services";
import { getOneUniversity } from "../../../services/university/services";
import { archiveSerie, deleteSerie } from "../../../services/series/services";
import MyToast from "../../../components/Toast/Toast";
import { SocketContext } from "../../../context/socket";
const AccordionQuestion = lazy(
  () => import("../../../components/AccordionQuestion/AccordionQuestion")
);

export default function ExamsList({ path }: { path: string }) {
  const socket = useContext(SocketContext);

  const { universityName, year, day } =
    useParams<{ universityName: string; year: string; day: string }>();
  const { pathname } = useLocation();
  const history = useHistory();
  const queryString = useQueryParams();
  const [page, setPage] = useState(
    queryString.get("page")
      ? queryString.get("page") === "0"
        ? 0
        : Number(queryString.get("page")) - 1
      : 0
  );

  const textColor = useColorModeValue("blackAlpha.600", "whiteAlpha.600");
  const headingColor = useColorModeValue("blue-light", "blue-light");

  const handlePageClick = (x: number) => {
    setPage(x);
    queryString.set("page", String(x + 1));
    history.push({
      search: queryString.toString(),
    });
  };

  const {
    data: university,
    isLoading: universityLoading,
    isError: universityError,
  } = useQuery(
    `university-${universityName}`,
    () => getOneUniversity(universityName),
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
  const universityID = university?._id;

  const {
    data: questions,
    isLoading: examIsLoading,
    refetch,
    isError,
  } = useQuery(
    [`exam-${universityName}-${year}-${day}`, universityID, page],
    () => getExamSingleExam(university?._id, year, day, page),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!Boolean(universityID),
    }
  );



  const toast = useToast();
  const { mutateAsync: archiveOneSerie, isLoading: archiveSerieLoading } =
    useMutation(archiveSerie, {
      onSuccess: (res) => {
        MyToast({
          toast,
          status: "success",
          title: res.archived ? "Série archivée." : "Série restaurée.",
        });
        if(res?.archived === false){
        socket?.emit("exam", {
          qcm: {
            duration: questions?.exam?.duration,
            jour: questions?.exam?.jour,
            year: questions?.exam?.year,
            qcmId: questions?.exam?._id,
            university: questions?.exam?.university?.name
          },
        });
      }
        refetch();
      },
      onError: (err: any) => {
        MyToast({
          toast,
          description: err.response.data.message,
          status: "warning",
          title: "Impossible",
        });
      },
    });

    const { mutateAsync: deleteOneSerie, isLoading: deleteSerieLoading } =
    useMutation(deleteSerie, {
      onSuccess: () => {
        MyToast({ toast, status: "success", title: "Série supprimée." });
        history.goBack();
      },
      onError: (err: any) => {
        MyToast({
          toast,
          description: err.response.data.message,
          status: "warning",
          title: "Impossible",
        });
      },
    });
  const bgText = useColorModeValue("red.100", "red.900");

  return (
    <SidebarLayout>
      <HStack justify={"space-between"} align="flex-start">
        <HStack wrap="wrap" rowGap={6}>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to={"/examen-blanc"}>
                <PageHeading title={"Examen blanc"} />
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to={`/examen-blanc/${year}/${universityName}`}>
                <Text color={textColor} textTransform="capitalize">
                  épreuve
                </Text>
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Text color={textColor} textTransform="capitalize">
                Jour {day}
              </Text>
            </BreadcrumbItem>
          </Breadcrumb>
          {questions?.exam?.archived && (
            <Text
              fontSize={"xs"}
              fontWeight={500}
              bg={bgText}
              p={1}
              px={2}
              color={"red.300"}
              rounded={"full"}
            >
              Archivée
            </Text>
          )}
        </HStack>
        {!isError && !examIsLoading && (
          <SerieDropDownMenu
            data={questions?.exam}
            archiveOneSerie={archiveOneSerie}
            archiveSerieLoading={archiveSerieLoading}
            deleteOneSerie={deleteOneSerie}
            deleteSerieLoading={deleteSerieLoading}
            outsideSerie={true}
            isExam={true}
            count={questions?.count}
          />
        )}
      </HStack>
      <Stack spacing={2} my={4}>
        <Heading as="h1" size="md" color={headingColor}>
          {universityName + " " + year}
        </Heading>
        {isError && (
          <Informative
            msg="Examen n'est pas encore crée"
            description="Cliquez sur le bouton ci-dessous pour créer un examen."
            action
            queries={{ university: universityID, year, jour: day }}
          />
        )}
        {examIsLoading ? (
          <p>Loading...</p>
        ) : (
          <Accordion defaultIndex={[0]} allowMultiple>
            {questions?.results.map((el: any, i: number) => (
              <Suspense fallback={<QuestionTableLoading />} key={i}>
                <AccordionQuestion
                  index={i}
                  data={el}
                  path={pathname}
                  refetch={refetch}
                  withBreadCrumb
                  insideExam
                  isExam={el?.type === "Examen" || el?.qcm?.type?.name === "Examen"}
                  examDay={el?.jour || el?.qcm?.jour}
                  _id={true}
                />
              </Suspense>
            ))}
          </Accordion>
        )}
      </Stack>
      {questions?.count > 20 &&
        Pagination({
          currentPage: page,
          lastPage: Math.ceil(questions?.count / 20),
          handlePageClick,
        })}
    </SidebarLayout>
  );
}
