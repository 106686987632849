import {
  Box,
  Flex,
  Heading,
  Icon,
  LinkBox,
  Stack,
  Text,
  Tooltip,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import moment from "moment";
import FolderOpenIcon from "../../assets/icons/FolderOpenIcon";

export default function FolderBox({ goTo, item, itemType }: { goTo: any; item: any, itemType?:string }) {
  const bgBox = useColorModeValue("lightGrey", "card-dark");
  const bgStack = useColorModeValue("gray.800", "white");
  const bgText = useColorModeValue("red.100", "red.900");
  const colorUpdatedAt = useColorModeValue("blackAlpha.700", "whiteAlpha.600");
  return (
    <Tooltip label={item.name} placement="bottom" openDelay={1000}>
      <LinkBox
        as="article"
        w="full"
        maxW={"400px"}
        bg={bgBox}
        p="5"
        borderWidth="1px"
        borderColor={"transparent"}
        rounded=".8rem"
        transition={"all 0.15s ease-in-out"}
        _hover={{ borderColor: "darkSky", boxShadow: "xs", cursor: "pointer" }}
        onClick={goTo}
        overflow="hidden"
      >
        <Stack color={bgStack} h="full" justify={"space-between"}>
          {item?.archived && (
            <Text
              fontSize={"xs"}
              fontWeight={500}
              bg={bgText}
              p={1}
              px={2}
              color={"red.300"}
              rounded={"full"}
              position={"absolute"}
              top="2"
              right={"2"}
            >
              Archivée
            </Text>
          )}
          <Flex align={"center"}>
            <Box minWidth="40px">
              <Icon as={FolderOpenIcon} w="full" h="full" />
            </Box>
            <VStack align={"flex-start"} ml="4">
              <Heading
                fontSize={{base: "md", md: "lg"}}
                pt="2"
                fontWeight={"400"}
                isTruncated
                maxW={"250px"}
              >
                {item.name}
              </Heading>
              <Text pb="2" color="gray.500">{item?.subjectCount || item?.seriesCount} {itemType === "subject" ? "séries":"sujets"}</Text>
            </VStack>
          </Flex>
          <Text
            as="time"
            fontSize={"xs"}
            textAlign="right"
            color={colorUpdatedAt}
          >
            Dernière modification{" "}
            {moment(item?.updatedAt).locale("fr").fromNow()}
          </Text>
        </Stack>
      </LinkBox>
    </Tooltip>
  );
}
