import { useState, useRef } from "react";
import { EditIcon } from "@chakra-ui/icons";
import {
  HStack,
  IconButton,
  Tag,
  Text,
  Tooltip,
  useBreakpointValue,
  useToast,
  Container,
  Box,
  Flex,
  Button,
  Badge,
  useDisclosure,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogCloseButton,
} from "@chakra-ui/react";
import moment from "moment";
import { BiArchiveIn, BiArchiveOut } from "react-icons/bi";
import { IoTrashOutline } from "react-icons/io5";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import Dialog from "../../../components/Dialog/Dialog";
import MyToast from "../../../components/Toast/Toast";
import {
  archiveSpeciality,
  deleteSpeciality,
} from "../../../services/specialities/services";
import {
  getAllAnnounces,
  toggleActivateAnnounce,
} from "../../../services/annonces/services";
import { Table, Td, Th, Tr } from "../../../components/Table/Table";
import { IColumnTable } from "../../../types";
import LoadingTable from "../../../components/LoadingTable/LoadingTable";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import Forbidden from "../../../components/Forbidden/Forbidden";
import ActivateAnnounceDialog from "./ActivateAnnounceDialog";
import RemoveAnnounceDialog from "./RemoveAnnounceDialog";

const annoncesColumns: IColumnTable[] = [
  {
    textAlign: "left",
    label: "Title",
  },
  {
    textAlign: "left",
    label: "Annonce",
  },
  {
    textAlign: "left",
    label: "Date de début à minuit",
  },
  {
    textAlign: "left",
    label: "Date de fin à minuit",
  },
  {
    textAlign: "left",
    label: "Statue",
  },
  {
    textAlign: "center",
    label: "Actions",
  },
];

const AnnouncesTable = ({ path }: any) => {
  const [notAuthorized, setNotAuthorized] = useState<boolean | string>(false);
  const [values, setValues] = useState({
    maxValue: 10,
    minValue: 0,
    page: 1,
  });
  const sizes = useBreakpointValue({ base: "sm", "2xl": "md" });
  const history = useHistory();
  const toast = useToast();

  const { mutateAsync: deleteMutation, isLoading: deleteIsLoading } =
    useMutation(deleteSpeciality, {
      onSuccess: async () => {
        MyToast({ toast, status: "success", title: "Spécialité supprimée." });
        //   refetch();
      },
      onError: (err: any) => {
        MyToast({
          toast,
          description: err.response.data.message,
          status: "warning",
          title: "Impossible",
        });
      },
    });

  const { mutateAsync: archiveMutation, isLoading } = useMutation(
    archiveSpeciality,
    {
      onSuccess: async () => {
        MyToast({ toast, status: "success", title: "Spécialité modifiée." });
        //   refetch();
      },
      onError: (err: any) => {
        MyToast({
          toast,
          description: err.response.data.message,
          status: "warning",
          title: "Impossible",
        });
      },
    }
  );

  const {
    data: announces,
    isLoading: isLoadingAnnounces,
    refetch: refetchAnnounces,
  } = useQuery("announces", getAllAnnounces, {
    refetchOnWindowFocus: false,
    retry: false,
    onError: (err: any) =>
      err.response.data.statusCode === 403 &&
      setNotAuthorized(err.response.data.message),
  });

  // Handle Page Change
  const handleChange = (value: number) => {
    let dataLength = announces.length / 10;

    if (value > 0 && dataLength + 1 > value) {
      setValues({
        minValue: (value - 1) * 10,
        maxValue: value * 10,
        page: value,
      });
    }
  };

  return (
    <Box overflowX="auto" mt="6" mb="0" borderRadius={"1rem"}>
      <Table>
        <thead>
          <Tr>
            {annoncesColumns.map(
              ({ textAlign, label }: IColumnTable, index: number) => (
                <Th key={index} textAlign={textAlign}>
                  {label}
                </Th>
              )
            )}
          </Tr>
        </thead>
        <tbody>
          {isLoadingAnnounces ? (
            <LoadingTable />
          ) : notAuthorized ? (
            <Tr>
              <Td colSpan="10">
                <Forbidden msg={notAuthorized} />
              </Td>
            </Tr>
          ) : (
            announces.map((el: any) => (
              <Tr key={el._id}>
                <Td>
                  <Text
                    fontSize={{ base: "0.8rem", "2xl": "sm" }}
                    isTruncated
                    maxW={"150px"}
                  >
                    {el.title}
                  </Text>
                </Td>
                <Td>
                  <Text
                    fontSize={{ base: "0.8rem", "2xl": "sm" }}
                    isTruncated
                    maxW={"200px"}
                  >
                    {el.content}
                  </Text>
                </Td>
                <Td>
                  <Text
                    fontSize={{ base: "0.8rem", "2xl": "sm" }}
                    isTruncated
                    maxW={"99%"}
                  >
                    {moment(el.startDate).format("DD/MM/YYYY")}
                  </Text>
                </Td>
                <Td>
                  <Text
                    fontSize={{ base: "0.8rem", "2xl": "sm" }}
                    isTruncated
                    maxW={"99%"}
                  >
                    {moment(el.endDate).format("DD/MM/YYYY")}
                  </Text>
                </Td>
                <Td>
                  <Text
                    fontSize={{ base: "0.8rem", "2xl": "sm" }}
                    isTruncated
                    maxW={"99%"}
                  >
                    {el.isActive ? (
                      <Badge variant="solid" colorScheme="green">
                        Active
                      </Badge>
                    ) : (
                      <Badge variant="solid" colorScheme="red">
                        Inactive
                      </Badge>
                    )}
                  </Text>
                </Td>
                <Td>
                  <HStack>
                    <ActivateAnnounceDialog
                      isActive={el.isActive}
                      announceId={el._id}
                    />
                    <RemoveAnnounceDialog announceId={el._id} />
                  </HStack>
                </Td>
              </Tr>
            ))
          )}
        </tbody>
        <tfoot>
          <Tr>
            <Th colSpan="10">
              <Container maxW={"8xl"}>
                <Flex justify={"space-between"}>
                  <Button
                    variant={"ghost"}
                    _hover={{ bg: "whiteAlpha.200" }}
                    _active={{}}
                    leftIcon={<ChevronLeftIcon />}
                    onClick={() => handleChange(values.page - 1)}
                  >
                    Précédent
                  </Button>
                  <Button
                    variant={"ghost"}
                    _hover={{ bg: "whiteAlpha.200" }}
                    _active={{}}
                    rightIcon={<ChevronRightIcon />}
                    onClick={() => handleChange(values.page + 1)}
                  >
                    Suivant
                  </Button>
                </Flex>
              </Container>
            </Th>
          </Tr>
        </tfoot>
      </Table>
    </Box>
  );
};

export default AnnouncesTable;
