export default function BellIcon({color="#fff"}:{color?: string}) {
  return (
    <svg
      id="notification"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <rect
        id="Rectangle_30"
        data-name="Rectangle 30"
        width="24"
        height="24"
        rx="5"
        fill={color}
        opacity="0"
      />
      <g id="Group_38" data-name="Group 38" transform="translate(1.2 1.2)">
        <path
          id="Path_53"
          data-name="Path 53"
          d="M10.818,20.529a3.38,3.38,0,0,1-3.375-3.375h1.35a2.025,2.025,0,0,0,4.05,0h1.35A3.376,3.376,0,0,1,10.818,20.529Z"
          fill={color}
        />
        <path
          id="Path_51"
          data-name="Path 51"
          d="M10.818,18.477a19.507,19.507,0,0,1-6.183-1,2.5,2.5,0,0,1-1.584-1.485,2.431,2.431,0,0,1,.243-2.142l1.035-1.719a3.643,3.643,0,0,0,.414-1.5v-2.6a6.075,6.075,0,1,1,12.15,0v2.6a3.761,3.761,0,0,0,.414,1.512l1.026,1.71a2.491,2.491,0,0,1,.2,2.142,2.448,2.448,0,0,1-1.539,1.485,19.411,19.411,0,0,1-6.174,1Zm0-15.174A4.73,4.73,0,0,0,6.093,8.028v2.6a5.076,5.076,0,0,1-.6,2.2L4.455,14.544a1.118,1.118,0,0,0-.135.981,1.138,1.138,0,0,0,.747.666,18,18,0,0,0,11.511,0,1.1,1.1,0,0,0,.693-.675,1.121,1.121,0,0,0-.09-.972l-1.035-1.719a5.058,5.058,0,0,1-.6-2.2V8.028A4.724,4.724,0,0,0,10.818,3.3Z"
          fill={color}
        />
        <path
          id="Path_52"
          data-name="Path 52"
          d="M12.492,3.546a.685.685,0,0,1-.189-.027,6.342,6.342,0,0,0-.756-.162,5.263,5.263,0,0,0-2.2.162.67.67,0,0,1-.819-.891,2.465,2.465,0,0,1,4.59,0,.686.686,0,0,1-.126.7.7.7,0,0,1-.5.216Z"
          fill={color}
        />
      </g>
    </svg>
  );
}
