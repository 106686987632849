import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  HStack,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useCallback } from "react";
import alphabet from "../../../../utils/alphabet.json";

export interface Option {
  _id?: string;
  justification: string;
  isCorrect: boolean;
  value: string;
  withJustification?: boolean;
}

interface SelectedOption {
  count: number;
  answered: number;
  _id: string;
}

const OptionsPanel = ({
  options,
  selectedOptionsCounts,
  selectedOptionsIsLoading
}: {
  options: Option[];
  selectedOptionsCounts: {
    selected_options: SelectedOption;
  }[];
  selectedOptionsIsLoading: boolean
}) => {
  const getOption = useCallback(
    (optionId: string) => {
      const result = selectedOptionsCounts?.map((item) => ({
        selected_options: {
          _id: item.selected_options._id,
          percentage:
            (item.selected_options.count * 100) /
            (item.selected_options.answered === 0
              ? 1
              : item.selected_options.answered),
        },
      }));

      const option = result?.find((el) => el.selected_options._id === optionId);

      return Math.round(option?.selected_options.percentage ?? 0);
    },
    [selectedOptionsCounts]
  );
  return (
    <AccordionPanel p="0">
      {options?.map((option: Option, i: number) => (
        <Stack key={option._id} borderBottom="1px solid #eee">
          <HStack align={option.justification ? "flex-start" : "center"}>
            {option.justification ? (
              <Accordion
                allowMultiple
                bg={option.isCorrect ? "green.100" : "gray.100"}
                flexGrow={1}
              >
                <AccordionItem border="none" _expanded={{ bg: "red" }}>
                  <AccordionButton
                    _active={{}}
                    _focus={{}}
                    _hover={{
                      bg: option.isCorrect ? "green.100" : "gray.100",
                    }}
                    _expanded={{
                      bg: option.isCorrect ? "green.50" : "gray.50",
                    }}
                    bg={option.isCorrect ? "green.50" : "gray.50"}
                    border="1px solid"
                    borderColor={option.isCorrect ? "green.200" : "gray.200"}
                  >
                    <HStack justify={"space-between"} w="full" pl="2">
                      <HStack align="flex-start">
                        {selectedOptionsIsLoading ? (
                          <Spinner size={"xs"} color="blue-light" />
                        ) : (
                          <Text fontWeight={"bold"} w="47px" textAlign={"left"}>
                            {`${getOption(option._id ?? "")}%`}
                          </Text>
                        )}
                        <Text whiteSpace={"normal"}>
                          {` ${alphabet[i]} - ${option.value}`}
                        </Text>
                      </HStack>
                      <AccordionIcon />
                    </HStack>
                  </AccordionButton>
                  <AccordionPanel pb={4} pl="6" whiteSpace={"normal"}>
                    {option.justification}
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            ) : (
              <HStack
                py="2"
                px="6"
                flexGrow={1}
                gap="0"
                border="1px solid"
                bg={option.isCorrect ? "green.50" : "gray.50"}
                borderColor={option.isCorrect ? "green.200" : "gray.100"}
                mt={option.isCorrect ? "-2px" : "-1px"}
                align="center"
              >
                {selectedOptionsIsLoading ? (
                  <Spinner size={"xs"} color="blue-light" />
                ) : (
                  <Text fontWeight={"bold"} w="47px" textAlign={"left"}>
                    {`${getOption(option._id ?? "")}%`}
                  </Text>
                )}
                <Text fontSize={"md"} whiteSpace={"normal"}>
                  {` ${alphabet[i]} - ${option.value}`}
                </Text>
              </HStack>
            )}
          </HStack>
        </Stack>
      ))}
    </AccordionPanel>
  );
};

export default OptionsPanel;
