import { Skeleton, Stack } from "@chakra-ui/react";

export default function QuestionTableLoading({
  many = false,
}: {
  many?: boolean;
}) {
  if (many) {
    return (
      <Stack spacing={4} my="4">
        {Array.apply(null, Array(12)).map((_: any, index: number) => (
          <Skeleton h="48" rounded={"2xl"} width={"full"} key={index} startColor="darkGrey" endColor="lightGrey"/>
        ))}
      </Stack>
    );
  }

  return <Skeleton h="48" my="4" rounded={"2xl"} width={"full"} />;
}
