import axiosInstance from "../../utils/axiosInstance";

export const getAllAdmin = async () => {
  let token = localStorage.getItem("token");
  return await axiosInstance({
    method: "get",
    url: `/admin`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data);
};

export const getOneAdmin = async (fullname: string) => {
  let token = localStorage.getItem("token");
  return await axiosInstance({
    method: "get",
    url: `/admin/${fullname}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data);
};

export const createAdmin = async (data: string) => {
  let token = localStorage.getItem("token");
  return await axiosInstance({
    method: "post",
    url: `/admin/create`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data);
};

export const updateAdmin = async (data: any) => {
  let token = localStorage.getItem("token");
  return await axiosInstance({
    method: "put",
    url: `/admin/${data.email}`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data);
};

export const deleteAdmin = async (data: string) => {
  let token = localStorage.getItem("token");
  return await axiosInstance({
    method: "delete",
    url: `/admin/${data}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data);
};


export const updateProfile = async (data: any) => {
  let token = localStorage.getItem("token");
  return await axiosInstance({
    method: "put",
    url: `/admin/profile/update`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data);
};

export const changePassword = async (data: any) => {
  let token = localStorage.getItem("token");
  return await axiosInstance({
    method: "put",
    url: `/admin/profile/password/update`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data);
};