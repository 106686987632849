import { useColorModeValue } from "@chakra-ui/react";

export default function FilterIcon() {
  const color = useColorModeValue("#292d32", "white")
  return (
    <svg
      id="filter-square"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <rect
        id="Rectangle_896"
        data-name="Rectangle 896"
        width="24"
        height="24"
        rx="5"
        fill={color}
        opacity="0"
      />
      <g id="Group_1156" data-name="Group 1156" transform="translate(1.2 1.2)">
        <path
          id="Path_137"
          data-name="Path 137"
          d="M10.242,17.01a1.745,1.745,0,0,1-.864-.225A1.693,1.693,0,0,1,8.5,15.291V12.519a1.425,1.425,0,0,0-.27-.675L6.273,9.792A2.393,2.393,0,0,1,5.625,8.28v-1.2A1.726,1.726,0,0,1,7.344,5.31h6.9a1.725,1.725,0,0,1,1.719,1.719V8.181a2.632,2.632,0,0,1-.72,1.683L12.969,11.88a1.076,1.076,0,0,0-.3.7v2.25a1.81,1.81,0,0,1-.8,1.467l-.711.459a1.742,1.742,0,0,1-.918.252ZM7.344,6.66a.385.385,0,0,0-.369.423v1.2a1.121,1.121,0,0,0,.27.567l2,2.106a2.769,2.769,0,0,1,.6,1.566v2.772a.348.348,0,0,0,.189.315.4.4,0,0,0,.405,0l.72-.468a.558.558,0,0,0,.162-.324v-2.25a2.362,2.362,0,0,1,.765-1.719l2.232-1.98a1.317,1.317,0,0,0,.3-.7V7.029a.377.377,0,0,0-.369-.369Z"
          fill={color}
        />
        <path
          id="Path_138"
          data-name="Path 138"
          d="M13.5,20.475H8.1c-4.887,0-6.975-2.088-6.975-6.975V8.1c0-4.887,2.088-6.975,6.975-6.975h5.4c4.887,0,6.975,2.088,6.975,6.975v5.4C20.475,18.387,18.387,20.475,13.5,20.475Zm-5.4-18c-4.149,0-5.625,1.476-5.625,5.625v5.4c0,4.149,1.476,5.625,5.625,5.625h5.4c4.149,0,5.625-1.476,5.625-5.625V8.1c0-4.149-1.476-5.625-5.625-5.625Z"
          fill={color}
        />
      </g>
    </svg>
  );
}
