import { useAuthProvider } from "../../../auth-provider/AuthProvider";
import {
  Box,
  Radio,
  RadioGroup,
  Stack,
  useColorMode,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import PageHeading from "../../../components/PageHeading/PageHeading";
import AccountIcon from "../../../assets/icons/AccountIcon";
import { useForm } from "react-hook-form";
import { IInputField } from "../../../types";
import AvatarIcon from "../../../assets/icons/AvatarIcon";
import EmailIcon from "../../../assets/icons/EmailIcon";
import KeyIcon from "../../../assets/icons/KeyIcon";
import BlockHeader from "../../../components/BlockHeader/BlockHeader";
import SecurityIcon from "../../../assets/icons/SecurityIcon";
import EyeIcon from "../../../assets/icons/EyeIcon";
import ProfileBlock from "../../../components/ProfileBlock/ProfileBlock";
import { useMutation } from "react-query";
import { changePassword, updateProfile } from "../../../services/admin/services";
import MyToast from "../../../components/Toast/Toast";

export default function Profile() {
  const { data: auth } = useAuthProvider();
  const accountMethods = useForm<any>({
    defaultValues: {
      fullname: auth?.fullname,
      email: auth?.email,
    },
  });
  const passwordMethods = useForm<any>({
    defaultValues: {
      password_old: "",
      password_new: "",
      password_confirm: "",
    },
  });
  const { colorMode, setColorMode } = useColorMode();
  const toast = useToast();

  const accountFields: IInputField[] = [
    {
      type: "text",
      name: "fullname",
      placeholder: "Nom et prénom",
      icon: <AvatarIcon />,
      rules: {
        required: "Veuillez saisir le nom et prénom",
      },
    },
    {
      type: "text",
      name: "email",
      placeholder: "Adresse email",
      icon: <EmailIcon />,
      rules: {
        required: "Veuillez saisir l'adresse email",
      },
    },
  ];
  const securityFields: IInputField[] = [
    {
      type: "password",
      name: "password_old",
      placeholder: "Mot de passe",
      icon: <KeyIcon />,
      rules: {
        required: "Veuillez saisir le nom et prénom",
      },
    },
    {
      type: "password",
      name: "password_new",
      placeholder: "Nouveau mot de passe",
      icon: <KeyIcon />,
      rules: {
        required: "Veuillez saisir votre nouveau mot de passe",
      },
    },
    {
      type: "password",
      name: "password_confirm",
      placeholder: "Confirmer le nouveau mot de passe",
      icon: <KeyIcon />,
      rules: {
        required: "Veuillez confirmer votre nouveau mot de passe",
      },
    },
  ];

  const { mutateAsync: changePasswordMutation, isLoading: changePasswordLoading } = useMutation(changePassword, {
    onSuccess: () => {
      MyToast({ toast, status: "success", title: "Mot de passse modifié." });
      passwordMethods.reset()
    },
    onError: (err: any) => {
      if(err.response.data.statusCode === 400){
        passwordMethods.setError("password_new", {message: err.response.data.message})
      }
      if(err.response.data.statusCode === 401){
        passwordMethods.setError("password_old", {message: err.response.data.message})
      }
      MyToast({
        toast,
        description: err.response.data.message,
        status: "warning",
        title: "Impossible",
      });
    },
  })

  const { mutateAsync: updateProfileMutation, isLoading } = useMutation(updateProfile, {
    onSuccess: () => {
      MyToast({ toast, status: "success", title: "Profil modifié." });
    },
    onError: (err: any) => {
      MyToast({
        toast,
        description: err.response.data.message,
        status: "warning",
        title: "Impossible",
      });
    },
  });

  const onSubmit = (data: any, block: string) => {
    if (block === "account") {
      updateProfileMutation(data);
    } else {
      if(data.password_new !== data.password_confirm){
        passwordMethods.setError("password_confirm", {message: "Mot de passe n'est pas identique"})
      }
      changePasswordMutation({password: data.password_old, password_new: data.password_new});
    }
  };

  return (
    <Box mt="4">
      <PageHeading title={auth?.fullname || ""} />
      <ProfileBlock
        title="Détails du compte"
        block="account"
        icon={AccountIcon}
        fields={accountFields}
        methods={accountMethods}
        onSubmit={onSubmit}
        updateAccountLoading={isLoading}
        changePasswordLoading={changePasswordLoading}
      />
      <ProfileBlock
        title="Sécurité"
        block="security"
        icon={SecurityIcon}
        fields={securityFields}
        methods={passwordMethods}
        onSubmit={onSubmit}
        updateAccountLoading={isLoading}
        changePasswordLoading={changePasswordLoading}
      />
      <Stack
        bg={useColorModeValue("lightGrey", "block-dark")}
        p={{ base: 4, "2xl": "8" }}
        borderRadius={"1rem"}
        mt="6"
        as="form"
      >
        <BlockHeader title="Theme" icon={EyeIcon} />
        <RadioGroup onChange={setColorMode} value={colorMode}>
          <Stack direction="column">
            <Radio value="light">Mode jour</Radio>
            <Radio value="dark">Mode nuit</Radio>
            <Radio value="system">Automatique</Radio>
          </Stack>
        </RadioGroup>
      </Stack>
    </Box>
  );
}
