import {
  Input,
  InputGroup,
  InputRightElement,
  InputLeftElement,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { Controller, useFormContext, useFormState } from "react-hook-form";
import { IInputField } from "../../types";
import _ from "lodash";
import AnswerWrongIcon from "../../assets/icons/AnswerWrongIcon";

const TextField = ({
  name,
  label,
  rules,
  placeholder,
  icon,
  bg,
  hidden = false,
  disabled = false,
  type = "text",
  borderColor = "transparent",
  borderWidth = "0px",
  borderRadius = "8px",
  marginBottom = "0px",
}: IInputField) => {
  const { control, watch } = useFormContext();
  const { errors } = useFormState({ control });
  const bgColorMode = useColorModeValue(
    !hidden && _.get(errors, name) ? "red.50" : bg,
    !hidden && _.get(errors, name) ? "dark-blue-dark" : "dark-blue-dark"
  );
  return (
    <div>
      <Controller
        name={name}
        control={control}
        defaultValue={_.get(watch(), name)}
        render={({ field: { ref, ...rest } }) => (
          <InputGroup>
            {icon && (
              <InputLeftElement
                pointerEvents="none"
                children={icon}
                h={"full"}
              />
            )}
            <Input
              bg={bgColorMode}
              hidden={hidden}
              type={type}
              variant={"primary"}
              disabled={disabled}
              placeholder={placeholder}
              // inputref={ref}
              {...rest}
              // @ts-ignore
              label={label}
              // border={"none"}
              size={"lg"}
              marginBottom={marginBottom}
              borderRadius={borderRadius}
              fontWeight={"normal"}
              _placeholder={{
                fontWeight: "light",
                color: "#bbb",
                fontSize: { base: "0.9rem", "2xl": "1rem" },
              }}
              borderWidth={!hidden && _.get(errors, name) ? "1px" : borderWidth}
              borderColor={
                !hidden && _.get(errors, name) ? "red.100" : borderColor
              }
              height={{ base: "2.5rem", "2xl": "3rem" }}
            />
            {!hidden && _.get(errors, name) ? (
              <InputRightElement
                pointerEvents="none"
                children={<AnswerWrongIcon />}
                h={"full"}
              />
            ) : null}
          </InputGroup>
        )}
        rules={rules}
      />
      {!hidden && (
        <Text color="red" fontSize="sm">
          {/* @ts-ignore */}
          {_.get(errors, name) ? _.get(errors, `${name}.message`) : null}
        </Text>
      )}
    </div>
  );
};

export default TextField;
