import {
  Accordion,
  AccordionItem,
  Box,
  Grid,
  GridItem,
  HStack,
  Spinner,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { MdVerifiedUser } from "react-icons/md";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import PageHeading from "../../../components/PageHeading/PageHeading";
import UserDetailsHeader from "../../../components/UserDetails/UserDetailsHeader";
import UserRecentSeriesAttempts from "../../../components/UserDetails/UserRecentAttempts";
import UserRecentExamsAttempts from "../../../components/UserDetails/UserRecentExams";
import {
  getExamsAttempts,
  getUserDetails,
} from "../../../services/users/services";
import NotFound from "../../Authorized/NotFound/NotFound";

export default function UserDetails() {
  const { userId } = useParams<{ userId: string }>();
  const { data, isLoading, isError } = useQuery(
    `user-serie-${userId}`,
    () => getUserDetails(userId),
    {
      refetchOnWindowFocus: false,
    }
  );
  const {
    data: exams,
    isLoading: loadingExams,
    isError: examError,
  } = useQuery(`user-examen-${userId}`, () => getExamsAttempts(userId), {
    refetchOnWindowFocus: false,
  });

  //   @ts-ignore
  const uniqueSeries = [...new Set(data?.attempts?.map((item: any) => item.qcm._id)),];
  const seriesTotalAnsweredQuestions = data?.attempts?.reduce(
    (acc: any, val: any) => {
      if (uniqueSeries?.includes(val.qcm._id)) {
        return acc + val.answers.length;
      }
    },
    0
  );

  //   @ts-ignore
  const uniqueExams = [...new Set(exams?.map((item: any) => item.qcm._id))];
  const examsTotalAnsweredQuestions = exams?.reduce((acc: any, val: any) => {
    if (uniqueExams?.includes(val.qcm._id)) {
      return acc + val.answers.length;
    }
  }, 0);

  return (
    <Stack>
      {isLoading || loadingExams ? (
        <Stack align={"center"} justify="center">
          <Spinner color="gold-yellow" />
        </Stack>
      ) : isError || examError ? (
        <NotFound />
      ) : (
        <Stack spacing={6}>
          <PageHeading title={data?.user?.fullname} />

          <User userData={data?.user} />
          <UserDetailsHeader
            uniqueSeries={uniqueSeries?.length}
            uniqueExams={uniqueExams?.length}
            seriesAttempts={data?.attempts?.length}
            examsAttempts={exams?.length}
            lastLogin={data?.user?.last_login}
            seriesTotalAnsweredQuestions={seriesTotalAnsweredQuestions}
            examsTotalAnsweredQuestions={examsTotalAnsweredQuestions}
          />
          <Accordion defaultIndex={[0]} allowMultiple>
            <AccordionItem>
              <UserRecentSeriesAttempts seriesAttempts={data?.attempts} />
            </AccordionItem>
            <AccordionItem>
              <UserRecentExamsAttempts examsAttempts={exams} />
            </AccordionItem>
          </Accordion>
        </Stack>
      )}
    </Stack>
  );
}

const User = ({ userData }: any) => {
    const tagColor = userData.provider !== "email"
    ? "green"
    : userData.email_verified
    ? "green"
    : "gray"
  return (
    <Grid bg="lightGrey" gridTemplateColumns={{base: "1fr", md: "1fr 1fr"}} rounded={"1rem"} p="4" h="full">
        <GridItem>
            <HStack flexWrap={"wrap"}>
                <Text fontWeight={"bold"}>Nom d'utilisateur:</Text>
                <Text>{userData.username}</Text>
            </HStack>
            <HStack flexWrap={"wrap"}
            
                align={{ base: "flex-start", md: "center" }}
            >
                <Text fontWeight={"bold"}>Email:</Text>
                <Text>{userData.email}</Text>
                <Tooltip label="Vérifiée" hasArrow>
                <Box>
                    <MdVerifiedUser color={tagColor} />
                </Box>
                </Tooltip>
            </HStack>
            <HStack flexWrap={"wrap"}>
                <Text fontWeight={"bold"}>Promotion:</Text>
                <Text>{userData.promotion}</Text>
            </HStack>
        </GridItem>
        <GridItem>
            <HStack flexWrap={"wrap"}>
                <Text fontWeight={"bold"}>Specialité:</Text>
                <Text>{userData.speciality_to_learn}</Text>
            </HStack>
            <HStack flexWrap={"wrap"}>
                <Text fontWeight={"bold"}>Statut:</Text>
                <Text>{userData.status}</Text>
            </HStack>
            <HStack flexWrap={"wrap"}>
                <Text fontWeight={"bold"}>Faculté:</Text>
                <Text>{userData.university}</Text>
            </HStack>
        </GridItem>
    </Grid>
  );
};
