import { Box, Button, Heading, Text } from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";

interface IInformative {
  msg: string;
  description: string;
  action: boolean;
  queries: {
    university: string;
    year: string;
    jour: string;
  }
}
export default function Informative({
  msg,
  description,
  action = false,
  queries
}: IInformative) {
  return (
    <Box
      textAlign="center"
      py={10}
      px={6}
      border="1px solid"
      borderColor={"gray.200"}
      borderRadius="1rem"
      bg="gray.50"
    >
      <InfoIcon boxSize={"50px"} color={"blue-light"} />
      <Heading as="h2" size="lg" mt={6} mb={2}>
        {msg}
      </Heading>
      <Text color={"gray.500"}>{description}</Text>
      {action && (
        <Link to={`/examen-blanc/ajouter?university=${queries.university}&year=${queries.year}&day=${queries.jour}`}>
          <Button variant={"primary"} mt="4">
            Créer l'examen
          </Button>
        </Link>
      )}
    </Box>
  );
}
