import { useColorModeValue } from "@chakra-ui/react";

export default function CalendarIcon({
  isCheked = false,
}: {
  isCheked?: boolean;
}) {
  const color = useColorModeValue("#292d32", "white")
  return (
    <svg
      id="calendar-1"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <rect
        id="Rectangle_1009"
        data-name="Rectangle 1009"
        width="16"
        height="16"
        rx="5"
        fill={isCheked ? "#fff" : color}
        opacity="0"
      />
      <g
        id="Group_1169"
        data-name="Group 1169"
        transform="translate(2.15 1.55)"
      >
        <path
          id="Path_160"
          data-name="Path 160"
          d="M6.975,3.825a.453.453,0,0,1-.45-.45v-1.8a.45.45,0,0,1,.9,0v1.8A.453.453,0,0,1,6.975,3.825Z"
          transform="translate(-3.525 -1.125)"
          fill={isCheked ? "#fff" : color}
        />
        <path
          id="Path_161"
          data-name="Path 161"
          d="M14.175,3.825a.453.453,0,0,1-.45-.45v-1.8a.45.45,0,0,1,.9,0v1.8A.453.453,0,0,1,14.175,3.825Z"
          transform="translate(-5.925 -1.125)"
          fill={isCheked ? "#fff" : color}
        />
        <path
          id="Path_162"
          data-name="Path 162"
          d="M7.35,12.452a.6.6,0,0,1-.228-.048.576.576,0,0,1-.372-.552.6.6,0,0,1,.048-.228.693.693,0,0,1,.126-.2.619.619,0,0,1,.2-.126.613.613,0,0,1,.654.126.631.631,0,0,1,.174.426.9.9,0,0,1-.012.12.381.381,0,0,1-.036.108.454.454,0,0,1-.054.108.945.945,0,0,1-.072.09.631.631,0,0,1-.426.174Z"
          transform="translate(-3.6 -4.502)"
          fill={isCheked ? "#fff" : color}
        />
        <path
          id="Path_163"
          data-name="Path 163"
          d="M10.5,12.45a.6.6,0,0,1-.228-.048A.576.576,0,0,1,9.9,11.85a.6.6,0,0,1,.048-.228.693.693,0,0,1,.126-.2.619.619,0,0,1,.2-.126.6.6,0,0,1,.654.126.631.631,0,0,1,.174.426.9.9,0,0,1-.012.12.381.381,0,0,1-.036.108.454.454,0,0,1-.054.108.945.945,0,0,1-.072.09A.631.631,0,0,1,10.5,12.45Z"
          transform="translate(-4.65 -4.5)"
          fill={isCheked ? "#fff" : color}
        />
        <path
          id="Path_164"
          data-name="Path 164"
          d="M13.65,12.45a.6.6,0,0,1-.228-.048.619.619,0,0,1-.2-.126l-.072-.09a.454.454,0,0,1-.054-.108.382.382,0,0,1-.036-.108.9.9,0,0,1-.012-.12.631.631,0,0,1,.174-.426.619.619,0,0,1,.2-.126.6.6,0,0,1,.654.126.631.631,0,0,1,.174.426.9.9,0,0,1-.012.12.381.381,0,0,1-.036.108.454.454,0,0,1-.054.108.946.946,0,0,1-.072.09A.631.631,0,0,1,13.65,12.45Z"
          transform="translate(-5.7 -4.5)"
          fill={isCheked ? "#fff" : color}
        />
        <path
          id="Path_165"
          data-name="Path 165"
          d="M7.35,15.6a.6.6,0,0,1-.228-.048.693.693,0,0,1-.2-.126A.631.631,0,0,1,6.75,15a.6.6,0,0,1,.048-.228.56.56,0,0,1,.126-.2A.6.6,0,1,1,7.35,15.6Z"
          transform="translate(-3.6 -5.554)"
          fill={isCheked ? "#fff" : color}
        />
        <path
          id="Path_166"
          data-name="Path 166"
          d="M10.5,15.6a.594.594,0,0,1-.6-.6.6.6,0,0,1,.048-.228.56.56,0,0,1,.126-.2.628.628,0,0,1,.852,0,.56.56,0,0,1,.126.2A.6.6,0,0,1,11.1,15a.594.594,0,0,1-.6.6Z"
          transform="translate(-4.65 -5.554)"
          fill={isCheked ? "#fff" : color}
        />
        <path
          id="Path_167"
          data-name="Path 167"
          d="M13.65,15.6a.631.631,0,0,1-.426-.174.56.56,0,0,1-.126-.2.565.565,0,0,1,0-.456.56.56,0,0,1,.126-.2.6.6,0,0,1,.54-.162.362.362,0,0,1,.114.036.454.454,0,0,1,.108.054.946.946,0,0,1,.09.072A.6.6,0,0,1,13.65,15.6Z"
          transform="translate(-5.7 -5.55)"
          fill={isCheked ? "#fff" : color}
        />
        <path
          id="Path_168"
          data-name="Path 168"
          d="M13.125,8.406H2.925a.45.45,0,1,1,0-.9h10.2a.45.45,0,1,1,0,.9Z"
          transform="translate(-2.175 -3.252)"
          fill={isCheked ? "#fff" : color}
        />
        <path
          id="Path_169"
          data-name="Path 169"
          d="M10.275,14.475h-4.8a3.143,3.143,0,0,1-3.45-3.45v-5.1a3.143,3.143,0,0,1,3.45-3.45h4.8a3.143,3.143,0,0,1,3.45,3.45v5.1A3.143,3.143,0,0,1,10.275,14.475Zm-4.8-11.1a2.241,2.241,0,0,0-2.55,2.55v5.1a2.241,2.241,0,0,0,2.55,2.55h4.8a2.241,2.241,0,0,0,2.55-2.55v-5.1a2.241,2.241,0,0,0-2.55-2.55Z"
          transform="translate(-2.025 -1.575)"
          fill={isCheked ? "#fff" : color}
        />
      </g>
    </svg>
  );
}
