import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { IconButton, Button } from "@chakra-ui/react";
import React from "react";
import { IoEllipsisHorizontal } from "react-icons/io5";

const Pagination = ({ currentPage, lastPage, handlePageClick }: any) => {
    const render = [];
  
    if (currentPage - 1 >= 0) {
      const prevPage = currentPage - 1;
      render.push(
        <IconButton
          aria-label="icon"
          icon={<ChevronLeftIcon />}
          value={prevPage}
          onClick={() => handlePageClick(prevPage)}
          key={`prev-page-${prevPage}`}
          mx="1"
        />
      );
    }
  
    let startIdx;
    let endIdx;
    if (currentPage - 1 >= 0) {
      startIdx = currentPage - 1;
      endIdx = startIdx + 3;
    } else {
      startIdx = 0;
      endIdx = 3;
    }
    if (currentPage + 3 >= lastPage) {
      startIdx = lastPage - 3;
      endIdx = lastPage;
    }
  
    for (let idx = startIdx; idx < endIdx; idx++) {
      const offset = idx + 1;
      render.push(
        <Button
          key={`page-${offset}`}
          color={idx === currentPage ? "white" : "black"}
          onClick={() => handlePageClick(idx)}
          value={idx}
          mx="1"
          bg={idx === currentPage ? "blue-light" : "gray.100"}
        >
          {offset}
        </Button>
      );
    }
  
    if (endIdx < lastPage) {
      const offset = lastPage - 1;
      render.push(
        <React.Fragment key={`last-page-${lastPage}`}>
          <IconButton icon={<IoEllipsisHorizontal />} aria-label="dots" mx="1" />
          <Button onClick={() => handlePageClick(offset)} value={offset} mx="1">
            {lastPage}
          </Button>
        </React.Fragment>
      );
    }
  
    if (currentPage + 1 < lastPage) {
      const nextPage = currentPage + 1;
      render.push(
        <IconButton
          aria-label="icon"
          icon={<ChevronRightIcon />}
          value={nextPage}
          onClick={() => handlePageClick(nextPage)}
          key={`next-page-${nextPage}`}
          mx="1"
        />
      );
    }
    return render;
  };

  export default Pagination;