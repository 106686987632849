import {
  Stack,
  Box,
  FormLabel,
  ModalFooter,
  Button,
  HStack,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "react-query";
import { FormProvider, useForm } from "react-hook-form";
import Field from "../../../components/Field/Field";
import { IInputField } from "../../../types";
import MyToast from "../../../components/Toast/Toast";
import { createAnnounce } from "../../../services/annonces/services";

const AnnouncesForm = ({ onClose }: { onClose: () => void }) => {
  const announceMethods = useForm<any>();
  const toast = useToast();
  const queryClient = useQueryClient();
  const announceFields: IInputField[] = [
    {
      type: "text",
      name: "title",
      placeholder: "Titre",
      rules: {
        required: "Veuillez entrer le titre",
      },
    },
    {
      type: "text",
      name: "content",
      placeholder: "Annonce",
      rules: {
        required: "Veuillez entrer l'annonce.",
      },
    },
    {
      type: "date",
      name: "startDate",
      placeholder: "Date de début (à minuit)",
      rules: {
        required: "Veuillez sélectionner la date de début",
      },
    },
    {
      type: "date",
      name: "endDate",
      placeholder: "Date de fin (à minuit)",
      rules: {
        required: "Veuillez sélectionner la date de fin",
      },
    },
  ];

  const { mutateAsync, isLoading } = useMutation(createAnnounce, {
    onSuccess: async () => {
      MyToast({
        toast,
        status: "success",
        title: "Annonce crée.",
      });
      onClose();
      queryClient.invalidateQueries(`announces`);
    },
    onError: (err: any) => {
      MyToast({
        toast,
        description: err.response.data.message,
        status: "warning",
        title: "Impossible",
      });
    },
  });

  const handleSubmit = (data: any) => {
    mutateAsync({ data, isActive: false });
  };

  return (
    <FormProvider {...announceMethods}>
      <Stack
        spacing={4}
        as="form"
        onSubmit={announceMethods.handleSubmit(handleSubmit)}
      >
        {announceFields.map((field: IInputField) => (
          <Box key={field.name}>
            <FormLabel>{field.placeholder}</FormLabel>
            <Field
              onChange={(date: any) =>
                announceMethods.setValue(field.name, date)
              }
              {...field}
              bg="darkGrey"
            />
          </Box>
        ))}
        <ModalFooter px="0">
          <Button variant="secondary" mr={3} onClick={onClose}>
            Annuler
          </Button>
          <Button variant="primary" type="submit" isLoading={isLoading}>
            Créer
          </Button>
        </ModalFooter>
      </Stack>
    </FormProvider>
  );
};

export default AnnouncesForm;
