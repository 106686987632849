import moment from "moment";
import { GoReport } from "react-icons/go";
import NotificationWrapper from "./NotificationWrapper";

const ReportNotification = ({ notification, createdAt, seen }: any) => {
  return (
    <NotificationWrapper
      notificationTitle={() => (
        <>
          <strong>{notification?.username} </strong>a signalé une erreur
        </>
      )}
      notificationIcon={<GoReport color="#4B62F0" />}
      notificationDate={moment(createdAt).fromNow()}
      notificationLink={`/remarques`}
      seen={seen}
    />
  );
};

export default ReportNotification;
