import { Fragment, useEffect, useState } from "react";
import { FiFastForward, FiRewind } from "react-icons/fi";
import FixedRightSidebar, {
  LeftSide,
  RightPart,
} from "../../../layouts/FixedRightSidebar";
import { useQuery } from "react-query";
import {
  findSelectedOptionsCount,
  getGroupedReports,
} from "../../../services/Remarques/services";
import CustomTable from "../../../components/CustomTable/CustomTable";
import { Td, Tr } from "../../../components/Table/Table";
import { columns, FILTER_SIDEBAR, HISTORY_SIDEBAR, REMARQUES_SIDEBAR } from "./constants";
import type { Question } from "./components/AccordionQuestion";
import AccordionQuestion from "./components/AccordionQuestion";
import RemarqueRow from "./components/RemarqueRow";
import { Button, HStack, useToast } from "@chakra-ui/react";
import FilterSquareIcon from "../../../assets/icons/FilterSquareIcon";
import { useSidebar } from "../../../context/SidebarContext";
import Sidebar from "../../../components/Sidebar";
import LoadingTable from "../../../components/LoadingTable/LoadingTable";
import MyToast from "../../../components/Toast/Toast";
import ErrorBoundary from "../../../components/ErrorBoundary/ErrorBoundary";
import LayerIcon from "../../../assets/icons/LayerIcon";
import ActivityIcon from "../../../assets/icons/ActivityIcon";
import InfoCircleIcon from "../../../assets/icons/InfoCircleIcon";
import NotesIcon from "../../../assets/icons/NotesIcon";
import QandRIcon from "../../../assets/icons/QandRIcon";
import SidebarCloseIcon from "../../../assets/icons/SidebarCloseIcon";
import SidebarOpenIcon from "../../../assets/icons/SidebarOpenIcon";

interface Report {
  message: string;
  resolved: boolean;
  _id: string;
  lastUpdatedAt: string;
}
interface UpdatedBy {
  fullname: string;
  _id: string;
}
interface Response {
  reports: Report[];
  message: string;
  question: Question;
  updatedBy?: UpdatedBy;
}

const Remarques = () => {
  const {
    handleChangeSidebar,
    handleIsSidebarOpen,
    handleChangePage,
    handleSelectQuestion,
    handleSelectedReportDetails,
    handleSelectedReport,
    state: {
      page,
      filter,
      selectedQuestion,
      selectedReportDetails,
      isSidebarOpen,
    },
  } = useSidebar();
  const [pagesCount, setPagesCount] = useState<number>(1);
  const [expandedRow, setExpandedRow] = useState<string | null>(null);
  // const [selectedQuestion, setSelectedQuestion] = useState<string | null>(null);
  const toast = useToast();
  const LIMIT = 30;

  const { data, isLoading, isError } = useQuery(
    ["groupedReports", page, ...Object.entries(filter)],
    () => getGroupedReports({ page, limit: LIMIT, filter }),
    {
      refetchOnWindowFocus: false,
      onSuccess: (res) => setPagesCount(res?.totalCount.totalDocuments || 0),
      onError: (err: any) => {
        MyToast({
          toast,
          description: err.response.data.message,
          status: "error",
          title: "Erreur",
        });
      },
      staleTime: Infinity,
    }
  );

  const { data: selectedOptionsCounts, isLoading: selectedOptionsIsLoading } =
    useQuery(
      ["selecetedOptionsCount", selectedQuestion?._id],
      () => findSelectedOptionsCount(selectedQuestion?._id || ""),
      {
        refetchOnWindowFocus: false,
        enabled: Boolean(selectedQuestion),
        staleTime: Infinity,
      }
    );

  const handleRowClick = (
    rowId: string,
    questionId: Question,
    reportDetails: { updatedBy?: UpdatedBy; lastUpdatedAt: string }
  ) => {
    handleChangeSidebar(HISTORY_SIDEBAR);
    setExpandedRow((prevRow) => (prevRow === rowId ? null : rowId));
    handleSelectQuestion(questionId);
    handleSelectedReportDetails(reportDetails);
    const reports = data?.data.find((el: Response) => el.question._id === questionId._id)?.reports
    handleSelectedReport(reports)
  };

  const renderPageButtons = () => {
    const buttons = [];
    const totalPages = Math.ceil(pagesCount / LIMIT)
      ? Math.ceil(pagesCount / LIMIT)
      : 1;
    const startPage = Math.max(0, page - 2);
    const endPage = Math.min(totalPages, startPage + 1);

    for (let i = startPage; i <= page; i++) {
      if (i + 1 > totalPages) {
        break;
      }
      buttons.push(
        <Button
          key={i}
          onClick={() => handleChangePage(i + 1)}
          bg={i + 1 === page ? "blue-light" : "gray.100"}
          color={i + 1 === page ? "white" : "black"}
          _hover={{}}
        >
          {i + 1}
        </Button>
      );
    }

    if (startPage > 1) {
      buttons.unshift(<span key="start-dots">...</span>);
      for (let i = page - 4; i > page - 6; i--) {
        if (i === -1) {
          break;
        }
        buttons.unshift(
          <Button
            key={`key-${i}`}
            onClick={() => handleChangePage(i + 1)}
            bg={i + 1 === page ? "blue-light" : "gray.100"}
            color={i + 1 === page ? "white" : "black"}
            _hover={{}}
          >
            {i + 1}
          </Button>
        );
      }
    }

    if (page < totalPages) {
      buttons.push(<span key="end-dots">...</span>);
      if (page + 2 < totalPages) {
        for (let i = totalPages - 2; i < totalPages; i++) {
          buttons.push(
            <Button
              key={`keys-${i}`}
              onClick={() => handleChangePage(i + 1)}
              bg={i + 1 === page ? "blue-light" : "gray.100"}
              color={i + 1 === page ? "white" : "black"}
              _hover={{}}
            >
              {i + 1}
            </Button>
          );
        }
      }
    }

    buttons.push(
      <Button
        key={totalPages + "d"}
        onClick={() => handleChangePage(totalPages)}
        bg={"gray.100"}
      >
        <FiFastForward />
      </Button>
    );
    buttons.unshift(
      <Button key={"first"} onClick={() => handleChangePage(1)} bg={"gray.100"}>
        <FiRewind />
      </Button>
    );
    return buttons;
  };

  useEffect(() => {
    if (!expandedRow) {
      handleChangeSidebar(FILTER_SIDEBAR);
      handleSelectedReportDetails(null);
    }
  }, [expandedRow]);
  return (
    <FixedRightSidebar
      breadcrumbs={filter}
      basePage="Remarques"
      buttons={[
        {
          title: isSidebarOpen ? "Fermer" : "Ouvrir",
          icon: isSidebarOpen ? <SidebarCloseIcon /> : <SidebarOpenIcon />,
          onClick: () => handleIsSidebarOpen(!isSidebarOpen),
        },
        {
          title: "Filtrer",
          value: FILTER_SIDEBAR,
          icon: <FilterSquareIcon />,
          onClick: () => handleChangeSidebar(FILTER_SIDEBAR),
          isHidden: !isSidebarOpen,
        },
      ]}
    >
      <LeftSide>
        <CustomTable columns={columns}>
          {isLoading ? (
            <LoadingTable height="500px" />
          ) : isError ? (
            <ErrorBoundary />
          ) : (
            data?.data?.map((report: Response) => {
              const oneReport: Report = report.reports[0];
              return (
                <Fragment key={oneReport._id}>
                  <RemarqueRow
                    expandedRow={expandedRow}
                    handleRowClick={handleRowClick}
                    jour={report.question.qcm.speciality.jour}
                    speciality={report.question.qcm.speciality.name}
                    subject={report.question.qcm.subject.name}
                    type={report.question.qcm.type.name}
                    university={report.question.qcm.university.name}
                    year={report.question.qcm.year}
                    message={report.message}
                    order={report.question.order}
                    reportsCount={report.reports.length}
                    oneReport={oneReport}
                    question={report.question}
                    updatedBy={report.updatedBy}
                  />
                  {expandedRow === oneReport._id && (
                    <Tr height="auto" padding="0">
                      <Td colSpan={10} padding="0">
                        <AccordionQuestion
                          question={report.question}
                          selectedOptionsCounts={selectedOptionsCounts}
                          message={report.message}
                          resolved={oneReport.resolved}
                          selectedOptionsIsLoading={selectedOptionsIsLoading}
                          isRemarque
                        />
                      </Td>
                    </Tr>
                  )}
                </Fragment>
              );
            })
          )}
        </CustomTable>

        <HStack my="2">{renderPageButtons()}</HStack>
      </LeftSide>
      <RightPart
        footerButtons={[
          {
            title: "Historique",
            icon: <LayerIcon />,
            value: HISTORY_SIDEBAR,
            onClick: () => handleChangeSidebar(HISTORY_SIDEBAR),
            isDisabled: !selectedReportDetails,
          },
          {
            title: "Référence",
            icon: <ActivityIcon />,
            value: "Référence",
            onClick: () => console.log("Référence"),
          },
          {
            title: "Remarque",
            icon: <InfoCircleIcon />,
            value: REMARQUES_SIDEBAR,
            onClick: () => handleChangeSidebar(REMARQUES_SIDEBAR),
          },
          {
            title: "Notes",
            icon: <NotesIcon />,
            value: "Notes",
            onClick: () => console.log("Notes"),
          },
          {
            title: "Q&R",
            icon: <QandRIcon />,
            value: "Q&R",
            onClick: () => console.log("Q&R"),
          },
        ]}
      >
        <Sidebar />
      </RightPart>
    </FixedRightSidebar>
  );
};

export default Remarques;
