import {
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Box,
  Grid,
  GridItem,
  Heading,
  HStack,
  Stack,
  Text,
} from "@chakra-ui/react";
import moment from "moment";
import { BsCheckCircleFill } from "react-icons/bs";
import { GoAlert } from "react-icons/go";

export default function UserRecentSeriesAttempts({ seriesAttempts }: any) {
  return (
    <Stack spacing={4}>
      <AccordionButton _focus={{}} _active={{}}>
        <Heading as="h2" fontSize={"lg"} color="blue-light" mr="2">
          Séries
        </Heading>
        <AccordionIcon />
      </AccordionButton>

      <AccordionPanel p="0">
        <Box bg="lightGrey" rounded={"1rem"}>
          <Grid gridTemplateColumns={"25px repeat(8, 1fr)"} gap="10px" p="4">
            <GridItem></GridItem>
            <GridItem isTruncated fontWeight={"bold"}>Specialité</GridItem>
            <GridItem isTruncated fontWeight={"bold"}>Sujet</GridItem>
            <GridItem isTruncated fontWeight={"bold"}>Faculté</GridItem>
            <GridItem isTruncated fontWeight={"bold"}>Année</GridItem>
            <GridItem isTruncated fontWeight={"bold"}>Questions</GridItem>
            <GridItem isTruncated fontWeight={"bold"}>Répondu</GridItem>
            <GridItem isTruncated fontWeight={"bold"}>Moyenne</GridItem>
            <GridItem isTruncated fontWeight={"bold"}>Date</GridItem>
          </Grid>
          {seriesAttempts.length === 0 ? (
            <Stack
              gridTemplateColumns={"25px repeat(8, 1fr)"}
              gap="10px"
              bg={"darkGrey"}
              alignItems="center"
              p="4"
            >
              <Text>Pas de donnée</Text>
            </Stack>
          ) : (
            seriesAttempts.map((attempt: any, index: number) => (
              <AttemptRow key={attempt._id} attempt={attempt} index={index} />
            ))
          )}
        </Box>
      </AccordionPanel>
    </Stack>
  );
}

const AttemptRow = ({ attempt, index }: any) => {
  return (
    <Grid
      gridTemplateColumns={"25px repeat(8, 1fr)"}
      gap="10px"
      bg={index % 2 !== 0 ? "lightGrey" : "darkGrey"}
      alignItems="center"
      p="4"
    >
      <GridItem>
        {attempt.answers.length >= attempt.qcm.questionsCount ? (
          <BsCheckCircleFill color="green" />
        ) : (
          <GoAlert color="#F7D372" />
        )}
      </GridItem>
      <GridItem isTruncated>{attempt.qcm.speciality.name}</GridItem>
      <GridItem isTruncated>{attempt.qcm.subject.name}</GridItem>
      <GridItem isTruncated>{attempt.qcm.university.name}</GridItem>
      <GridItem>{attempt.qcm.year}</GridItem>
      <GridItem>{attempt.qcm.questionsCount}</GridItem>
      <GridItem>{attempt.answers.length}</GridItem>
      <GridItem>{attempt.overall?.toFixed(2)}</GridItem>
      <GridItem>{moment(attempt.updatedAt).format("DD/MM/YYYY")}</GridItem>
    </Grid>
  );
};
