import {
  Box,
  Button,
  Container,
  FormLabel,
  HStack,
  IconButton,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import Field from "../../../components/Field/Field";
import PageHeading from "../../../components/PageHeading/PageHeading";
import SidebarLayout from "../../../layouts/SidebarLayout";
import { getSpecialities } from "../../../services/specialities/services";
import { getSubjects } from "../../../services/subjects/services";
import { getUniversities } from "../../../services/university/services";
import { IInputField } from "../../../types";
import { CgArrowsExchangeAlt } from "react-icons/cg";
import { GrPowerReset } from "react-icons/gr";
import { HiArrowNarrowRight } from "react-icons/hi";
import { createSerie, getQcmTypes } from "../../../services/series/services";
import MyToast from "../../../components/Toast/Toast";

export default function SeriesAdd() {
  const {
    data: subjects,
    isLoading: subjectsLoading,
    isError: subjectError,
  } = useQuery("subjects", () => getSubjects(), {
    refetchOnWindowFocus: false,
    retry: false,
  });
  const {
    data: specialities,
    isLoading: specialitiesLoading,
    isError: specialitiesError,
  } = useQuery("specialities", () => getSpecialities(), {
    refetchOnWindowFocus: false,
    retry: false,
  });
  const {
    data: universities,
    isLoading: universitiesLoading,
    isError: universitiesError,
  } = useQuery("universities", () => getUniversities(), {
    refetchOnWindowFocus: false,
    retry: false,
  });
  const {
    data: qcmTypes,
    isLoading: qcmTypesLoading,
    isError: qcmTypesError,
  } = useQuery("qcm-types", () => getQcmTypes(), {
    refetchOnWindowFocus: false,
    retry: false,
  });

  if (subjectError || specialitiesError || universitiesError || qcmTypesError)
    return <p>Error!</p>;

  return (
    <SidebarLayout>
      <Container maxW={"7xl"}>
        <PageHeading title="Créer une série" />
        {subjectsLoading ||
        specialitiesLoading ||
        universitiesLoading ||
        qcmTypesLoading ? (
          <p>Chargement...</p>
        ) : (
          <SerieForm
            subjects={subjects}
            specialities={specialities}
            universities={universities}
            types={qcmTypes}
          />
        )}
      </Container>
    </SidebarLayout>
  );
}

const SerieForm = ({ subjects, specialities, universities, types }: any) => {
  const [disabled, setDisabled] = useState<any>({
    subject: true,
    speciality: true,
  });
  const params = useParams<any>();
  const history = useHistory();
  const toast = useToast();
  const methods = useForm({
    defaultValues: {
      year: "",
      university: "",
      type: types.find((el: any) => el.name === "Série")?._id,
      speciality: specialities.find(
        (el: any) => el.name === params.specialityName
      )?._id,
      subject: subjects.find((el: any) => el.name === params.subjectName)?._id,
    },
  });

  const serieFields: IInputField[] = [
    {
      type: "select",
      name: "year",
      placeholder: "Année",
      options: [...Array(new Date().getFullYear() - 2014)].map((_, i) => ({
        id: i,
        label: (new Date().getFullYear() - i).toString(),
        value: (new Date().getFullYear() - i).toString(),
      })),
      height: "20rem",
    },
    {
      type: "select",
      name: "university",
      placeholder: "Faculté",
      options: universities.map((el: any) => ({
        id: el._id,
        label: el.name,
        value: el._id,
      })),
      rules: {
        required: "Veuillez sélectionner la faculté",
      },
    },
    {
      type: "select",
      name: "speciality",
      placeholder: "Spécialité",
      options: specialities.map((el: any) => ({
        id: el._id,
        label: el.name,
        value: el._id,
      })),
      disabled: disabled.speciality,
      height: "20rem",
      rules: {
        required: "Veuillez sélectionner la spécialité",
      },
    },
    {
      type: "select",
      name: "subject",
      placeholder: "Sujet",
      options: subjects
        .filter((x: any) => x.speciality._id == methods.watch("speciality"))
        .map((el: any) => ({
          id: el._id,
          label: el.name,
          value: el._id,
        })),
      disabled: disabled.subject,
      rules: {
        required: "Veuillez sélectionner le sujet",
      },
    },
  ];

  const getSelectValue = (fieldName: string) => {
    switch (fieldName) {
      case "subject":
        return methods.getValues("subject");
      case "speciality":
        return methods.getValues("speciality");
      case "university":
        return methods.getValues("university");
      case "year":
        return methods.getValues("year");
      case "type":
        return methods.getValues("type");
      default:
        return "";
    }
  };

  const enableSelect = (fieldName: any) => {
    setDisabled({ ...disabled, [fieldName]: false });
  };

  const resetSelectValue = (fieldName: any) => {
    if (fieldName === "subject") {
      let initSubject = subjects.find(
        (el: any) => el.name === params.subjectName
      );
      methods.setValue("subject", initSubject._id);
    } else if (fieldName === "speciality") {
      let initSpeciality = specialities.find(
        (el: any) => el.name === params.specialityName
      );
      methods.setValue("speciality", initSpeciality._id);
    }
  };

  const { mutateAsync: createMutation, isLoading: createLoading } = useMutation(
    createSerie,
    {
      onSuccess: (res: any) => {
        MyToast({ toast, status: "success", title: "Série crée." });
          history.push(`/questions/creer?type=serie&q=${res.id}`)
      },
      onError: (err: any) => {
        MyToast({
          toast,
          description: err.response.data.message,
          status: "warning",
          title: "Impossible",
        });
      },
    }
  );

  const onSubmit = (data: any) => {
    createMutation(data);
  };
  return (
    <FormProvider {...methods}>
      <Box as="form" onSubmit={methods.handleSubmit(onSubmit)}>
        {serieFields.map((field: IInputField) => (
          <Box key={field.name} my="2">
            <FormLabel>{field.placeholder}</FormLabel>
            <HStack>
              <Box flexGrow={1}>
                <Field
                  value={getSelectValue(field.name)}
                  {...field}
                  bg="darkGrey"
                />
              </Box>
              {(field.name === "subject" || field.name === "speciality") && (
                <IconButton
                  icon={
                    disabled[field.name] ? (
                      <CgArrowsExchangeAlt fontSize={"1.5rem"} />
                    ) : (
                      <GrPowerReset
                        fontSize={"1.1rem"}
                        onClick={() => resetSelectValue(field.name)}
                      />
                    )
                  }
                  aria-label="change"
                  onClick={() => enableSelect(field.name)}
                />
              )}
            </HStack>
          </Box>
        ))}
        <Button
          isLoading={createLoading}
          loadingText="Création en cours"
          variant="primary"
          spinnerPlacement="end"
          type="submit"
          my="2"
          rightIcon={<HiArrowNarrowRight />}
        >
          Créer
        </Button>
      </Box>
    </FormProvider>
  );
};
