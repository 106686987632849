export default function EyeIcon() {
    return (
        <svg id="eye" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
  <rect id="Rectangle_1072" data-name="Rectangle 1072" width="32" height="32" rx="5" fill="#4b62f0" opacity="0"/>
  <g id="Group_1228" data-name="Group 1228" transform="translate(3.346 5.176)">
    <path id="Path_246" data-name="Path 246" d="M12.1,17.295a5.2,5.2,0,1,1,5.2-5.2A5.2,5.2,0,0,1,12.1,17.295Zm0-8.592a3.4,3.4,0,1,0,3.4,3.4A3.4,3.4,0,0,0,12.1,8.7Z" transform="translate(0.555 -1.275)" fill="#7fcacf"/>
    <path id="Path_247" data-name="Path 247" d="M13.963,24.33c-4.512,0-8.772-2.64-11.7-7.224a7.269,7.269,0,0,1,0-7.2c2.94-4.584,7.2-7.224,11.7-7.224s8.76,2.64,11.688,7.224a7.269,7.269,0,0,1,0,7.2C22.723,21.69,18.463,24.33,13.963,24.33Zm0-19.848c-3.876,0-7.584,2.328-10.176,6.4a5.453,5.453,0,0,0,0,5.256c2.592,4.068,6.3,6.4,10.176,6.4s7.584-2.328,10.176-6.4a5.453,5.453,0,0,0,0-5.256c-2.592-4.068-6.3-6.4-10.176-6.4Z" transform="translate(-1.309 -2.682)" fill="#4b62f0"/>
  </g>
</svg>

    )
}
